.sol-uvk {
  .sol-event-search {
    &__tabs {
      position: absolute;
      left:0;
      bottom:0;
    }
    &__tab {
      height: 50px;
      width: 190px;
      max-width:50%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      color: @black;
      cursor: pointer;
      border-radius: 3px;
      &--active {
        background-color: @white;
      }
      > span {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        color: @black;
      }
    }
  }
}