

  .sol-header {
    .sol-bottom {
      background: @top-menu-bg;
      .sol-menu-container {
        .sol-menu-button {
            .fa {
              display: block;
            }
          }
         > .sol-top-menu-nav{
           display: inline-flex;
           justify-content: center;
          > ul {
            display: flex;
            height: 70px;
            > li {
              > div {
                height: 100%;
                > .sol-menu-list {
                  background: #fff;
                  border-radius: 0px;
                  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
                  left: 0;
                  list-style: none;
                  margin: 0;
                  padding: 0;
                  position: absolute;
                  top: 70px;
                  display: none;
                  width: 290px;
                }
                > a {
                  height: 100%;
                  align-items: center;
                  > span {
                    color: @white;
                  }
                }
              }
            }
          }

        }
        .sol-menu-item {
          position: relative;
          &[data-is-current="true"]:not([data-menu-level="1"]) {
            > .sol-block-wrapper {
              background: @top-sub-menu-item-bg-active;
              > .sol-menu-item-link {
                > .sol-menu-item-display-name {
                  color: @top-sub-menu-item-text-active;
                }
              }
              > .sol-menu-button {
                >.fa {
                  color: @top-sub-menu-item-text-active;
                }
              }
            }
          }
          &[data-is-current="true"] {
            > .sol-block-wrapper {
              > .sol-menu-button {
                > .fa {
                  transform: rotate(90deg);
                }
              }
            }
            &[data-menu-level="1"] {
              > .sol-block-wrapper {
                background: #35607f;
              }
            }

          }

          .sol-block-wrapper {
            display: flex;
            height: 70px;
            transition: background .3s ease;
          }
          a {
            text-decoration: none;
          }
          &-link {
            display: inline-flex;
            height: 100%;
            align-items: center;
          }
          .sol-menu-button {
            .no-appearance;
            .focus-outline;
            .fa {
              font-size: 20px;
              color: @white;
              transform: rotate(90deg);
              transition: transform .3s ease;
            }
          }
          &:not([data-menu-level="1"]) {
            .sol-block-wrapper {
              border-bottom: 1px solid #e7e7e7;
            }
            > .sol-block-wrapper {
              > .sol-menu-item-link {
                flex-grow: 2;
                padding-left: 15px;
                padding-right: 15px;
              }
              > .sol-menu-button {
                flex-basis: 43px;
                flex-grow: 1;
              }
            }
          }
          &:not([data-menu-level="1"]) {
            .sol-menu-list {
              display: none;
              flex-grow: 1;
            }
          }
          .sol-menu-list {
            display: none;
            flex-grow: 1;
          }
          &:not([data-menu-level="1"]):not([data-is-current="true"]) {
            > .sol-block-wrapper {
              &:hover {
                background: @top-sub-menu-item-bg-hover;
              }
            }

            .sol-menu-item-link {
              padding: 13px 10px;
              flex-grow: 2;
            }
            .sol-menu-button {
              height: 100%;
              width: 43px;
              flex-grow: 1;
              cursor: pointer;
              z-index: 3;
            }
            .fa {
              color: @ms-color-1;
            }
          }
          &[data-menu-level="1"] {
            transition: background .3s ease;
              > .sol-block-wrapper {
                padding-left: 30px;
                padding-right: 30px;
                > .sol-menu-item-link {
                  > .sol-menu-item-display-name {
                    font-family: 'Source Sans Pro', sans-serif;
                    font-size: 20px;
                     text-align: center;
                     color: #ffffff;
                  }
                }
                > .sol-menu-button {
                  margin-left: 20px;
                }
              }
          }
          &[data-menu-level="1"]:not([data-is-current="true"]):hover{
            background: @top-main-menu-bg-hover;
            > .sol-block-wrapper {
              > .sol-menu-item-link {
                > .sol-menu-item-display-name {
                  color: @top-main-menu-text-color-hover;
                }
              }
            }
            > .sol-block-wrapper {
              > .sol-menu-button {
              > .fa {
                  transform: rotate(90deg) !important;
                  color: @top-menu-icon-color-hover !important;
                }
            }
              }
          }
          [data-menu-level="3"] {
            > .sol-block-wrapper {
              > .sol-menu-item-link {
                padding-left: 25px;
              }
            }
          }
          [data-menu-level="4"] {
            > .sol-block-wrapper {
              > .sol-menu-item-link {
                padding-left: 30px;
              }
            }
          }

        }
      }
    }
  }
