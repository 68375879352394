.sol-uvk {
  .sol-top-img {
    position: relative;
    width: 100%;
    max-width: 1440px;
    height: 335px;
    @media @phone {
      height: 200px;
    }
    margin-left: auto;
    margin-right: auto;
    background-size: cover;
    background-repeat: no-repeat;
    .sol-text-overlay {
      &:extend(.flex-centered);
      width: 513px;
      height: 85px;
      position: absolute;
      left: 0;
      bottom: 0;
      background: @white;
      border-top-right-radius: 5px;
      font-weight: bold;
      color: @black;
      @media @tablet-strict {
        &:extend(.flex-centered);
        width: 41%;
        height: 65px;
      }
      @media @phone {
        &:extend(.flex-centered);
        width: 61%;
        height: 50px;
        padding-left: 30px;
        padding-right: 30px;
      }
      h1 {
        @media @tablet-strict {
          font-size: 25px !important;
        }
        @media @phone {
          margin: 0;
          line-height: 1;
          font-size: 20px;
        }
      }
    }
  }
}
