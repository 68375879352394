.sol-related-info-container {
  padding: 30px 11.5%;
  @media @phone {
    padding: 21px 4.2%;
  }
  .sol-related-info__title {
    font-size: 25px;
    font-weight: bold;
    text-align: left;
    color: @black;
    margin-bottom: 23px;
    @media @tablet-strict {
      font-size: 21px;
    }
    @media @phone {
      font-size: 18px;
      margin-bottom: 14px;
    }
  }
  .sol-related {
    background-color: @related-box-bg;
    margin-top: 5px;
    padding-bottom: 8px;
    padding-left: 0;
    margin-bottom: 10px;
    &-item {
      background-color: @related-box-item-bg;
      border-radius: 5px;
      padding: 7px 26px;
      margin-bottom: 5px;
      text-decoration: none;
      line-height: 23px;
      display: inline-flex;
      align-items: center;
      &:hover {
        .sol-related-item-name {
          text-decoration: underline;
        }
      }
      &-name {
        color: @related-box-link-text;
        font-size: 17px;
        font-weight: 600;
        text-align: left;
        margin-right: 24px;
        .transition(color);
      }
      &-size {
        display: none;
      }
      & i.fa {
        width: 17px;
        height: 17px;
        margin-right: 5px;
        border-radius: 50%;
        color: @related-box-icon;
        border-color: @related-box-icon;
        font-size: 16px;
        line-height: 15px;
        text-align: center;
        position: relative;
        top: 1px;
        &.sol-link-icon {
          border: 1px solid @related-box-icon;
        }
      }
      &:hover &-name {
        color: @related-box-link-hover;
      }
    }
    div &-item &-item-name {
      text-decoration: none;
      color: @related-box-text;
    }
  }
}

// När det ligger på undersida
.sol-related-info--subpage {
  .sol-related-info-container {
    padding-left: 7.5%;
    padding-right: 5.5%;
    @media @tablet-strict {
      padding: 30px 0;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
    @media @phone {
      padding: 21px 4.2%;
    }
  }
}