.sol-wide-image {
  margin-left: -10.1%;
   margin-right: -10.1%;
  @media (max-width: 900px) {
    max-width: none;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }
  img {
    max-width: none;
    max-height: inherit;
  }
  .sv-portlet-image-caption {
    @media (max-width: 900px) {
      padding-right: 15px;
    }

  }
}
.sol-service-message-wrapper {
  padding-left: 25%;
  padding-right: 25%;
  @media @tablet {
    padding: 35px 10.5% 30px;
  }
  @media @phone {
    padding: 25px 5.3%;
  }
  .sol-contact-rating-cnt {
    margin-top: 25px;
  }
  .pagecontent {
      margin-left: auto;
      margin-right: auto;
  }
  .sol-svericemeddelande-status {
    &--ongoing, &--completed {
      position: relative;
      left: 14px;
      width: 135px;
      height: 22px;
      margin-bottom: 30px;
      .flex-centered();
      border-radius: 15px;
      &__icon {
        width: 28px;
        height: 28px;
        background-color: @white;
        border-radius: 50%;
        position: absolute;
        left: -14px;
        top: 50%;
        transform: translateY(-50%);
        background-size: 12px 14px;
        background-position: center center;
        background-repeat: no-repeat;
      }
      &__text {
        color: @white;
        font-size: 11px;
        line-height: 1.31;
      }
    }
    &--ongoing {
      background-color: @deep-rose;
      &__icon {
        border: 2px solid @deep-rose;
        background-image: url('/webdav/files/System/resources/graphics/icons/Wrench.svg');
      }
    }
    &--completed {
      background-color: @light-olive-green;
      &__icon {
        border: 2px solid @light-olive-green;
        background-image: url('/webdav/files/System/resources/graphics/icons/Wrench-green.svg');
      }
    }
  }
  .sv-image-portlet {
    margin-bottom: 25px;
  }

  .sol-collapsible-decoration {
    margin-left: 0;
    margin-right: auto;
  }
  .sol-btn {
    padding-left: 0;
    padding-right: 0;
    width: 285px;
    margin-top: 31px;
    text-transform: none;
    @media @phone {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }


  .sol-publish-info {
    padding-left: 0;
    margin-bottom: 0;
  }
}

.sol-flex {
  display: flex;
  @media @phone {
    flex-direction: column;
  }
  > div {
    //margin-top: 50px;
    @media @phone {
      margin-top: 15px;
    }
  }
}
