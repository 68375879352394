.sol-file-listing {
  @file-listing__header-bg: @downriver;
  @media @tablet-strict {
    max-width: 79%;
  }
  @media @phone {
    margin-left: auto;
    margin-right: auto;
    max-width: 92%;
  }
  .sv-text-portlet-content {
    .font-normal {
      margin: 0;
      background-color: @downriver;
      padding: 10px 19px 12px;
      color: @white;
      font-size: 15px;
      font-weight: bold
    }
  }
  table {
    border: solid 1px #ebf4f7;
    tr {
      padding-top: 12px;
      padding-bottom: 12px;
      display: block;
      padding: 12px 18px;
      td {
        &.sv-file-portlet-right {
          display: none;
        }
        padding-top: 0;
        padding-bottom: 0;
        border: none;
        a {
          font-size: 15px;
          line-height: 1.47;
          text-align: left;
          color: #0e3c5d;
        }
      }
    }
  }
}