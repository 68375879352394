.sol-service-message-listing-page-wrapper {
  width: 55%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 50px;
  @media @tablet-strict {
    padding-top: 25px;
    width: 79.2%;
  }
  @media @phone {
    padding-top: 15px;
    width: 90%;
  }
  h1.font-heading-1 {
    margin-right: 5%;
  }
  .sol-service-messages-list {
    padding-left: 60px;
    margin-bottom: 45px;
    @media @tablet-strict {
      padding-left: 30px;
    }
    @media @phone {
      padding-left: 15px;
    }
  }
  .sv-channel-item {
    &:not(:last-child) {
      .sol-service-message {
        &__title {
          &::before {
            content: "";
            display: inline-block;
            position: absolute;
            background-color: #ececec;
            left: -30px;
            top: 3px;
            width: 3px;
            height: 165px;
            @media @phone {
              height: 265px;
              left: -15px;
            }
            @media (max-width: 320px) {
              height: 320px;
            }
          }
        }
      }
    }
  }
  .sol-service-message {
    padding-bottom: 17px;
    border-bottom: 1px solid #ececec;
    height: 145px;
    text-decoration: none;
    display: block;
    &:hover {
      .sol-service-message__preamble  {
        text-decoration: underline;
      }
    }
    @media @tablet-strict {

    }
    @media @phone {
    height: 245px;
    }
    /*@media (max-width: 320px) {
      height: 300px;
    }*/
    &__date {
      margin-bottom: 13px;
      > span {
        font-size: 13px;
        font-weight: 600;
        line-height: 0.77;
        text-align: left;
        color: @gray-3;
      }
    }
    &__title {
      font-size: 18px;
      font-weight: bold;
      text-align: left;
      color: @black;
      margin-bottom: 8px;
      position: relative;
      line-height: 1;
    }
    &__preamble {
      font-size: 18px;
      line-height: 1.33;
      text-align: left;
      color: @black;
      padding-right: 8%;
      @media @tablet-strict {
        padding-right: 2%;
      }
    }
    &[data-status="ongoing"] {
      .sol-service-message__title {
        &::after {
          content: "";
          display: inline-block;
          position: absolute;
          left: -35px;
          top: 3px;
          width: 13px;
          height: 13px;
          border-radius: 50%;
          background-color: @deep-rose;
          z-index: 2;
          @media @phone {
            left: -20.5px;
          }
        }
      }
    }
    &[data-status="completed"] {
        .sol-service-message__title {
          &::after {
            content: "";
            display: inline-block;
            position: absolute;
            left: -35px;
            top: 3px;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            background-color: @light-olive-green;
            z-index: 2;
            @media @phone {
              left: -20.5px;
            }
          }
        }

    }
  }
}
