.search-icon {
  background-color: @search-top-bg;
  border: 2px solid @search-top-border-color;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  @media @tablet {
    height: 100%;
    width: 100%;
  }
  background-image: url(/webdav/files/System/resources/graphics/icons/Sok-vit.svg);
  background-size: 20px 19px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
}

.sol-top-search-field {
  position: relative;

  input[type=search],
  input[type=text] {
    .no-outline-focus;
    .no-appearance;
    .transition(border-color);
    .placeholder( { color: @gray-3; } );
    width: 100%;
    height: 45px;
    padding: 0 11px;
    border: 2px solid @search-top-field-border;
    border-radius: 4px;
    background-color: @search-top-field-bg;
    color: @search-top-field-text;
    font-family: @main-font;
    font-size: 14px;
    line-height: 22px;
    font-weight: 300;
    @media @phone {
      height: 42px;
    }
    &:focus {
      border-color: @search-top-field-border-hover;
    }
  }

  button[type=submit] {
    .no-appearance;
    .focus-outline;
    .transition(background-color);
    position: absolute;
    top: -9px;
    right: -12px;
    width: 45px;
    height: 45px;
    top: 0;
    border-radius: 50%;
    color: @search-top-button-text;
    font-size: 25px;
    text-align: center;
    text-decoration: none;
    border: none;
    box-shadow: none;
    cursor: pointer;
    padding: 0;
    @media @phone {
      width: 42px;
      height: 42px;
    }

    & > i.fa {
      position: relative;
      top: -2px;
    }
  }
  & > div:hover {
    input[type=search],
    input[type=text] {
      border-color: @search-top-field-border-hover;
    }
  }
}
