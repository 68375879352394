.sol-service-message-puffar__cnt {
    width: 90%;
    padding-left: 30px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 70px;
    padding-bottom: 70px;
    display: flex;
    @media @tablet {
      flex-wrap: wrap;
      width: 100%;
      padding-bottom: 46px;
    }
    @media @tablet-strict {
      padding-left: 20px;
      padding-right: 20px;
    }
  @media @phone {
    padding-top: 24px;
    padding-bottom: 0;
  }
    @media @phone {
      padding-left: 0;
      padding-right: 0;
    }
  .sol-service-message {
    &:hover {
      text-decoration: underline;
    }
    &__cnt {
      flex-basis: 31%;
      @media @tablet {
          flex-basis: 85%;
          margin-left: auto;
          margin-right: auto;
      }
      @media @tablet-strict {
        margin-bottom: 64px;

      }
      .sv-text-portlet-content {
        p.font-normal,
        h2 {
          font-size: 31px;
          font-weight: normal;
          line-height: 1;
          margin-top: 0;
          margin-bottom: 20px;
          @media @tablet-strict {
            font-size: 27px;
          }
          @media @phone {
            margin-left: 9.5px;
            font-size: 26px;
          }
        }
      }
    }
    &__btn {
      .sol-btn();
      margin-top: 35px;
      margin-left: 30px;
      padding: 10px 34px;
      font-size: 17px;
      font-weight: 700;
      position: relative;
      text-transform: inherit;
      @media @phone {
        margin-bottom: 42px;
      }
    }
  }
  .sv-portlet:not(.sol-service-message-widget) {
    flex-basis: 64%;
  }
  .sol-service-messages-list {
    padding-left: 60px;
    margin-bottom: 45px;
    @media @tablet-strict {
      padding-left: 30px;
    }
    @media @phone {
      padding-left: 15px;
    }
  }
  .sv-channel-item {
    margin-bottom: 0;
      .sol-service-message {
          position: relative;
          padding-top: 15px;
          &::before {
            content: "";
            display: inline-block;
            position: absolute;
            background-color: #ececec;
            left: -30px;
            top: 3px;
            width: 3px;
            bottom: -5px;
            @media @tablet-strict {
            }
            @media @phone {
              left: -15px;
            }
            @media (max-width: 320px) {
            }
          }
        &__title {
        }
      }
      &:first-child {
        .sol-service-message {
          padding-top: 0;
          min-height: 55px;
        }
      }
      &:last-child {
        .sol-service-message {
          &:before {
            height: 17px;
            bottom: auto;
          }
        }
      }
  }
  .sol-service-message {
    padding-bottom: 17px;
    border-bottom: 1px solid #ececec;
    padding-bottom: 10px;
    text-decoration: none;
    display: block;
    margin-left: 30px;
    &:hover {
      .sol-service-message__preamble  {
        text-decoration: underline;
      }
    }
    &__date {
      margin-bottom: 3px;
      > span {
        font-size: 14px;
        font-weight: 600;
        line-height: 0.77;
        text-align: left;
        color: @gray-3;
      }
    }
    &__title {
      font-size: 17px;
      font-weight: bold;
      line-height: 0.99;
      text-align: left;
      color: @black;
      margin-bottom: 8px;
      position: relative;
      line-height: 1;
    }
    &__preamble {
      font-size: 18px;
      line-height: 1.33;
      text-align: left;
      color: @black;
      padding-right: 8%;
      @media @tablet-strict {
        padding-right: 2%;
      }
    }
    &[data-status="ongoing"] {
      .sol-service-message__title {
        &::after {
          content: "";
          display: inline-block;
          position: absolute;
          left: -35px;
          top: 1px;
          width: 13px;
          height: 13px;
          border-radius: 50%;
          background-color: @deep-rose;
          z-index: 2;
          @media @phone {
            left: -20.5px;
          }
        }
      }
    }
    &[data-status="completed"] {
        .sol-service-message__title {
          &::after {
            content: "";
            display: inline-block;
            position: absolute;
            left: -35px;
            top: 1px;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            background-color: @light-olive-green;
            z-index: 2;
            @media @phone {
              left: -20.5px;
            }
          }
        }

    }
  }
}
