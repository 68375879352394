.sol-status-indicators {
  display: flex;
  height: 30px;
  align-items: center;
  padding-left: 30px;
  border-top: 2px solid #ececec;
  border-bottom: 2px solid #ececec;
  margin-bottom: 40px;
  margin-right: 5%;
  .sol-indicator {
    &--completed, &--ongoing {
      display: inline-flex;
      align-items: center;
      margin-right: 27px;
      &__icon {
        display: inline-flex;
        border-radius: 50%;
        width: 13px;
        height: 13px;
        margin-right: 5px;
      }
      &__text {
        font-size: 11px;
        font-weight: bold;
        line-height: 1;
        letter-spacing: 0.2px;
        text-align: left;
        color: @black;
      }
    }
    &--completed {
      &__icon {
          background-color: @light-olive-green;
        }
    }
    &--ongoing {
      &__icon {
          background-color: @deep-rose;
      }
    }
  }
}
