.sol-events-widget {
    background-color: @pale-sea;
    opacity: 0.98;
    padding: 38px 30px 48px;
    &__top {
      display: flex;
      align-items: center;
      padding-left: 18px;
      padding-bottom: 22px;
      border-bottom: 1px solid tint(@downriver, 80%);
      @media @tablet-strict {
        justify-content: center;
      }
      &__text {
        display: inline-flex;
        flex-direction: column;
        padding-left: 26px;
      }
    }
    .sv-channel {
      padding-left: 22px;
      padding-top: 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        flex-basis: 100%;
        margin: 0;
        @media @tablet-strict {
          flex-basis: 48%;
        }
      }
    }
    &__title {
      font-size: 21px;
      font-weight: bold;
      text-align: left;
      color: @black;
      margin-bottom:8px;
    }
    &__description {
      font-size: 18px;
      text-align: left;
      color: @black;
    }
    .sol-event-item {
      display: flex;
      margin-left: 0;
      margin-right: 0;
      &__title {
        margin-top: 0 !important;
        font-weight: 600;
      }
      &__time-n-place {
        font-weight: 400;
      }
      .sol-left {
        width: 50px;
        height: 50px;
        background-color: @downriver;
        border-radius: 5px;
        margin-right: 13px;
        flex-shrink: 0;
      }
      .sol-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      &__start-date {
        .flex-centered;
        flex-direction: column;
        > span {
          color: @white;
          text-transform: uppercase;
          &:first-child {
            font-size: 12px;
            line-height: 1.8;
          }
          &:nth-child(2) {
            font-size: 23px;
            line-height: 1.04;
          }
        }
      }
    }
    .sol-event-listing-page-btn {
      color: @downriver;
      margin-top: 14px;
      padding-left: 22px;
      .inline-flex-centered;
      text-decoration: none;
      span {
        color: @downriver;
      }
      svg {
        margin-right: 9px;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
.sol-blue-box {
  & .sol-events-widget {
    padding: 0;
  }
}
.sol-events-widget {
  & .sol-event-item {
    &__title {
      font-size: 18px;
      font-weight: 600;
      line-height: 1.11;
      margin-bottom: 0;
    }
  }
}
