footer {
  .sol-bottom {
    background-color: @footer-bg;
    & .sv-grid-main .sv-row > .sv-column-4 {
      padding-right: 30px;
    }

    .sol-footer-contact,
    .sol-footer-links {
      &-item {
        display: block;
        position: relative;
        margin-bottom: 11px;
        padding-left: 30px;
        text-decoration: none;
        color: @footer-text;
        .transition(opacity);
        & i.fa:first-child {
          position: absolute;
          left: 0;
          top: 1px;
          font-size: 17px;
        }

        & i.fa.fa-angle-right {
          width: 18px;
          height: 18px;
          margin-right: 10px;
          padding-left: 2px;
          border: 1px solid rgba(255, 255, 255, 0.7);
          border-radius: 50%;
          opacity: 0.5;
          line-height: 16px;
          text-align: center;
          font-size: 14px;
        }
      }
      & a:hover {
        opacity: 0.7;
        text-decoration: underline;
      }
    }
    .sol-social-media-title {
      font-size: 18px;
      line-height: 1.56;
      text-align: left;
      color: #ffffff;
      margin-bottom: 21px;
    }
    .sol-social-media {
      .sol-clearfix;
      .sol-social-media-item > a {
        width: 40px;
        height: 40px;
        margin-right: 7px;
        float: left;
        border: 2px solid @footer-text;
        border-radius: 50%;
        color: @footer-text;
        font-size: 21px;
        text-align: center;
        line-height: 34px;
        .transition(opacity);
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

  h1,
  p {
    &.font-footer-header {
      margin-bottom: 20px;
      margin-top: 0;
      padding-bottom: 10px;
      border-bottom: 1px solid fadeout(@footer-text, 80%);
      font-size: 14px;
      font-family: @main-font;
      color: @footer-text;
      line-height: 22px;
      font-weight: 700;
    }
  }

  p.font-footer-text {
    margin-bottom: 11px;
    font-size: 14px;
    font-family: @main-font;
    color: @footer-text;
    line-height: 22px;
    font-weight: 700;
    a:hover {
      color: fadeout(@footer-text, 30%);
      text-decoration: underline;
    }
  }
}
