.sol-menu-overlay {
  display: block !important;
  .sol-menu {
    background-color: @ms-mobile-menu-bg;
    position: fixed;
    top: 120px;
    left: 0;
    right: 0;
    z-index: 49;
    overflow: hidden;
    box-shadow: 0 3px 14px 0 rgba(0, 0, 0, .43);
    /*@media @tablet-strict {
      position: absolute;
      top: 0;
    }*/
      
    @media @phone {
      transition: all 0.3s ease;
      height: 0;
    }
      
    & > ul {
      border-top: 1px solid @mobile-menu-border;
      background-color: @downriver;
      > li {
        &:last-child {
          > div  {
            > a {
              border-color: @downriver;
              border-bottom: 0 !important;
            }
          }
        }
      }
    }

    a {
      margin: 0;
    }
      
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
      
    & .sol-menu-item {
      position: relative;
      .sol-menu-item__button {
        max-height: 100%;
        z-index: 99;
        cursor: pointer;
      }
      > a {
        color: @white;
        transition: background-color .2s ease-in-out;

        span {
          color: @white;
        }
      }
      i {
        font-family: FontAwesome;
        font-style: normal;
        color: @white;
      }
      & ul {
        width: 100%;
      }
      &[data-menu-level="1"] {
        > a {
          color: @white;
          background-color: @downriver;
          > span {
            font-size: 17px;
            font-weight: bold;
            text-align: left;
            color: @white;
          }
          &:hover {
            background-color: #06253c !important;
          }
        }
        > .sol-menu-item__button {
          > i {
            color: @white;
          }
        }
      }
      &:not([data-menu-level="1"]) {
        background-color: @white;
        & > a {
          transition: background-color .2s ease-in-out;
          &:hover {
            background-color: @submenu-bg--hover;
          }
        }
        & > .sol-menu-item__button {
          > i {
            color: @black;
          }
        }
      }
      &[data-expanded="false"]:not([data-menu-level="1"]) {
        & > a {
          background-color: @submenu-bg--expanded--current;
          & > span {
            color: @submenu-text--expanded--current;
          }
        }
        & > .sol-menu-item__button {
          > i {
            color: @submenu-icon-color--expanded--current;
          }
        }
      }
      &[data-expanded="true"]:not([data-menu-level="1"]) {
        & > a {
          background-color: @menu-item-bg--expanded;
          & > span {
            color: @menu-item-text--expanded;
          }
        }
        & > a {
          & > span {
            font-weight: bold;
          }
        }
        &[data-has-children="true"] {
          & > a {
            & > span {
              color: @menu-item-text--expanded;
            }
          }
          & > .sol-menu-item__button {
            > i {
              color: @submenu-text--expanded--current;
            }
          }
        }

        &[data-is-current="false"] {
          & > a {
            &:hover {
              background-color: #0e3c5d;
            }
          }
        }
        &[data-is-current="true"] {
          & > a {
            background-color: @menu-item-bg--expanded--current;
            & > span {
              color: @menu-item-text--expanded--current;
            }
          }
          & > .sol-menu-item__button {
            > i {
              color: @menu-item-text--expanded--current;
            }
          }
        }
      }

      & a {
        .focus-outline;
        display: block;
        position: relative;
        padding: 10px 49px 10px 19px;
        border-bottom: 1px solid @mobile-menu-border;
        text-decoration: none;
        margin-left: 1px;
        margin-right: 1px;
        color: @mobile-menu-text;

        & span {
          color: @black;
          font-size: 17px;
          font-weight: 400;
        }
      }
      & .sol-menu-item__button {
        position: absolute;
        top: 0;
        height: 43px;
        right: 0;
        width: 50px;
        .no-appearance;
        .focus-outline;
        width: 50px;
        border-left: 1px solid @mobile-menu-border;

        & i {
          position: absolute;
          right: 16px;
          top: 50%;
          width: 20px;
          height: 20px;
          margin-top: -10px;
          text-align: center;
          padding-top: 3px;
          padding-left: 3px;
          .transition(all);
        }
      }

      &[data-menu-level="2"] a {
        padding-left: 30px;
      }

      &[data-menu-level="3"] a {
        padding-left: 40px;
      }

      &[data-menu-level="4"] a {
        padding-left: 50px;
      }

      &[data-menu-level="5"] a {
        padding-left: 60px;
      }

      &[data-expanded="true"] > .sol-menu-item__button {
        & i {
          .rotate(90deg);
        }
      }


      &[data-node-type="sv:folder"] {
        & > .sol-menu-item__button {
          width: 100%;
          border-left: none;
        }
      }
    }
  }
}

@media @phone {
  &.sol-menu-toggle-button__active .sol-overlay .sol-menu {
    visibility: visible;
    opacity: 1;
    overflow: auto;
  }
}

@media (min-width: @md-min) {
  .sol-uvk {
    .sol-menu-overlay .sol-menu {
      position: absolute !important;
      transition: all 0.3s ease;
      width: 0;
      right: 0;
      top: 0 !important;
      bottom: 0;
      overflow-y: hidden;
      left: auto;
      height: 100vh;
      z-index: 999;
      visibility: hidden;
      &:before {
        content: '';
        display: block;
        height: 100px;
        background-color: #fff;
        position: relative;
      }
      @media @phone {
        width: 100%;
        left: 0;
      }
      a {
        margin: 0;
      }
      ul {
        border-top: 0;
      }

      > ul {
        height: calc(~"100% - 100px");
        overflow-y: auto;
        overflow-x: hidden;
        > li:last-child {
          a {
            border-bottom: 0 !important;
          }
        }
      }
      .sol-menu-item {
        min-width: 300px;
        .sol-menu-item__button {
          max-height: 100%;
          cursor: pointer;
        }
        a {
          color: @white;
        }
        i {
          color: @white;
        }
        &[data-menu-level="1"] {
          > a {
            color: @white;
            background-color: @downriver;
            > span {
              font-size: 17px;
              font-weight: bold;
              text-align: left;
              color: @white;
            }
          }
          > .sol-menu-item__button {
            > i {
              color: @white;
            }
          }
        }

        &:not([data-menu-level="1"]) {
          > a {
            transition: background-color .2s ease-in-out;
            &:hover {
              background-color: @submenu-bg--hover;
            }
          }
        }

        &[data-expanded="true"]:not([data-menu-level="1"]) {
          & > a {
            background-color: @submenu-bg--expanded--current;
            & > span {
              color: @submenu-text--expanded--current;
            }
          }
          & > .sol-menu-item__button {
            > i {
              color: @submenu-icon-color--expanded--current;
            }
          }
          &[data-is-current="true"] {
            & > a {
              background-color: @submenu-bg--current;
              & span {
                color: @black;
              }
            }
            & > .sol-menu-item__button {
              > i {
                color: @submenu-icon-color--current;
              }
            }
          }
          }



        &[data-expanded="true"] {
          & > button i {
            color: @white;
            transform: rotate(90deg);
          }
        }

        &[data-expanded="true"][data-has-children="false"] > button {
          & i {
            color: @submenu-text--expanded;
          }
        }
        &[data-menu-level="2"] {
          > a {
            > span {
              font-size: 18px;
              text-align: left;
            }
          }

        }
        &[data-menu-level="3"] {
          > a {
            span {
              font-size: 18px;
            }
          }
          &[data-expanded=true] {
            > a {
              span {
                font-size: 18px;
              }
            }

          }
        }
        &[data-menu-level="2"], &[data-menu-level="3"] {
          &[data-is-current=true] {
            > a {
              span {
                font-weight: bold;
                font-size: 18px;
              }
            }

          }
        }
        &[data-menu-level="3"] {
          &[data-is-current=true] {
            > a {
            }
          }
        }
        &[data-menu-level="4"] {
          &[data-is-current=true] {
            > a {
              > span {
                font-weight: bold;
                font-size: 18px;
              }
            }
          }
        }
      }
    }
    & .sol-menu-toggle-button--active ~ .sol-menu-overlay .sol-menu {
      width: 445px;
      visibility: visible;

      @media @phone {
        width: 100%;
      }
    }
  }

  header #Tradmeny {
    position: fixed;
    background-color: #000;
    opacity: 0;
    visibility: hidden;
    z-index: 998;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: all 0.2s ease;

    &[aria-hidden="true"] {
      opacity: 0.55;
      visibility: visible;
    }
  }
}

@media @tablet-strict {
  .sol-uvk {
    .sol-menu-overlay .sol-menu {
      top: 45px !important;
      height:50vh;
      &:before {
        height: 85px;
      }

      & > ul {
        height: ~'calc(100% - 85px)';
        overflow-y: auto;
      }
    }
  }
}

.sol-uvk {
  & .sol-menu-toggle-button {
    @media @tablet {
      display: block;
      width: auto;
      font-weight: normal;
      top: 87px;
      right: 20px;
    }

    @media @phone {
      top: 82px;
    }
  }

  & .sol-menu-toggle-button--active ~ .sol-menu-overlay .sol-menu {
    @media @phone {
      width: 100%;
      overflow: auto;
      height: ~'calc(100vh - 121px)';
    }
  }
}
