.sol-uvk {
  .sol-slide-menu-container {
    z-index: 52;
    position: absolute !important;
    transition: all 0.3s ease;
    background-color: @ms-mobile-menu-bg;
    width: 0;
    right: 0;
    top: 0 !important;
    bottom: 0;
    overflow-y: hidden;
    left: auto;
    height: 100%;
    z-index: 999;
    visibility: hidden;
    &__top {
      height: 100px;
      background-color: #fff;
      position: relative;
    }
    &.sol-active {
      width: 445px;
    }
    a {
      margin: 0;
    }
    ul {
      border-top: 0;
      background-color: @white;
    }

    > ul {
        height: calc(~"100% - 100px");
        overflow-y: auto;
        overflow-x: hidden;
        background-color: @downriver;
        > li:last-child {
          .sol-menu-item-link {
            border-bottom: 0 !important;
          }
        }
    }
    .sol-menu-item {
      min-width: 300px;
      .sol-menu-button {
        max-height: 100%;
        z-index: 99;
        cursor: pointer;
      }
      .sol-menu-item-link {
        color: @white;
      }
      .fa {
        color: @white;
      }
      &[data-menu-level="0"] {
        > .sol-menu-item-link {
          color: @white;
          background-color: @downriver;
          > .sol-menu-item-display-name {
             font-size: 16px;
             font-weight: bold;
             text-align: left;
             color: @white;
          }
        }
        > .sol-menu-button {
          > .fa {
            color: @white;
          }
        }
      }

      &:not([data-menu-level="0"]) {
        > .sol-menu-item-link {
          transition: background-color .2s ease-in-out;
          &:hover {
            background-color: @submenu-bg--hover;
          }
        }
      }

      &[data-expanded="true"]:not([data-menu-level="0"]) {
        & > .sol-menu-item-link {
          background-color: @submenu-bg--expanded--current;
          & > .sol-menu-item-display-name {
            color: @submenu-text--expanded--current;
          }
        }
        & > .sol-menu-button {
          > i.fa {
            color: @submenu-icon-color--expanded--current;
          }
        }
        &[data-is-current="true"] {
          & > .sol-menu-item-link {
            background-color: @submenu-bg--current;
            & .sol-menu-item-display-name {
              color: @black;
            }
          }
          & > .sol-menu-button {
            > i.fa {
              color: @submenu-icon-color--current;
            }
          }
        }
        }



      &[data-expanded="true"] {
        & > .sol-menu-item-link i.fa {
          color: @white;
          transform: rotate(90deg);
        }
      }

      &[data-expanded="true"][data-has-children="false"] > .sol-menu-item-link {
        & i.fa{
          color: @submenu-text--expanded;
        }
      }
      &[data-menu-level="1"] {
        > .sol-menu-item-link {
          > .sol-menu-item-display-name {
            font-size: 18px;
             text-align: left;
          }
        }

      }
      &[data-menu-level="2"] {
        > .sol-menu-item-link {
          .sol-menu-item-display-name {
            font-size: 18px;
          }
        }
        &[data-expanded=true] {
          > .sol-menu-item-link {
            .sol-menu-item-display-name {
              font-size: 18px;
            }
          }

        }
      }
      &[data-menu-level="1"], &[data-menu-level="2"] {
        &[data-is-current=true] {
          > .sol-menu-item-link {
            .sol-menu-item-display-name {
              font-weight: bold;
              font-size: 18px;
            }
          }

        }
      }
      &[data-menu-level="2"] {
        &[data-is-current=true] {
          > .sol-menu-item-link {
          }
        }
      }
      &[data-menu-level="3"] {
        &[data-is-current=true] {
          > .sol-menu-item-link {
            > .sol-menu-item-display-name {
              font-weight: bold;
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}
