.sol-uvk-contact-box {
  background-color: @white;
  padding-left: 4%;
  padding-right: 4%;
  padding-top: 38px;
  padding-bottom: 46px;
  display: flex;
  flex-wrap: wrap;
  word-break: break-word;
  .sv-grid-main {
    width: 100% !important;
  }
  @media @tablet {
    flex-basis: 100%;
    padding-left: 7%;
    padding-right: 7%;
  }
  &__left {
    flex-basis: 50%;
    max-width: 50%;
    flex-grow: 0;
  }
  &__right {
    flex-basis: 50%;
    max-width: 50%;
    flex-grow: 0;
  }
  a {
    font-size: 24px;
    line-height: 1.17;
    text-align: left;
    color: @downriver;
    @media @tablet-strict {
      font-size: 20px;
    }
    @media @phone {
      font-size: 19px;
    }
    &[href^="tel:"], &[href^="mailto:"] {
      display: block;
    }
    &[href^="tel:"] {
      text-decoration: none;
      position: relative;
      margin-left: 27px;
      &::before {
        display: inline-block;
        content: "";
        width: 14px;
        height: 23px;
        background-image: url('/webdav/files/System/resources/graphics/icons/Phone.svg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: absolute;
        line-height: 1;
        left: -27px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    &[href^="mailto:"] {
      position: relative;
      text-decoration: none;
      margin-left: 30px;
      &::before {
        display: inline-block;
        content: "";
        width: 21px;
        height: 17px;
        background-image: url('/webdav/files/System/resources/graphics/icons/Mail.svg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          background-size: 20px;
        }
        position: absolute;
        line-height: 1;
        left: -30px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
