.sol-header {
  position: relative;
  z-index: 55;
  width: 100%;
  background-color: @header-top-bg;
  @media @phone {
    top: 0;
    left: 0;
    right: 0;
    height: 120px;
  }
  .sv-grid-main {
    @media @phone {
      padding: 0;
    }
  }
  .sol-top {
    position: relative;
    padding-left: 4.8%;
    //padding-right: 4.1%;
    padding-top: 25px;
    padding-bottom: 25px;
    background-color: @header-top-bg;
    height: 100px;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    @media @tablet-strict {
      padding: 0;
      height: 45px;
      width: 100%;
      min-height: 45px;
      background-color: @black;
      display: flex;
      justify-content: space-around;
    }
    @media @phone {
      height: 45px;
      width: 100%;
      max-width: 1440px;
      text-align: center;
      background-color: @header-top-mobile-bg;
      padding: 0;
      background-color: @black;
      display: flex;
      justify-content: space-around;
    }
    > .sv-script-portlet {
      @media @tablet {
        width: 100%;
      }
    }
    .sol-top-logo {
      position: absolute;
      height: auto;
      top: 50%;
      transform: translateY(-50%);
      text-decoration: none !important;
      color:  @ms-color-1;
      font-size: 30px;
      font-weight: 500;
      > img {
        width: 196px;
      }
    @media @tablet-strict {
      display: none;
    }
    @media @phone {
      display: none;
      max-width: 196px;
    }
      .sol-top-logo-text {
        text-decoration: none;
      }
    }
  }
  .sol-top-search {
    position: relative;
    .transition(~  'background-color, height');
    background-color: @search-top-bg;
    height: 0;
    overflow: hidden;
    display: none;
    &.sol-active {
      height: 100px;
      background-color: @search-top-bg-active;
      @media @tablet {
        height: 100px;
      }
      @media @phone {
        height: 80px;
      }
    }
    #sol-main-search-btn {
      line-height: 1;
      @media @tablet {
        display: none;
      }
    }
    & form {
      position: relative;
      display: block;
      width: 58%;
      @media @tablet {
        width: 70%;
        margin-top: 25px;
        margin-bottom: 25px;
      }
      @media @phone {
        margin-top: 20px;
        margin-bottom: 20px;
      }
      @media @phone-sm {
        width: 90%;
      }
    }

    &-close {
      .no-appearance;
      .focus-outline;
      .transition(color);
      position: absolute;
      right: -74px;
      top: 9px;
      color: @color-1;
      text-decoration: underline;
      cursor: pointer;
      @media @tablet {
        display: none;
      }
      &:hover {
        color: @search-top-close;
      }
    }
  }
  .sol-hamburger-wrapper--desktop,
  .sol-menu-toggle-button {
    position: absolute;
    z-index: 999;
    display: flex;
    flex-direction: column;
    height: 65px;
  }


  .sol-bottom {
    background-color: @header-menu-bg;
    text-align: center;
    @media @tablet {
      display: none;
    }
  }
}

.sol-to-content {
  position: absolute;
  right: -5000px;
  top: -3px;
  width: 135px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-weight: 600;
  background-color: @color-1;
  font-size: 14px;
  color: @white;
  &:focus {
    right: 100%;
    @media @phone {
      position: static;
      width: 100%;
      margin-bottom: 10px;
    }
  }
}
.sol-uvk {
  .sol-hamburger-wrapper--desktop,
  .sol-menu-toggle-button {
    right: 60px;
    top: 50px;
    transform: translateY(-50%);
    z-index: 999;
    &.sol-hamburger {
      z-index: 999;
    }
    @media @tablet {
      display: none;
    }
  }
  .sol-tool-nav-item__search {
    right: 136px;
  }
}

