.sol-news-filter {
    position: relative;
    margin-bottom: 20px;
  &__cnt {
    display: flex;
    @media @tablet {
      flex-direction: column;
    }
  }
    .sol-news-filter-button {
      .focus-outline;
      display: flex;
      width: 310px;
      height: 40px;
      margin-right: 25px;
      background-color: @white;
      outline: none;
      border: none;
      border-left: 5px solid tint(@ms-color-1, 40%);
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);

      font-size: 14px;
      font-weight: bold;
      text-align: left;
      line-height: 32px;

      padding-left: 15px;
      padding-right: 15px;
      .sol-news-filter-button__text {
        flex-grow: 1;
      }
      .sol-news-filter-button__arrow {
        display: inline-flex;
        align-items: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 2px solid tint(@ms-color-1, 40%);
        i.fa {
          width: 100%;
          text-align: center;
          transition-duration: 0.6s;
        }
      }
    }
    .sol-news-filter-button--expanded {
      background-color: @ms-color-1;
      color: @white;
      .sol-news-filter-button__text {
        color: @white;
      }
      .sol-news-filter-button__arrow {
        background-color: @white;
        color: @black;
        i.fa {
          transform: rotate(90deg);
          transition-duration: 0.5s;
        }
      }

    }
    > .sol-news-filter-list {
        @media @phone {
          width: 90%;
          left: 50%;
          transform: translateX(-50%);
        }
    }
}

.sol-news-filter {
  > .sol-news-filter-list {
    position: absolute;
    width: 90%;
    left: 5%;
    @media @phone {
      left: 50%;
    }
  }
}

.sol-news-filter-list {
  list-style: none;
  padding-left: 0;
  top: 100%;
  left: 15px;
  width: 280px;
  display: none;
  z-index: 1;
  background-color: @white;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
  margin-top: 0;
  margin-bottom: 0;
}

.sv-archivemenu-portlet {
  &:first-child {
    margin-right: 25px;
    @media @phone {
      mragin-right: 0;
    }
  }
}
#sol-news-filter-button-month, #sol-news-filter-button-year {
  margin: 0;
}
.sol-news-filter-list--months {
  display: block;
  position: initial;
  width: auto;
  box-shadow: none;
}
.sol-news-filter__item {
  padding: 10px 20px;
  font-size: 18px;
  line-height: 1.33;
  a {
    text-decoration: none;
    display: inline-block;
    width: 100%;
  }
  &:hover {
    background-color: @ms-color-1;
    a {
      color: @white;
    }
  }
}
.sol-news-filter__header {
  background-color: tint(@ms-color-1, 80%);
  padding: 5px;
  text-align: center;
  font-weight: bold;
}
