.sol-event-listing-page {
  &__event-list {
    padding-left: 30px;
    border-bottom: 5px solid #ebf4f7;;
    &__wrapper {
      margin-bottom: 30px;
    }
    &__show-more-btn {
      margin-left: 30px;
    }
  }
}
.sol-event-top-image > div {
  text-align: center;
}
.sol-uvk {
  .sol-event-item {
    &--img, &--no-img {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      padding-top: 20px;
      padding-bottom: 20px;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      min-height: 164px;
      text-decoration: none;
      border-bottom: 1px solid #ececec;
      @media @phone {
        padding-left: 120px;
      }
      .double-slash {
        display: inline-block;
        margin-left: 5px;
        margin-right: 5px;
      }
    }
    &--img {
      padding-left: 222px;
      @media @phone {
        padding-left: 116px;
      }
      .sol-event-item__description {
        @media @phone {
          display: none;
        }
      }
    }
    &--no-img {
      @media @phone {
        padding-left: 0;
      }
    }
    &__img {
      position: absolute;
      top: 15px;
      left: 0;
      width: 200px;
      height: 130px;
      margin-right: auto;
      background-size: cover;
      background-position: 50% 50%;
      @media @phone {
        width: 100px;
        height: 100px;
      }
    }
    &__date-info {
      display: flex;
      flex-wrap: wrap;
      div, span {
        font-size: 11px;
        font-weight: 600;
        line-height: 1;
        color: @gray-3;
        @media @phone {
          line-height: 1.5;
        }
      }
      div {
        &:first-child {
          text-transform: uppercase;
        }
      }
    }
    &__title {
      font-size: 18px;
      font-weight: bold;
      line-height: 1.11;
      color: @black;
      margin-top: 10px;
      @media @phone {
        margin-top: 0;
      }
    }
    &__description {
      font-size: 18px;
      line-height: 1.33;
      color: @black;
      @media @tablet {
        font-size: 17px;
      }
    }
  }
}
