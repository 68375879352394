.sol-job-openings {

    .sol-job-item {
        &:nth-child(even) {
            background-color: rgb(235, 244, 247);
        }
        padding:5px;
        h2 {
            color:rgb(14, 60, 93);
            font-size:19px;
        }
        a, p {
        font-size: 16px;
        }
    }
}
