.sol-mobile-menu-wrapper {
  position: relative;
  @media @desktop {
    display: none;
  }

}

.sol-mobile-menu-container, .sol-slide-menu-container {
  background-color: white;
  left: 0;
  right: 0;
  z-index: 49;
  overflow: hidden;
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, .43);
  @media @tablet-strict {
    position: absolute;
    top: 0;
  }


  & > ul {
    border-top: 1px solid @mobile-menu-border;
  }

  & .sol-menu-list {
    width: 100%;
  }

  & .sol-menu-item {
    position: relative;
    .sol-menu-button {
      max-height: 100%;
    }
    .sol-menu-item-link {
      color: @white;
    }
    .fa {
      color: @white;
    }
    &[data-menu-level="0"] {
      > .sol-menu-item-link {
        color: @white;
      }
      > .sol-menu-button {
        > .fa {
          color: @white;
        }
      }
    }


    &-link {
      .focus-outline;
      display: block;
      position: relative;
      padding: 10px 49px 10px 19px;
      border-bottom: 1px solid @mobile-menu-border;
      text-decoration: none;
      margin-left: 1px;
      margin-right: 1px;
      color: @mobile-menu-text;

      & .sol-menu-item-display-name {
        font-size: 14px;
        font-weight: 400;
      }
    }
    & .sol-menu-button {
      position: absolute;
      top: 0;
      height: 43px;
      right: 0;
      width: 50px;
      .no-appearance;
      .focus-outline;
      width: 50px;
      border-left: 1px solid @mobile-menu-border;

      & i {
        position: absolute;
        right: 16px;
        top: 50%;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        text-align: center;
        padding-top: 3px;
        padding-left: 3px;
        .transition(all);
      }
    }

    &[data-menu-level="1"] .sol-menu-item-link {
      padding-left: 30px;
    }

    &[data-menu-level="2"] .sol-menu-item-link {
      padding-left: 40px;
    }

    &[data-menu-level="3"] .sol-menu-item-link {
      padding-left: 50px;
    }

    &[data-menu-level="4"] .sol-menu-item-link {
      padding-left: 60px;
    }

    &[data-expanded="true"] > .sol-menu-button {
      & i.fa {
        .rotate(90deg);
      }
    }


    &[data-node-type="sv:folder"] {
      & > .sol-menu-button {
        width: 100%;
        border-left: none;
      }
    }
  }
}

.sol-mobile-menu-container {
  @media @phone {
    display: none;
  }
  position: absolute;
  background-color: white;
  > div {
    position: relative;
    display: none;
    @media @tablet-strict {
      display: block;
      height: 85px;
      background-color: @white;
    }
    > button {
      position: absolute;
      right: 14px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  @media @tablet-strict {
    z-index: 999;
    position: fixed;
    transition: width 0.3s;
    background-color: @ms-mobile-menu-bg;
    width: 0;
    height: 50vh;
    right: 0;
    top: 45px;
    overflow: hidden;
    left: auto;
  }
  @media @phone {
    background-color: @ms-mobile-menu-bg;
    width: 100%;
    top: 0;
  }
  a {
    margin: 0;
  }
  ul {
    border-top: 0;
  }
  .sol-menu-item {
    .sol-menu-button {
      max-height: 100%;
    }
    .sol-menu-item-link {
      color: @white;
    }
    .fa {
      color: @white;
    }
    &[data-menu-level="0"] {
      > .sol-menu-item-link {
        color: @white;
        > .sol-menu-item-display-name {
          font-size: 16px;
          font-weight: bold;
          text-align: left;
          color: @white;
        }
        > .fa {
          color: @white;
        }
      }
    }
    &[data-menu-level="1"] {
      > .sol-menu-item-link {
        > .sol-menu-item-display-name {
          font-size: 18px;
          text-align: left;
          color: #3b3b3b;
        }
      }
    }
  }
}



