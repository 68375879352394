.sol-mini-site, .sol-uvk {
    font-family: 'Source Sans Pro', sans-serif;
    h1, h2, h3, h4, h5, h6 {
      line-height: 1.2em;
      &.sol-hidden {
        display: none !important;
      }
    }
    h1,
    .font-heading-1,
    h1.font-heading-1 {
      font-size: 34px;
      font-weight: bold;
      line-height: 1.2em;
      @media @tablet-strict {
        font-size: 26px;
      }
      @media @phone {
        font-size: 21px;
      }
    }
    h2,
    .font-heading-2,
    h2.font-heading-2 {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 25px;
      font-weight: bold;
      text-align: left;
      color: @ms-text-color;
      line-height: 1.2em;
      @media @tablet-strict {
        font-size: 21px;
      }
      @media @phone {
        font-size: 18px;
      }
}
    h3,
    .font-heading-3,
    h3.font-heading-3 {
      font-size: 22px;
      font-weight: bold;
      line-height: 1.2em;
      text-align: left;
      color: #3b3b3b;
      @media @tablet-strict {
        font-size: 20px;
      }
      @media @phone {
        font-size: 15px;
      }
    }

    h4,
    h4.font-heading-4,
    .font-heading-4 {
      font-size: 20px;
      line-height: 1.2em;
      font-weight: bold;
      @media @tablet-strict {
        font-size: 18px;
      }
      @media @phone {
        font-size: 13px;
      }
    }
    h5,
    h5.font-heading-5,
    .font-heading-5 {
      font-size: 18px;
      line-height: 1.2em;
      font-weight: bold;
      @media @tablet-strict {
        font-size: 16px;
      }
      @media @phone {
        font-size: 11px;
      }
    }
.sv-font-minisajt-ingress, .font-description-old {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 26px;
  font-weight: 300;
  line-height: 1.4em;
  color: @ingress-color;
  @media @tablet-strict {
    font-size: 24px;
  }
  @media @phone {
    font-size: 19px;
  }
}
.sv-font-minisajt-logo-text {
  font-size: 40px;
  font-weight: 500;
  line-height: 1;
  @media @phone {
    max-width: 150px;
    font-size: 20px;
  }
  text-align: left;
  color: @text-color;
  @media @tablet {
    font-size: 24px;
  }
}
.sv-font-minisajt-citat {
  font-size: 27px;
  font-weight: 300;
  line-height: 1.3;
  text-align: center;
  color: @text-color;
  @media @phone {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.3;
    text-align: center;
  }
}
.sv-font-minisajt-citat-forfattare {
  line-height: 3.18;
  text-align: center;
  color: @text-color;
  font-size: 12px;
  font-weight: bold;
  @media @tablet-strict {
    font-size: 16px;
  }
}
.sv-font-minisajt-citat-title {
  font-size: 13px;
  font-weight: 300;
  line-height: 2.69;
  text-align: center;
  @media @tablet-strict {
    font-size: 16px;
  }
}
.sol-blue-box {
  h1.font-heading-1, h2.font-heading-2, h3.font-heading-3,
  h4.font-heading-4, h5.font-heading-5, h6.font-heading-6 {
    @media @tablet {
      font-size: 25px;
      font-weight: 300;
      text-align: left;
    }
  }
  @media @phone {
    .sv-font-topp-text-puff-normal {
     text-align: left;
     color: @text-color;
 }
  }
}
h2.sv-font-topp-text-puff-header {
  font-size: 33px;
  font-weight: bold;
  letter-spacing: 0.4px;
  text-align: center;
  color: @white;
  @media @tablet-strict {
    font-size: 36px;
  }
  @media @phone {
    font-size: 23px;
    font-weight: bold;
    letter-spacing: 0.2px;
    text-align: center;
    margin: 0;
    margin-bottom: 0 !important;
  }
}
p.sv-font-topp-text-puff-normal {
  font-size: 26px;
  font-weight: 300;
  text-align: center;
  color: @white;
  @media @tablet-strict {
    font-size: 23px;
  }
  @media @phone {
    font-size: 15px;
    font-weight: 300;
    text-align: center;
    margin: 0;
  }
}
.sv-font-informationsbox-header {
  margin-bottom: 20px;
  font-size: 31px;
  font-weight: 300;
  text-align: left;
  color: @text-color;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 55px;
  @media @phone {
    padding-left: 45px;
    font-size: 21px;
  }
  &:before {
    content: "\f129";
    display: inline-block;
    font-family: fontAwesome;
    width: 41px;
    height: 41px;
    border-radius: 50%;
    border: 2px solid rgba(14, 60, 93, 0.4);
    margin-right: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0e3c5d;
    position: absolute;
    left: 0;
    @media @phone {
      width: 31px;
      height: 31px;
    }
  }
}
.sv-font-informationsbox-bla-header {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.6;
  text-align: left;
  color: #0e3c5d;
}
.sol-informationbox {
  .font-normal {
    font-size: 18px;
    line-height: 1.67;
    text-align: left;
    color: @text-color;
  }
  strong {
    margin-right: 10px;
  }
}
}
