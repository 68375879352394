.sol-publish-info {
  font-size: 13px;
  font-weight: 400;
  & strong {
    margin-right: 5px;
    font-size: 14px;
    @media @phone {
      font-size: 13px;
    }
    @media @phone-sm {
      display: block;
    }
  }
}
