.sol-uvk {
  .sol-event-search {
    ul {
      list-style: none;
      @media @phone {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .sol-beige-cnt {
      padding-top: 36px;
    }
    .sol-grid {
      position: relative;
      max-width: 770px;
      margin-left: auto;
      margin-right: auto;
      @media @tablet-strict {
        max-width: 80%;
      }
      @media @phone {
        max-width:89%;
      }
      .sol-top {
        height: 255px;
        @media @tablet-strict {
          height: 180px;
        }
        @media @phone {
          height: 270px;
        }
      }
      .sol-flex-row {
        display: flex;
        justify-content:space-between;
        align-items: center;
        margin-bottom: 25px;
        @media @phone {
          flex-direction: column;
          align-items: flex-start;
        }
        h1 {
          margin-bottom: 0;
          @media @phone {
          margin-bottom: 17px;
        }
        }
        .sol-event-buttons {
          @media @phone {
            width: 100%;
          }
        }
        .sol-event-buttons-btn {
          .sol-btn;
          @media @phone {
            width:100%;
          }
        }
      }
      .sol-loading {
        position: absolute;
        top: 150px;
        left: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }
  }
}