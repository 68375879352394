.sol-subpage-wrapper {
  padding-left: 7.5%;
  padding-right: 5.5%;
  @media @tablet {
    width: 80%;
    .margin-auto;
    padding-left: 0;
    padding-right: 0;
  }
  @media @phone {
    width: 89%;
  }
  .sol-clearfix;
  > .sol-left {
    width: 56%;
    float: left;
    @media @tablet {
      float: none;
      width: 100%;
    }
  }
  > .sol-right {
    width: 32%;
    float: right;
    @media @tablet {
      float: none;
      width: 100%;
    }
  }
  & .pagecontent {
    .sol-clearfix;
  }
}
