.sol-startpage-top-images {
  position: relative;
  &-item {
    height: 450px;
    background-position: 50% 50%;
    background-size: cover;
    @media @phone {
      height: 200px;
    }
  }
  & .slick-dots {
    list-style: none;
    margin: 0;
    padding: 10px 0 0 0;
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 0;
    left: 0;
    height: 30px;
    text-align: center;
    & li {
      position: relative;
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.6);
      cursor: pointer;
      margin: 0 3px 5px;
      .transition(~'background-color, color', 100ms);
      & button {
        display: none;
      }
      &[aria-hidden=false] {
        background-color: white;
      }
    }
  }
  &-content {
    position: absolute;
    width: 660px;
    left: 0px;
    top: 127px;
    right: 0px;
    z-index: 10;
    margin-left: auto;
    margin-right: auto;
    border-radius: 3px;
    overflow: hidden;
    @media @phone {
      position: static;
      width: auto;
      border-radius: 0;
    }
    & .sol-top-search {
      padding: 30px 35px 30px 15px;
      background-color: @top-content-search-bg;
      @media @phone {
        background-color: @top-content-search-bg-phone;
      }
    }
  }
}

.sol-startpage-wrapper {
  .sol-home-page-top {
    min-height: 500px;
    display: flex;
    @media @tablet {
      flex-direction: column;
      height: auto;
    }
    &__bg-img {
      width: 43%;
      height: 100%;
      background-size: cover;
      background-position: 50% 50%;
      @media @tablet-strict {
        width: 100%;
        height: 280px !important;
      }
      @media @phone {
        height: 200px !important;
        width: 100%;
      }
    }
    &__shortcuts {
      width: 57%;
      height: auto;
      background-color: @downriver;
      padding-left: 3.5%;
      padding-right: 2.8%;
      padding-top: 45px;
      display: flex;
      flex-wrap: wrap;
      @media @tablet-strict {
        width: 100%;
        padding: 25px 30px 0;
      }
      @media @phone {
        width: 100%;
        padding: 0;
      }
      .sol-shortcut {
        text-decoration: none;
        display: inline-flex;
        flex-basis: 43%;
        padding-bottom: 22px;
        margin-bottom: 27px;
        margin-left: 0;
        margin-right: 0;
        border-bottom: 1px solid rgba(235, 244, 247, 0.13);
        &:hover {
          .sol-shortcut__text {
            .sol-shortcut__text__title, .sol-shortcut__text__preamble {
              text-decoration: underline;
            }
          }
        }
        &:nth-child(odd) {
          margin-right: auto;
          @media @phone {
            border-right: 1px solid @pale-sea;
          }
        }
        @media @tablet-strict {
          flex-basis: 47%;
        }
        @media @phone {
          flex-basis: 50%;
          padding-top: 16px;
          padding-bottom: 16px;
          padding-left: 8.3%;
          margin: 0;
          align-items: center;
          justify-content: flex-start;
        }
        &__icon {
          flex-basis: 42px;
          display: inline-flex;
          align-items: flex-start;
          @media @phone {
            flex-basis: auto;
          }
        }
        svg {
          display: inline-flex;
          width: 36px !important;
          height: 33px !important;
          margin-right: 18px;
          @media @phone {
            width: 24px !important;
            height: 22px !important;
          }
        }
        &__text {
          display: flex;
          flex-direction: column;
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            flex-basis: 81.5%;
          }
          &__title, &__preamble {
            color: @white;
            transition: .2s all ease-in-out;
          }
          &__title {
            font-size: 17px;
            font-weight: bold;
            line-height: 1.41;
            text-align: left;
            color: #ffffff;
            @media @phone {
              font-size: 14px;
              font-weight: bold;
              line-height: 1.21;
              max-width: 70px;
            }
          }
          &__preamble {
            font-size: 14px;
            line-height: 1.39;
            text-align: left;
            color: #ffffff;
            @media @phone {
              display: none;
            }
          }
        }
      }
    }
  }
}
