.sol-table-decoration {
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-bottom: 55px !important;
  margin-bottom: 20px;

  form,
  &.sv-layout {
    border: 1px solid @pale-sea;

    > * {
      display: flex;
      align-items: center;
      width: 100%;

      &:nth-child(2n) {
        background-color: @pale-sea;
      }

      > * {
        width: 50%;
        padding-left: 20px;
        border-right: 1px solid @pale-sea;
        min-height: 50px;
        display: flex !important;
        flex-wrap: wrap;
        align-items: center;
      }
    }

    fieldset {
      display: block;
      width: 100%;
      padding: 0;
      border: none;

      > * {
        float: left;
      }
    }
  }

  &.sv-layout {
    padding-bottom: 0 !important;
  }

  p {
    margin-bottom: 0;
    font-size: 16px;
  }

  input[type="text"] {
    margin-right: 5px;
  }

  input[type="button"] {
    width: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: @downriver;
  }
}
