.btn {
  text-decoration: none;
  font-size: 17px;
}

.sol-btn {
  background-color: @ms-btn-bg;
  border: 2px solid @ms-btn-border-color;
  box-shadow: none;
  border-radius: 5px;
  padding: 7px 34px;
  color: @white;
  text-decoration: none !important;
  font-weight: 600;
  letter-spacing: 0.2px;
  text-align: center;
  transition: all .2s ease;
  &:hover {
    background-color: @ms-bnt-bg--hover;
    color: @ms-btn-text--hover;
    border: 2px solid @ms-btn-border-color--hover;
  }
}

