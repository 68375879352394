.sol-tool-nav {
  display: flex;
  align-items: center;
  float: right;
  position: relative;
  padding-right: 25%;
  height: 50px;
  justify-content: flex-end;
  @media (min-width: 992px) {
    min-width: 60%;
  }
  @media @tablet {
      margin: 0;
      z-index: 50;
      padding-right: 30px;
  }
  @media @tablet-strict {
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 45px;
  }

  @media @phone {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    vertical-align: top;
    float: none;
    margin-top: 0;
    text-align: center;
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    left: 0;
    height: 45px;

  }

  &-item {
    display: inline-block;
    float: left;
    @media @tablet-strict {
      margin-left: 30px;
    }
    @media @phone {
      float: none;
    }
    & > a,
    & > button {
      .focus-outline;
      .no-appearance;
      margin-left: 20px;
      margin-right: 5px;
      font-weight: 400;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: @header-top-text;
      cursor: pointer;
      text-decoration: none;
      font-family: @main-font;
      svg {
        margin-left: auto;
        margin-right: auto;
      }
      & i.fa {
        font-size: 15px;
        display: block;
        margin-top: 0;
        margin-right: 5px;
        margin-bottom: 6px;
      }
    }
    .sol-tool-nav-item__icon {
      .icon-size(20px, 26px);
      margin-bottom: 7px;
      @media @tablet {
        .icon-size(13.9px, 18px);
      }
    }
    .sol-tool-nav-item__text {
      @media @tablet {
        color: @white;
        font-size: 11px;
      }
      @media @phone {
        font-size: 10px;
      }
    }
    a, button {
      @media @tablet-strict {
        font-size: 10px;
        margin: 0!important;
        color: @white !important;
      }
      @media @phone {
        font-size: 10px;
        color: @white !important;
        margin: 0 !important;
        padding: 0;
      }
      > img {
        @media @tablet-strict {
          width: 15px;
          margin-bottom: 5px;
        }
      }
    }
    a {
      @media @tablet{
        line-height: 1;
      }
    }

    &.sol-to-content {
      background: @ms-color-1;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    & .sol-close {
      .focus-outline;
      .no-appearance;
      position: absolute;
      top: 0;
      right: 0;
      color: @header-top-text;
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      padding: 0;

      & i.fa {
        font-size: 11px;
      }
    }
    &__listen, &__lang, &__map, &__contact {
      svg {
          width: 26px;
          height: 20px;
          display: block;
          margin-bottom: 7px;
          @media @tablet {
            width: 16px;
            height: 16px;
            path {
              fill: @white;
            }
          }
      }
    }
    &__contact {
      @media @tablet-strict {
        margin-right: 15px;
      }
    }
    &__search {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      @media @tablet {
        display: none;
      }
      &__icon {
        background-color: @downriver;
        background-image: url('/webdav/files/System/resources/graphics/icons/Sok-vit.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 21.5px 21.5px;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        display: block;
      }
      &__text {
        font-weight: 600;
      }
      > #sol-main-search-btn {
        line-height: 1;
        display: flex;
        height: 65px;
        margin: 0;
        flex-direction: column;
        .sol-tool-nav-item__search__icon {
          margin-bottom: 5px;
        }
      }
      > .sol-top-search {
            padding-right: 0;
            overflow: visible;
            > .sol-top-search-field {
              width: 100%;
              margin-right: 25px;
              input[type=search] {
                width: 556px;
                border-radius: 6px;
                height: 35px;
                -webkit-appearance: none;
                &:hover {
                  -webkit-appearance: none;
                }
              }
              button[type=submit] {
                top: 50%;
                transform: translateY(-50%);
                right: 5px;
                height: 42px;
                width: 42px;
                .fa {
                    color: @search-top-button-text;
                }

              }
            }
        }
    }
  }

  &-box {
    position: absolute;
    width: 189px;
    margin-top: 10px;
    margin-left: -60px;
    padding: 17px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, .2);
    text-align: center;
    z-index: 1;
    cursor: default;
    @media @tablet-strict {
      margin-left: 0;
      right: 45px;
    }
    @media @phone {
      left: 50%;
      margin-left: -109px;
      width: 215px;
      padding: 12px;
    }

    & a.sol-btn-round {
      .transition(border);
      float: left;
      width: 30px;
      height: 30px;
      margin-right: 5px;
      margin-left: 5px;
      color: @header-top-box-share-icon;
      border: 2px solid @header-top-box-share-border;
      border-radius: 50%;
      font-size: 15px;
      padding-top: 1px;
      text-align: center;
      @media @phone {
        width: 40px;
        height: 40px;
        font-size: 21px;
        padding-top: 0;
        line-height: 37px;
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        border-color: @header-top-box-share-hover;
      }
    }

    &-translate {
      padding: 12px 17px 9px;
    }
  }
    #sol-main-search-btn {
      .sol-tool-nav-item__search__text {
        font-weight: 600;
        font-size: 14px;
        font-family: @main-font;
      }
      @media @phone {
        display: none;
      }
  }

}
// READSPEAKER

.sv-readspeaker_expandingplayer-portlet {
  position: relative;

  & > a {
    display: none;
  }

  & div[id^=readspeaker_player].rsexpanded {
    position: absolute;
    right: 0;
    height: auto;
    width: auto !important;
    padding: 17px 17px 22px;
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px 0;
    text-align: center;
  }
}

.sol-top-search {
    &.sol-active {
      padding-top: 21px !important;
    }
  .sol-top-search-field {
    label {
      font-size: 14px;
      font-weight: 600;
      text-align: left;
      color: #3b3b3b;
      position: absolute;
      right: 15px;
      bottom: -25px;
      line-height: 1;
    }
  }
}

.sol-uvk {
  .sol-tool-nav-item__search__text{
    font-weight: 600;
}
}
