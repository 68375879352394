.sol-puff {
  &-list {
    display: flex;
  }
  &--image, &--no-image {
    display: inline-flex;
    position: relative;
    flex-basis: 32%;
    width: 32%;
    height: 335px;
    background-position: 50% 50%;
    background-size: cover;
    margin-bottom: 30px;
    img {
      object-fit: cover;
      object-position: 50%;
      font-family: 'object-fit: cover; object-position: 50%;';
      height: 100%;
      min-width: 100%;
    }
    &:hover {
      .sol-puff__text-container {
        text-decoration: underline;
      }
    }
    &:nth-child(3n + 2) {
      margin-left: auto;
      margin-right: auto;
      @media @phone {
        margin-left: 0;
        margin-right: 0;
      }
    }
    @media @tablet-strict {
      height: 300px;
      flex-basis: 49%;
      width: 49%;
    }
    @media @phone {
      flex-basis: 100%;
      width: 100%;
      margin-bottom: 16px;
    }
  }
  &__text-container {
    width: 90%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 5.3%;
    padding-top: 18.5px;
    padding-left: 13.5px;
    padding-right: 13.5px;
    padding-bottom: 40px;
    min-height: 179px;
    background-color: fade(@pale-sea, 90%);
    .sol-top {
      .sol-puff__title {
        display: block;
        font-size: 18px;
        font-weight: bold;
        color: @black;
        margin-top: 0;
        margin-bottom: 10px;
      }
    }
    .sol-bottom {
      position: absolute;
      bottom: 0;
      left: 13.5px;
      right: 13.5px;
      height: 40px;
      border-top: 1px solid #a7a7a7;
      background-image: url('/webdav/files/System/resources/graphics/icons/Pil-h.svg');
      background-size: 16.5px 16.5px;
      background-repeat: no-repeat;
      background-position: 100% center;
    }
  }
  &--no-image {
    background-color: @pale-sea;
    .sol-puff__text-container {
      background-color: @white;
    }
  }
}
