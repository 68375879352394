//.sol-mini-site {
  .sol-overlay {
       display: none;
       position: absolute;
       left: 0;
       right: 0;
       top: 0;
       bottom: 0;
       background-color: #000;
       opacity: 0.6;
       &.sol-active {
         display: block;
       }
     }
@media @tablet {
  .slick-sider__buttons-container {
  }
  .sol-mobile-header {
    background-color: @white;
  /*  > div:first-child {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 75px;
      padding-right: 14px;
      > a {
        text-decoration: none;
      }
    }*/
    .sol-top-logo-text {
      text-decoration: none;
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
    }
    .sol-mobile-logo {
      max-width: 180px;
      height: auto;
      max-height: 31px;
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
    }
  }
}
//}
.sol-desktop-strict {
  @media @tablet {
    display: none !important;
  }
}
.sol-tablet-strict {
  display: none !important;
  @media @tablet-strict {
    display: block !important;
  }
}

.sol-phone-strict {
  display: none !important;
  @media @phone {
    display: block !important;
  }
}
