body, html {
    font-family: 'Source Sans Pro', sans-serif !important;
    -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   overflow-x: hidden;
   background-color: transparent;
}
body, html {
  overflow-x: hidden;
}
body, html {
  &.block_mode {
    @media @phone {
      position: relative !important;
      overflow: hidden !important;
      height: 100vh !important;
      /*.sol-header {
        position: fixed;
      }*/
      @media @phone { 
        height: 0 !important;
      }
      min-height: 0 !important
    }
  }
}
* {
  box-sizing: border-box;
}
.sol-body-overlay {
  @media @tablet-strict {
    position: fixed;
    background-color: #000;
    opacity: 0;
    z-index: 50;
    &.sol-active {
      opacity: 0.6;
      z-index: 50;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      & + div {
        .sol-mobile-header {
          border: none;
        }
      }
    }
  }
  @media @phone {
    height: 120px;
  }
}

/*.sol-body-padding-tablet {
  @media @tablet-strict {
    height: 130px;
  }
}*/



.sol-container {
  max-width: 1285px;
  margin-left: auto;
  margin-right: auto;
  @media @tablet {
    padding: 0;
  }
}

.sol-narrow-content {
  max-width: 702px;
  margin-left: auto;
  margin-right: auto;
}
.flex-row {
  display: flex;
}
#svid10_433d6ef615ca4bffb1a9e6 {
  z-index: 99;
}
