.sol-submenu {
  &.sol-menu-container {
     margin-top: 48px;
     overflow: visible;
    .sol-box-decoration-header {
      padding-left: 30px;
      padding-top: 11px;
      padding-bottom: 14px;
      line-height: 1;
    }
     & .sol-menu-list {
       width: 100%;
     }
     .sol-menu-root-name {
       background-color: @downriver;
       border-bottom: 1px solid #6e8a9e;
       font-size: 20px !important;
       transition: background-color .2s ease-in-out;
       &:hover {
         background-color: #06253c !important;
         font-size: 20px !important;
       }
     }
     & .sol-menu-item {
       position: relative;
         & > .sol-menu-item-link {
           border-bottom: 1px solid @submenu-item-border-color;
          }
       &[data-expanded="true"] {
        & > .sol-menu-button i.fa {
          transform: rotate(90deg);
        }
      }
      .sol-menu-item-link {
        text-decoration: none;
        display: block;
        padding-top: 11px;
        padding-bottom: 14px;
        transition: background-color .3s ease-in-out;
      }
      .sol-menu-item-display-name {
        font-size: 18px;
      }
       &[data-menu-level="0"] .sol-menu-item-link {
         .focus-outline;
         .sol-relative;
         display: block;
         padding: 8px 34px 8px 20px;
         color: @submenu-text;
         text-decoration: none;
         .transition(~'color, background-color, border');
       }
       &[data-menu-level="1"] > .sol-menu-item-link {
         padding-left: @submenu-lvl1-padding;
       }
       &[data-menu-level="2"] > .sol-menu-item-link {
         padding-left: @submenu-lvl2-padding;
       }
       &[data-menu-level="3"] > .sol-menu-item-link {
         padding-left: @submenu-lvl3-padding;
       }
       &[data-menu-level="4"] > .sol-menu-item-link {
         padding-left: @submenu-lvl4-padding;
       }
       &[data-expanded="true"][data-menu-level="0"] {
         & > .sol-menu-item-link,
         & > .sol-menu-item-link:hover {
           & i.fa{
             color: red;
           }
         }
         & > .sol-menu-list > ul {
           border-left: 2px solid @submenu-border--expanded;
         }
       }
       &[data-expanded="true"]:not([data-menu-level="0"]) {
         & > .sol-menu-item-link {
           & > .sol-menu-item-display-name {
             color: @submenu-text--expanded;
           }
         }
         &[data-menu-level="1"], &[data-menu-level="2"], &[data-menu-level="3"], &[data-menu-level="4"]{
           > i.fa {
             color: @submenu-icon-color;
           }
           & > .sol-menu-item-link {
             background-color: @submenu-bg--expanded--current;
           }
         }
         &[data-expanded="true"] {
           & > .sol-menu-item-link {
             & > .sol-menu-item-display-name {
               font-weight: bold;
               color: @white;
             }
           }
           & > .sol-menu-button {
             > i.fa {
               color: @submenu-icon-color--expanded;
             }
           }
           &[data-has-children="true"] {
             &[data-is-current="true"] {
               & > .sol-menu-item-link {
                 background-color: @submenu-bg--current;
               }
               & > .sol-menu-button {
                 > i.fa {
                   color: @submenu-icon-color--current;
                 }
               }
             }
             &[data-is-current="false"] {
               & > .sol-menu-item-link {
                 &:hover {
                   background-color: #0e3c5d;
                 }
               }
             }
           }
           &[data-has-children="false"] {
             &[data-is-current="true"] {
               & > .sol-menu-item-link {
                 background-color: @submenu-bg--current;
                 & > .sol-menu-item-display-name {
                   color: @black;
                 }
               }
               & > .sol-menu-button {
                 > i.fa {
                   color: @submenu-icon-color--current;
                 }
               }
             }
           }
         }
       }
       &[data-expanded="true"] {
         & > .sol-menu-item-link i.fa {
           color: @white;
           transform: rotate(90deg);
         }
       }

       &[data-expanded="true"][data-has-children="false"] > .sol-menu-item-link {
         & i.fa{
           color: @submenu-text--expanded;
         }
       }
       & .sol-menu-button {
         .no-appearance;
         .focus-outline;
         position: absolute;
         right: 0;
         width: 34px;
         height: 50px;
         text-align: right;
         font-size: 17px;
         top: 0;
         bottom: 1px;
         padding-right: 20px;
         cursor: pointer;
         & > i.fa {
           position: absolute;
           top: 50%;
           right: 25px;
           margin-top: -9px;
           .transition(all);
         }
       }
       &[data-node-type="sv:folder"] {
         & > .sol-menu-item-link > .sol-menu-button {
           width: 100%;
           left: 0;
         }
       }
     }

   }

}
