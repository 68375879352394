.sol-mini-site {
  .sv-grid-main {
    padding-left: 0;
    padding-right: 0;
  }
  .sol-subpage-wrapper {
    width: 52%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
    @media @tablet-strict {
      width: 80%;
    }
    @media @phone {
      width: 89%
    }
    /*padding-left: 25%;
    padding-right: 25%;
    @media @tablet {
      padding-left: 10.5%;
      padding-right: 10.5%;
    }
    @media @phone {
      padding-left: 5.3%;
      padding-right: 5.3%;
    }*/
    .pagecontent {
        margin-left: auto;
        margin-right: auto;
    }
  }
  .sol-related-info-container {
    background-color: @ms-color-2;
    padding: 30px 0;
    > div {
      width: 52%;
      margin-left: auto ;
      margin-right: auto;
      @media @tablet-strict {
        width: 80%;
      }
      @media @phone {
        width: 89%;
      }
    }
    .sol-related {
      border: 0;
      background-color: transparent;
    }
    .font-heading-4 {
      font-size: 22px;
      font-weight: bold;
      line-height: 1.45;
      text-align: left;
      color: #3b3b3b;
      margin-top: 0;
      margin-bottom: 28px;
      display: block;
    }
    .sol-related-item {
      background-color: @white;
      display: inline-flex;
      align-items: center;
      border-radius: 5px;
      padding: 7px 26px;
      margin-bottom: 5px;
      .fa {
        color: @gray-3;
        border-color: @gray-3;
      }

      &-name {
        font-size: 17px;
        font-weight: 600;
        text-align: left;
        color: #0e3c5d;
        margin-right: 24px;
      }
      &-size {
        display: none;
      }
    }
  }
  .sol-wide-image {
    margin-left: -99px;
    margin-right: -99px;
    max-width: 900px;
    @media (max-width: 900px) {
      max-width: none;
      width: 100vw;
      position: relative;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
    }
    img {
      max-width: none;
    }
    .sv-portlet-image-caption {
        @media (max-width: 900px) {
          padding-right: 15px;
        }

    }
  }



  .sol-bottom {
  }
.sol-menu-container {
  > .sol-sub-menu-container {
    > ul {
      display: flex;
      height: 70px;
      > li {
        > div {
          height: 100%;
          > a {
            height: 100%;
            align-items: center;
            padding-left: 15px;
            padding-right: 15px;
            > span {
              color: @white;
            }
          }
          > .sol-menu-button {
            > .fa {
              color: @white;
              transform: rotate(-90deg);
            }
          }
        }
      }
    }
  }
}
}
