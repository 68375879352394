.sol-mobile-menu-container, .sol-slide-menu-container {
  background-color: white;
  position: fixed;
  top: 120px;
  left: 0;
  right: 0;
  z-index: 49;
  overflow: hidden;
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, .43);
  @media @tablet-strict {
    position: absolute;
    top: 0;
  }
  @media @tablet {
    &.sol-active {
      visibility: visible;
      opacity: 1;
      overflow: auto;
    }
  }

  & > ul {
    border-top: 1px solid @mobile-menu-border;
    > li {
      &:last-child {
        > div  {
          > a {
            border-color: @downriver;
          }
          }
        }
    }
  }

  & .sol-menu-list {
    width: 100%;
  }

  & .sol-menu-item {
    position: relative;
    .sol-menu-button {
      max-height: 100%;
    }
    .sol-menu-item-link {
      color: @white;
    }
    .fa {
      color: @white;
    }
    .sol-menu-item-link {
      transition: background-color .2s ease-in-out;
    }
    &[data-menu-level="0"] {
      > .sol-menu-item-link {
        color: @white;
        &:hover {
          background-color: #06253c !important;
        }
      }
      > .sol-menu-button {
        > .fa {
          color: @white;
        }
      }
    }
    &:not([data-menu-level="0"]) {
      background-color: @white;
      & > .sol-menu-item-link {
        & > .sol-menu-item-display-name {
          font-size: 18px;
        }
      }
      & > .sol-menu-button {
        > .fa {
          color: @black;
        }
      }
    }
    &[data-expanded="false"]:not([data-menu-level="0"]) {
      & > .sol-menu-item-link {
        background-color: @white;
        & > .sol-menu-item-display-name {
          color: @black;
        }
      }
    }
    &[data-expanded="true"]:not([data-menu-level="0"]) {
      & > .sol-menu-item-link {
        background-color: @menu-item-bg--expanded;
        & > .sol-menu-item-display-name {
          color: @menu-item-text--expanded;
        }
      }
      & > .sol-menu-item-link {
        & > .sol-menu-item-display-name {
          font-weight: bold;
        }
      }
      &[data-has-children="true"] {
        & > .sol-menu-item-link {
          & > .sol-menu-item-display-name {
            color: @menu-item-text--expanded;
          }
        }
        & > .sol-menu-button {
          > i.fa {
            color: @submenu-text--expanded--current;
          }
        }
      }

      &[data-is-current="false"] {
        & > .sol-menu-item-link {
          &:hover {
            background-color: #0e3c5d;
          }
        }
      }
      &[data-is-current="true"] {
        & > .sol-menu-item-link {
          background-color: @menu-item-bg--expanded--current;
          & > .sol-menu-item-display-name {
            color: @menu-item-text--expanded--current;
          }
        }
        & > .sol-menu-button {
          > i.fa {
            color: @menu-item-text--expanded--current;
          }
        }
      }
    }

    &-link {
      .focus-outline;
      display: block;
      position: relative;
      padding: 10px 49px 10px 19px;
      border-bottom: 1px solid @mobile-menu-border;
      text-decoration: none;
      margin-left: 1px;
      margin-right: 1px;
      color: @mobile-menu-text;

      & .sol-menu-item-display-name {
        font-size: 14px;
        font-weight: 400;
      }
    }
    & .sol-menu-button {
      position: absolute;
      top: 0;
      height: 43px;
      right: 0;
      width: 50px;
      .no-appearance;
      .focus-outline;
      width: 50px;
      border-left: 1px solid @mobile-menu-border;

      & i {
        position: absolute;
        right: 16px;
        top: 50%;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        text-align: center;
        padding-top: 3px;
        padding-left: 3px;
        .transition(all);
      }
    }

    &[data-menu-level="1"] .sol-menu-item-link {
      padding-left: 30px;
    }

    &[data-menu-level="2"] .sol-menu-item-link {
      padding-left: 40px;
    }

    &[data-menu-level="3"] .sol-menu-item-link {
      padding-left: 50px;
    }

    &[data-menu-level="4"] .sol-menu-item-link {
      padding-left: 60px;
    }

    &[data-expanded="true"] > .sol-menu-button {
      & i.fa {
        .rotate(90deg);
      }
    }


    &[data-node-type="sv:folder"] {
      & > .sol-menu-button {
        width: 100%;
        border-left: none;
      }
    }
  }
}
