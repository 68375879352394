.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
  color: red;
  font-weight: 400;
  font-size: 14px;

}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  color: red;
  font-weight: 400;
  font-size: 14px;
}

.sol-comparator-page {
  &__cnt {
    //button {
    //  display: block !important;
    //  margin-left: auto;
    //}
    .sol-flex-row {
      display: flex;
      @media @tablet {
        flex-wrap: wrap;
      }
      .sol-kpi-checbox-cnt {
        flex-wrap: wrap;
        & + button {
          background-color: @downriver;
        }
      }
      > div {
        flex: 1 0 0;
        margin-bottom: 15px;
        @media @tablet {
          width: 100%;
          flex: 1 0 100%;
        }
        &:nth-child(3) {
          display: flex;
          flex-wrap: wrap;
          > button {
            margin-top: 15px;
          }
        }
      }
    }
    & input[type=checkbox],
    & input[type=radio] {
      display: none;
      & + label:before {
        content: "";
        box-sizing: border-box;
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 2px solid @form-input-chk-empty-border;
        border-radius: 3px;
        position: relative;
        margin-right: 10px;
        float: left;
        text-align: center;
        .transition(~  'color, background-color, border-color, border-width');
      }

      //& + label {
      //  display: block;
      //  font-size: 14px;
      //  line-height: 1.3em;
      //  font-weight: 300;
      //  margin-bottom: 10px;
      //  cursor: pointer;
      //}
    }

    & input[type=checkbox] {
      &:checked + label:before {
        .icon(@fa-var-check, 12px);
        padding-top: 2px;
        background-color: @form-input-chk-bg;
        border-color: @form-input-chk-border;
        color: @form-input-chk-text;
      }
    }

    .sol-unit__further-info {
      > div {
        display: flex;
      }
    }
    .sol-unit__description {
      // width: 60%;
      font-size: 16px;
      margin-bottom: 15px;
      width: 60%;
      margin-right: 6.5%;
    }
    .sol-expand-department-btn {
      color: #fff;
      &:hover {
        background-color: #0e3c5d;
      }
      &[aria-pressed="true"] {
        background-color: rgba(56, 142, 154, 0.1);
      }
    }

  }
  &__chosen-departments {
    &__list {
      padding-left: 0;
      border-radius: 5px;
    }
    &__list-item {
      padding: 10px;
    }
  }
  &__list {
    padding-left: 0;
    border-radius: 5px;
    max-width: 1250px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
  }
  &__list-item {
    padding: 10px;
    background-color: #fff;
  }
  ul {
    list-style: none;
  }
  .sol-unit {
    .sol-checkbox {
      margin-left: auto;
    }
    > .sol-flex-row:first-child {
      flex: 0 0 100%;
      margin-bottom: 10px;
    }
    .sol-kpi-checbox-cnt {
      flex-wrap: nowrap !important;
    }
    .sol-flex-row {
      > div:nth-child(3) {
        background-color: #ebf4f7;
        padding: 15px;
        display: flex;
        > div {
          flex: 1 0 100%;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
        }
      }
    }
    &__name {
      font-size: 20px;
      color: #ef7716;
    }
    .slide-enter-active {
      transition: 1s all ease-in-out;
    }
    .slide-leave-active {
      transition: .8s all ease-in-out;
    }
    .slide-enter {
      max-height: 0;
    }
    .slide-enter-to, .slide-leave {
      max-height: 1000px;
    }
    .slide-leave-to
      /* .slide-fade-leave-active below version 2.1.8 */ {
      max-height: 0;
    }
    display: flex;
    flex-wrap: wrap;
    &__further-info {
      flex-basis: 100% !important;
      overflow: hidden;
      > ul {
        margin-left: 7% !important;
      }
      &.expanded {
      }
    }
    &:nth-child(even) {
      background-color: #f4f4f4;
    }
    &__tag {
      &__list {
        display: flex;
        &-item {
          font-weight: bold;
        }
      }
    }
    &__tag-group {
      &__list {
        &-item {
          display: flex;
        }
      }
    }
    > div {
      flex: 1 1 0;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    header {
      background-color: #0e3c5d;
      padding: 10px;
    }
  }
  button {
    //.sol-btn;
    //marign-top: 0;
    //margin-bottom: 0;
    //margin-left: 15px;
    //background-color: #fff;
    //color: @downriver;
    //&:hover {
    //  background-color: #fff;
    //  color: @downriver;
    //}
  }
}

.sol-comparator-creator__form {

  & > div {
    margin-bottom: 22px;
    display: flex;
    flex-direction: column;
    & [class$="__error-message"] {
      color: red;
      font-weight: 400;
      font-size: 14px;
    }
    label {
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #3b3b3b;
      margin-bottom: 11px;
    }
    input {
      padding: 5px;
      border-radius: 2px;
      background-color: #ffffff;
      border: solid 1px #ececec;
      font-size: 15px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: @gray-3;
    }
  }
}

.stratsys-main-description, .stratsys-map-toggle-button, .stratsys-header > nav, .stratsys-accessibility {
  display: none;
}

.stratsys-cf {
  .stratsys-sub-section {
    display: none !important;
    &[data-students-quantity], &[data-drift-form], &[data-school-class] {
      display: flex !important;
    }
  }
  .stratsys-multi-select-item {
    position: relative;
    .stratsys-selected {
      &::before {
        display: block;
        content: '\f00c';
        font-family: fontAwesome;
        font-size: 16px;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
      }
    }
    &:active, &:focus {
      outline: 0;
    }
    color: @black;
    background-color: @white;
    border: none;
    border-bottom: 1px solid @downriver;
    width: 100%;
    padding: 10px;
    cursor: pointer;
    transition: all .2s ease-in-out;
    &[checked="checked"], &:hover {
      background-color: @downriver;
      color: @white;
      .stratsys-truncate {
        color: @white;
      }
    }
    > div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .stratsys-section {
    display: none;
    &[data-has-elis="true"] {
      display: flex;
    }
    .stratsys-button {
      .sol-btn;
      margin-top: 15px;
      margin-bottom: 15px;
    }
    > .stratsys-box {
      padding: 20px;
    }
    > .stratsys-department-data {
      padding: 20px;
    }
  }
  .stratsys-action-bar {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 30px;
    .stratsys-sort-header {
      > div {
        padding: 10px;
        border-bottom: 1px solid #ccc;
        > button {
          background-color: @white;
          cursor: pointer;
          margin-right: 15px;
          position: relative;
          padding: 9px;
          display: inline-block;
          line-height: 1;
          border: none;
          font-size: 13px;
          font-weight: bold;
          letter-spacing: 0.2px;
          text-align: center;
          text-decoration: none;
          color: #3b3b3b;
          border-radius: 5px;
          border: 2px solid #0e3c5d;
          &.stratsys-selected {
            background-color: @downriver;
            color: @white;
          }
        }
      }
    }
    > .stratsys-action-bar-item {
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
      flex-basis: 47%;
      width: 47%;
      position: relative;
      .stratsys-left {
        .stratsys-multi-select {
          > button {
            &::after {
              display: none;
            }
          }
        }
      }
      .stratsys-dropdown {
        position: absolute;
        background-color: @white;
        width: 100%;
        z-index: 3;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
      }
      .stratsys-dropdown-wrapper {
        > button {
          width: 100%;
          position: relative;
          cursor: pointer;
          transition-property: background-color;
          transition-duration: 200ms;
          transition-timing-function: ease;
          background-color: @white;
          &::after {
            display: block;
            content: "";
            width: 32px;
            height: 32px;
            border: 2px solid #6e8a9e;
            border-radius: 50%;
            background-color: #fff;
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%) rotate(-90deg);
            transition: all .2s ease-in-out;
            display: flex;
            justify-content: center;
            align-items: center;
            background-image: url('@{sol-graphics-path}/icons/chevron-down.svg');
            background-repeat: no-repeat;
            background-size: 20px 20px;
            background-position: center;
          }
        }
      }

    }
    .stratsys-button {
      height: 50px;
      padding-left: 20px;
      background-color: #fff;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      border: 0;
      border-left: 5px solid #6e8a9e;
    }
  }
  .stratsys-compare-button {
    display: none;
  }
  .stratsys-link-button {
    .sol-btn;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  table {
    width: auto;
    border: none;
    tbody td {
      border: none;
    }
    .stratsys-icon-col {
      display: none;
    }
    .stratsys-meta-info {

    }
  }
  .stratsys-tag-groups {
    width: 100%;
    .stratsys-sub-section {
      flex-basis: 50%;
    }
    .tags {
      display: flex;
    }
    .stratsys-tag {
      flex-grow: 1;
    }
  }
  .stratsys-section {
    flex-wrap: wrap;

    .stratsys-department-info {
      display: flex;
      align-items: flex-start;
      .stratsys-sub-section {
        display: flex;
        flex-direction: column;
        margin-left: 25px;
        padding-top: 10px;
        table {
          margin-bottom: 10px;
        }
      }
    }
    .stratsys-section-header {
      position: relative;
      padding: 15px;
      border-radius: 4px;
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: @white;
      border: none;
      border-left: 5px solid #6e8a9e;
      background-color: #fff;
      padding: 8px 18px 11px;
      width: 100%;
      text-align: left;
      position: relative;
      cursor: pointer;
      line-height: normal;
      min-height: 50px;
      overflow: visible;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
      transition-property: background-color;
      transition-duration: 200ms;
      transition-timing-function: ease;
      margin-bottom: 0;
      &::after {
        display: block;
        content: "";
        width: 32px;
        height: 32px;
        border: 2px solid #6e8a9e;
        border-radius: 50%;
        background-color: #fff;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%) rotate(-90deg);
        transition: all .2s ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url('@{sol-graphics-path}/icons/chevron-down.svg');
        background-repeat: no-repeat;
        background-size: 20px 20px;
        background-position: center;
      }
      .stratsys-title {
        margin: 0;
        font-size: 14px;
        font-weight: 600;
      }
      .sol-arrow {
        &::after {
          font-family: fontAwesome;
          content: "\f054";
          display: block;
          color: #0e3c5d;
          transition-property: all;
          transition-duration: 200ms;
          transition-timing-function: ease;
          transition: all .2s ease-in-out;
        }
      }
      .stratsys-button {
        display: none;
      }
    }
    .stratsys-box {
      flex-basis: 100%;

    }
    .stratsys-department-data {
      display: flex;
      flex-wrap: wrap;
      .stratsys-row-wrapper {
        display: flex;
        .stratsys-row {
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          .stratsys-sub-section {
            flex-basis: 50%;
            h3 {
              font-size: 16px;
            }
          }
        }
      }
    }

    // expanderad
    &.expanded {
      .stratsys-section-header {
        background-color: @downriver;
        .stratsys-title {
          color: @white;
        }
        &::after {
          transform: translateY(-50%) rotate(0);
        }
      }
    }
    &:not(.expanded) {
      .stratsys-box {
        display: none;
      }
      .stratsys-department-data {
        .stratsys-button:last-child {
          display: none;
        }
      }
    }
  }
}

// Advant
body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.sol-comparator__compare-btn {
  margin-bottom:40px;

}
.sol-comparator__list {
  padding-top: 15px;
}

.sol-comparator__list-item {
  padding:30px;
  margin-bottom: 15px;
}


.sol-comparator .sol-unit__name {
  font-size: 19px;
  color: #3b3b3b;
  font-weight:900;
  margin-bottom:10px
}

.sol-comparator
.sol-unit__address,
.sol-unit__phone-number,
.sol-unit__link-item {
  font-weight:300 !important;
}

.sol-comparator
.sol-unit__phone-number,
.sol-unit__link-list a {
  color:#0e3c5d;
}

.sol-comparator .sol-unit ul {
  font-weight: 300;
}

.sol-comparator .sol-unit__tag-group__name {
  font-weight:300;
}

.sol-comparator__cnt .sol-flex-row>div {
  flex: 2 0 0;
  margin-bottom: 0;
}
.sol-comparator__cnt .sol-flex-row>div:nth-child(3) {
  flex: 1 1 0;
}

.sol-comparator__cnt .sol-flex-row>div:nth-child(3) ul li {
  font-weight: 300 !important;
}

.sol-comparator__cnt .sol-unit__description {
  font-weight:300;
}

.sol-comparator__cnt input[type=radio]+label {
  margin-top:10px;
}

.sol-comparator__cnt .sol-flex-row>div:nth-child(3)>button {
  margin-top: 35px;
}

.sol-unit__further-info {
  margin-top:30px;
  padding-top:25px;
  border-top: 1px solid #ececec;
  background-
  p { 
    margin-top: 0 !important;
  }
}


.sol-comparator-page {
  background-color: #f8f9f6;
}
