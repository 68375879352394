.sol-create-new-event-page {
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .messageFade-enter-active, .messageFade-leave-active {
    transition: opacity .5s;
    transition-delay: 1s;
  }
  .messageFade-enter, .messageFade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    transition-delay: .7s;
  }
  .sol-thank-you-message, .sol-error-message {
    display: none;
  }

  & .input-group.input-group--selection-controls label {
    font-size: 17px;
    line-height: 20px;
    color: #0e3c5d;
  }
  & .input-group.input-group--selection-controls i.icon--selection-control {
    color: #204560;
    top: -2px;
  }

  & .application--wrap {
    & label {
      &:after {
        display: inline-block;
        content: "\002a";
        color: red;
        font-size: 20px;
        margin-left: 5px;
      }
    }
  }
}
.sv-edit-mode {
  .sol-thank-you-message, .sol-error-message {
    display: block;
  }
}
.sol-form-group {
  margin-bottom: 20px;
  label, legend {
    .input-label;
    &[required="required"] {
      &::after {
        display: inline-block;
        content: "\002a";
        color: red;
        font-size: 14px;

      }
    }
  }
  textarea {
    .input-textarea;
  }
  .sol-form-control {
    &[type="text"] {
      .input-text;
      padding-top: 10px;
    }
    &[type="radio"] {
      .input-radio;
      & + label {
        display: block;
        font-size: 14px;
        line-height: 20px;
        font-weight: 300;
        margin-bottom: 10px;
        cursor: pointer;
      }
    }
  }
}

.sol-new-event {
  &__ticket {
    &__link {
      margin-top: 20px;
    }
  }
}

.sol-new-event-form {
  margin: 40px 0;
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
    color: red;
  }
  & [class$="__error-message"] {
    color: red;
    font-weight: 400;
    width: 100%;
    display: block;
  }
  > div {
    .sol-form-group;
  }
  .sol-remove-date-time-btn {
    position: relative;
    display: inline-block;
    line-height: 22px;
    min-height: 40px;
    padding: 8px 25px;
    color: #313131;
    background-color: #c1e4c7;
    border-radius: 3px;
    transition-property: background-color,color;
    transition-duration: 200ms;
    transition-timing-function: ease;
    cursor: pointer;
    text-decoration: none;
    font-size: 14px;
    font-family: PFDINTextStd-Regular,sans-serif;
    border: 0;
    height: 40px;
    margin-left: 1%;
    .fa {
      margin-left: auto;
      margin-right: auto;
    }
  }
  .sol-add-new-date-time {
    position: relative;
    display: inline-block;
    line-height: 22px;
    padding: 8px 25px;
    color: #313131;
    background-color: #c1e4c7;
    border-radius: 3px;
    transition-property: background-color,color;
    transition-duration: 200ms;
    transition-timing-function: ease;
    cursor: pointer;
    text-decoration: none;
    font-size: 14px;
    font-family: PFDINTextStd-Regular,sans-serif;
    border: 0;
    margin-top: 17px;
    min-height: 35px!important;
  }
  button[type="submit"] {
    position: relative;
    display: inline-block;
    line-height: 22px;
    min-height: 40px;
    padding: 8px 25px;
    color: white;
    background-color: #0e3c5d;
    border-radius: 3px;
    transition-property: background-color, color;
    transition-duration: 200ms;
    transition-timing-function: ease;
    cursor: pointer;
    text-decoration: none;
    font-size: 14px;
    font-family: PFDINTextStd-Regular, sans-serif;
    border: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    min-width: 110px;
  }
  .sol-form-group__error-msg {
    color: red;
  }
  .application--wrap {
    margin-left: 28px;
    min-height: unset;
    padding: 0;

    @media (max-width: 480px) {
      margin-left: 0;
    }
  }
  .application.theme--light {
    background: none;
  }
  .sol-fieldset {
    border: 0;
    padding: 0;
    margin: 0;
    min-width: 0;
    .flex-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .sol-form-group {
        flex: 1 0 31%;
        max-width: 31%;

        @media (max-width: 480px) {
          flex: 1 0 100%;
          max-width: 100%;
        }
        &.full-width {
          flex: 1 0 100%;
          max-width: 100%;
        }
      }
    }
    legend {
      font-family: PFDINTextStd-Regular,sans-serif;
      color: #204560;
      margin-top: 30px;
      margin-bottom: 5px;
      font-size: 24px;
      line-height: 1.38;
    }

  }
  .sol-form-group {
    margin-bottom: 20px;
    label, legend {
      .input-label;
      &[required="required"] {
        &::after {
          display: inline-block;
          content: "\002a";
          color: red;
          vertical-align: text-top;
          font-size: 20px;
          margin-left: 5px;

        }
      }
    }
    textarea {
      .input-textarea;
    }
    .sol-form-control {
      &[type="text"] {
        .input-text;
        border-color: lightgray;
        background-color: #f8f9f6;
        padding-top: 10px;
      }
      &[type="radio"] {
        .input-radio;
        & + label {
          display: block;
          font-size: 14px;
          line-height: 20px;
          font-weight: 300;
          margin-bottom: 10px;
          cursor: pointer;
        }
      }
      &__cnt {
        display: inline-block;
        margin-right: 50px;

        & label {
          &::before {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
.sol-new-event__date-time {
  margin-top: 40px;
  & table {
    width: auto;

    & th {
      background-color: #efefef;
    }
  }
}
.sol-new-event-form>div .sol-form-control[type="text"],
.sol-new-event-form > div textarea {
  border-color: fadeout(lightgray,50%);
  background-color: #f8f9f6;
  &:hover, &:focus, &:active {
    border-color: fadeout(lightgray,50%);
    background-color: #f8f9f6;
  }
}
.sol-new-event-form .sol-fieldset legend {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 14px;
}
.sol-new-event-form .sol-add-new-date-time {
  background-color: #204560;
  color: white;
}
.sol-new-event-form .sol-remove-date-time-btn {
  background-color: #204560;
  color: white;
}
.sol-new-event-form>div.consent-label label {
  font-size: 17px; 
  white-space: normal;
  height: auto;
  max-width: 670px;
  white-space: normal;
  font-size: 17px;
  color: #0e3c5d;

  &:after {
    color: red;
    font-size: 20px;
    margin-left: 10px;
  }
}
.sol-new-event-form>div.consent-label {
  border-top: 1px solid #204560;
  margin-top: 20px;
  padding: 30px 0;

  & .input-group__details {
    display: none;
  }

  & i.icon--selection-control {
    color: #204560;
    top: -1px;
  }
}
.sol-new-event-form .consent-info {
  margin-bottom: 30px;
  font-size: 15px;
  color:  #204560;
}
.consent-error-message {
  display: block;
  margin-top: -35px;
  margin-bottom: 30px;
  color: red;
}
