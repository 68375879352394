.error-list {
  list-style-type: none;
  .error-item {
    &::before {
      display: inline-block;
      width: 30px;
      height: 30px;
      font-family: FontAwesome;
      content: "\f06a";
      color: #ff0033;
    }
  }
}
.error-stick {
  width: 200px;
  height: 50px;
  background-color: @white;
  color: red;
  position: fixed;
}
