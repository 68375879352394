  .sol-article-item {
  position: relative;
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  align-items: center;
  padding-left: 222px;
  min-height: 164px;
  text-decoration: none;
  border-bottom: 1px solid #ececec;
  @media @phone {
    padding-left: 0;
  }
  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 130px;
    margin-right: auto;
    background-size: cover;
    background-position: 50% 50%;
    @media @phone {
      position: static;
      width: 100px;
      height: 100px;
    }

    img {
      min-width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50%;
      font-family: 'object-fit: cover; object-position: 50%';
    }
  }
  &__date {
    font-size: 18px;
    font-weight: bold;
    font-size: 18px;
    font-weight: bold;
    > span {
       font-size: 14px;
       font-weight: 600;
       line-height: 0.91;
       text-align: left;
       text-transform: lowercase;
       color: @gray-3;
    }
    span {
      color: @gray-3;
      margin-right: 5px;
    }
  }
  &__content {
    .sol-article-item__heading {
      display: block;
      margin-top: 0;
      margin-bottom: 6.2px;
      font-size: 18px;
      font-weight: bold;
      text-align: left;
      color: #3b3b3b;
    }
    .sol-article-item__desc {
      display: block;
      font-size: 18px;
      line-height: 1.33;
      text-align: left;
      color: #3b3b3b;
    }
  }
  .sol-article-item-img {
    position: absolute;
    left: 0;
    width: 200px;
    height: 130px;
    margin-right: auto;
    background-size: cover;
    background-position: 50% 50%;
    @media @phone {
      position: static;
      width: 100px;
      height: 100px;
    }
  }
  .sol-article-text-content {
    .sol-article {
      margin-bottom: 15px;
    }

    @media @phone  {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &.sol-no-img {
    padding-left: 0;
  }
}
.sol-flex-news {
  @media @phone {
    & .sol-article-item {
      display: flex;
      flex-wrap: wrap;
      &__img {
        flex: 1 0 100%;
        width: auto;
        height: 200px;
      }
      &__content {
        flex: 1 0 100%;
      }
    }
  }
}
