.sol-subpage-wrapper,
.sol-article-wrapper {
  @media @tablet-strict {
    padding-top: 38px;
  }
  @media @phone {
    padding-top: 20px
  };
  & .sv-image-portlet {
    margin-bottom: 20px;
    .sv-portlet-image-caption {
      margin-top: 0;
      padding: 8px 5px;
      background-color: @img-text-bg;
      color: @img-text;
      font-size: 12px;
      line-height: 15px;
      text-align: right;
      font-family: @main-font;
      font-weight: 300;
    }
  }
  /*& .sv-linkicon {
    display: none;
  }*/
}
