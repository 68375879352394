.sol-box-decoration {
  .sol-clearfix;
  border-radius: 4px;
  background-color: @box-decoration-bg;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1);
  margin-bottom: 20px;

  &-header {
    display: block;
    padding: 12px 15px;
    //background-color: @box-decoration-header-bg;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: @box-decoration-header-text;
    text-align: left;
    text-decoration: none;
    line-height: 13px;
    font-weight: 700;
    font-size: 14px;
    margin: 0;

    a & {
      .focus-outline;
    }
  }

  .sv-text-portlet.sol-btn-small .font-btn {
    margin-bottom: 5px;
  }
}

.sol-divider-decoration {
  margin-top: 30px;
  margin-bottom: 30px;
  border-top: 1px solid @divider-decoration;
}

.sol-simple-box-decoration {
  margin-top: 25px;
  padding: 8px 20px 2px 15px;
  background-color: @simple-box-decoration-bg;

  &.sol-simple-box-decoration-alt {
    background-color: @simple-box-alt-bg;
  }

  & > div {
    display: block;
    margin-bottom: 5px;
  }
}
