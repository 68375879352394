.sol-mini-site {
  .sol-startpage-wrapper {
    .sol-homepage-map__cnt {
      height: 400px;
      iframe {
        height: 400px;
        width: 100%;
      }
    }
    .sol-container {
      &--beige {
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 60px;
        padding-bottom: 60px;
        @media @tablet {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
    .sol-dualbox {
      width: 100%;
      max-width: 1285px;
      padding: 0;
      margin-left: auto;
      margin-right: auto;
      .font-heading-2 {
        font-size: 31px;
        font-weight: 300;
        text-align: left;
        color: #3b3b3b;
        margin-top: 0;
      }
      @media @tablet {
        margin-top: 0;
        .sol-startsida-news {
          -webkit-order: 2;
          order: 2;
        }
        .sol-blue-box {
          -webkit-order: 1;
          order: 1;
        }
      }
      @media @phone {
        margin-bottom: 0;
      }
    }
    .sol-puffar-container {
      padding-top: 45px;
      padding-bottom: 45px;
      background-color: @beige;
      //max-width: 1440px;
      margin-left: auto;
      margin-right: auto;
    }

    .sol-puffar-section {
      max-width: 1345px;
      margin-left: auto;
      margin-right: auto;
      #BildTextpuff {
        display: none;
      }
      @media @tablet {
        justify-content: center;
        padding-left: 20px;
        padding-right: 20px;
      }
      @media @phone {
        margin-top: 0;
        margin-bottom: 0;
      }
      display: flex;
      h3 {
        margin : 0 !important;
      }
      > .sv-portlet {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        @media @phone {
          flex-wrap: wrap;
        }
        .sol-text-image-puff {
          .sol-text-image-puff__text__linkoverlay {
            z-index: 3;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            text-indent: -9999px;
            white-space: nowrap;
            overflow: hidden;
          }
          margin-bottom: 1rem;
          &:nth-child(3n + 3) {
            margin-left: 5%;
            margin-right: 5%;
            @media @tablet-strict {
              margin-left: 2%;
              margin-right: 2%;
            }
            @media @phone {
              margin-left: 0;
              margin-right: 0;
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
          &--image, &--noimage {
            position: relative;
            display: flex;
            align-items: flex-end;
            flex-basis: 30%;
            max-width: 30%;
            height: 335px;
            flex-grow: 0;
            @media @tablet {
              flex-basis: 32%;
              max-width: 32%;
              height: 300px;
            }
            @media @phone {
              flex-basis: 100%;
              max-width: 100%;
              margin-bottom: 14px;
              margin-right: 0;
            }
            padding: 15px;
          }

          &__text {
            padding-left: 14px;
            padding-right: 14px;
            padding-top: 18px;
            width: 100%;
            min-height: 5px;
            background: fade(@ms-color-2, 90%);
            z-index: 2;
            display: flex;
            flex-direction: column;
            p {
              margin: 0;
              padding-bottom: 38px;
              @media @tablet {
                padding-bottom: 24px;
              }
              border-bottom:  1px solid rgba(51, 51, 51, 0.3);
              font-size: 18px;
              line-height: 1.33;
              text-align: left;
              color: @ms-text-color;
            }
            &__link {
              align-self: flex-end;
              width: 21.5px;
              height: 20.9px;
              background-image: url('/webdav/files/System/resources/graphics/icons/Pil-h.svg');
              margin-top: 10px;
              margin-bottom: 10px;
            }
          }
            &--image {
              position: relative;
              &__image {
                display: block;
                height: 100%;
                background-size: cover;
                background-position: 50% 50%;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
              }
            }
            &--noimage {
              background-color: @ms-color-2;
              padding-top: 91px;
              padding-bottom: 19px;
              &__text {
                background-color: @white;
                p {
                  margin-top: 10px;
                }
              }
            }
        }
      }
      @media @phone {
        padding-left: 0;
        padding-right: 0;
        .sol-text-image-puff--image{
          padding: 0;
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
    .sol-dualbox-container {
      > .sol-puffar-section {
          margin-top: 45px;
          @media @tablet {
            margin: 0;
          }
          @media @tablet-strict {
            padding-top: 45px;
            padding-bottom: 45px;
          }
        }
        & + .sol-puffar-container {
          @media @phone {
            padding-top: 0;
          }
        }
    }

    .sol-startsida-quotes {
      .sol-quotes__icon {
        margin-bottom: 23px;
      }
      .sol-quotes__text {
        margin-left: auto;
        margin-right: auto;
        padding: 44px 269px;
        background: #fff;
        width: 100%;
        max-width: 1440px;
        min-height: 256px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .sv-grid-main {
          margin-left: inherit;
          margin-right: inherit;
        }
      }

      .sv-portlet {
        width: 100%;
        text-align: center;
      }
      p {
        text-align: center;
      }
      @media @tablet {
        .sol-quotes__text {
          padding: 35px 81px;
        }
      }
      @media @phone {
        .sol-quotes__text {
            padding-left: 28px;
            padding-right: 28px;
        }
        .font-normal {
          font-size: 18px;
          font-weight: 300;
          line-height: 1.3;
          text-align: center;
          color: @black;
        }
      }
    }
    .sol-carousel-text-wrapper {
      position: relative;

      &--adjacent, &--stacking, &--adjacent--links {
        .slick-prev {
          margin-right: 5px;
          width: 25px;
          height: 25px;
          @media @tablet-strict {
            width: 30px;
            height: 30px;
          }
          &:before {
          content: "";
          background-image: url('/webdav/files/System/resources/graphics/icons/Bildspels-pil-v.svg');
          background-repeat: no-repeat;
          width: 25px;
          height: 22px;
          display: block;
          @media @tablet-strict {
            width: 100%;
            height: 100%;
            background-size: 30px 30px;
          }
        }

      }
      .slick-next {
        width: 25px;
        height: 22px;
        @media @tablet-strict {
          width:  30px;
          height: 30px;
          left:   50px;
        }
        &:before {
        content: "";
        background-image: url('/webdav/files/System/resources/graphics/icons/Bildspels-pil-h.svg');
        background-repeat: no-repeat;
        width: 25px;
        height: 25px;
        display: block;
        @media @tablet-strict {
          width: 100%;
          height: 100%;
          background-size: 30px 30px;
        }
      }

    }
        .slick-sider__buttons-container {
          display: flex;
          align-items: center;
          background: #fff;
          position: absolute;
          bottom: 0;
          height: 36px;
          width: 135px;
          @media @tablet-strict {
            width: 175px;
            height: 50px;
          }
          @media @phone {
            width: 135px;
          }
          z-index: 2;
          padding-left: 10px;
          > button {
            &.slick-prev {
              left: 10px;
            }
            &.slick-next {
              left: 40px;
              @media @tablet-strict {
                left: 50px;
              }
            }
            @media @tablet-strict {
              width: 30px;
              height: 30px;
            }
            &:after {
              display: none;
            }
          }
          .slick-count-watcher {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 17px;
            font-size: 12px;
            @media @tablet-strict {
              font-size: 15px;
            }
            @media @phone {
              font-size: 14px;
            }
            span {
              font-weight: 300;
              font-size: 12px;
              @media @tablet-strict {
                font-size: 15px;
              }
              font-weight: 300;
              letter-spacing: -0.1px;
              text-align: left;
              color: #3b3b3b;
              &.sol-current-slide-num {
                margin-left: 5px;
              }
            }
          }
        }
      }
        &--stacking {
          .slick-sider__buttons-container {
            right: 0;
            border-top-left-radius: 22px;
            @media @phone {
              left: 0;
              border-top-right-radius: 22px;
              border-top-left-radius: 0;
            }
          }
          position: relative;
          .sv-font-topp-text-puff-header {
            margin-bottom: 15px;
          }
        .slick-slide {
          display: block;
          height: 410px;
          @media @tablet-strict {
            height: 350px;
          }
          @media @phone {
            height: 240px;
          }
          background-size: cover;
          background-position: 50% 50%;
        }
        &__textoverlay {
          min-height: 120px;
          background: fade(@ms-color-1, 95%);
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          min-width: 400px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          @media @phone {
            min-height: 80px;
            padding-top: 12px;
            padding-bottom: 12px;
            height: 80px;
            min-width: inherit;
            width: 100%;
          }
        }
      }

      &--adjacent, &--adjacent--links {
        position: relative;
        .slick-slide {
          display: block;
          height: 410px;
          @media @tablet-strict{
            height: 410px;
          }
          @media @phone {
            height: 240px;
          }
          background-size: cover;
          background-position: 50% 50%;
        }
        &__carousel {
          width: 100%;
          .slick-sider__buttons-container {
            left: 0;
            border-top-right-radius: 22px;
            @media @tablet-strict {
              width: 175px;
              height: 50px;
              position: absolute;
              left: 0;
              bottom: 120px;
            }
          }
        }
        &__textoverlay {
          background: fade(@ms-color-1, 95%);
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          min-width: 480px;
          .slick-sider__buttons-container {

          }
          .sol-link-tablet {
            &__icon {
               border: 2px solid #E2E7EB;
            }
            > a {
              position: relative;
              &:after {
                font-family: FontAwesome;
                content: @fa-var-external-link;
                display: block;
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                color: @white;
                font-size: 12px;
              }
            }
            .fa {
              color: #7C95A7;
            }
          }
          @media @tablet{
            left: 0;
            top: auto;
            height: 120px;
          }
          @media @phone {
            padding-top: 12px;
            height: 80px;
            min-width: inherit;
            width: 100%;
            .sol-link-tablet {
              margin-bottom: 0;
            }
            .sv-custom-module:last-child {
              margin-bottom: 0;
            }
          }
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          > div {
            margin-bottom: 12px;
          }
          .sol-link-tablet {
            max-width: 330px;
          }
        }

      }

      }

      .sol-startsida-news {
        margin-left: auto;
        margin-right: auto;
        max-width: 1285px;
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.03);
        .sol-article-item {
          &:hover {
            .sol-article-item__content {
              text-decoration: underline;
            }
          }
        }
        @media @tablet-strict {
          padding-left: 58px;
          max-width: 100%;
          box-shadow: none;
          margin: 0;
        }
        @media @phone {
          margin: 0;
          box-shadow: none;
          max-width: 100%;
          padding-left: 28px;
          padding-right: 28px;
          order: 2;
          .sol-startsida-news__header {
            padding-left: 0;
            @media @phone {
              font-size: 26px;
            }
          }
          .sol-article-item {
            padding-left: 0;
          }
        }
        .sol-article-date {
          span {
            font-size: 14px;
           font-weight: bold;
           text-align: left;
           color: @gray-3;
          }
        }
      }


  }

  &--school {
    .sol-carousel-text-wrapper--adjacent__textoverlay {
      background-color: @ms-color-2 !important;
      .sol-link-tablet {
        width: auto !important;
        min-width: 300px !important;
      }
    }
  }

  .sol-carousel-text-wrapper--adjacent--links {
    @media @tablet-strict {
      .slick-slide {
        height: 350px;
      }
    }
    .sol-carousel-text-wrapper--adjacent__textoverlay {
      background-color: fade(@ms-color-2, 50%) !important;
      @media @tablet-strict {
        position: static;
        height: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 25px 80px;
        justify-content: flex-start !important;
        > .sv-custom-module {
          display: flex;
          justify-content: center;
          flex-basis: 49%;
          flex-grow: 0;
          .sol-link-tablet {
            max-width: none;
            width: 275px;
          }
        }
      }
      @media @phone {
        position: static;
        height: auto;
        padding-top: 15px;
        padding-bottom: 15px,
      }
    }
  .sol-link-tablet {
    @media @tablet-strict {
      margin-bottom: 0;
    }
    @media @phone {
      flex-basis: 100%;
    }
    a {
      display: flex;
    }
  }
  }

}
