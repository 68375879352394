.sol-form--email form {
  & input[type=email],
  & input[type=number],
  & input[type=password],
  & input[type=tel],
  & input[type=text],
  & input[type=url],
  select,
  textarea {
    &:invalid {
      //background-color: @invalid-field-bg;
      //border-color: @invalid-field-border;
      background-image: url(/webdav/files/System/resources/graphics/icons/Stang.svg)
    }
    &:required:valid {
      //border-color: @valid-required-field-border-color;
      //background-color: @valid-required-field-bg;
      background-image: url(/webdav/files/System/resources/graphics/icons/check_valid.svg)
    }
  }
  textarea {
    height: 150px;
    overflow-y: auto;
  }
  > div {
  }
  .flex-row {
    justify-content: space-between;
    align-items: center;
    @media @phone {
      flex-wrap: wrap;
      > div {
        width: 100%;
      }
    }
  }
  width: 100%;
  max-width: 750px;
  padding: 25px 28px 50px;
  background-color: @white;
  border: 1px solid  #ececec;
  margin-left: auto;
  margin-right: auto;
  .sv-formFieldRow {
    margin: 15px 0;
    .sv-formField {
      float: none;
    }
  }

}

input[type=checkbox] {
  &:checked + label:before {
    color: @ms-btn-bg !important;
    background-color: @white !important;
  }
}
.sv-defaultFormTheme {
  display: block;
  }
