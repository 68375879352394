.sol-article-wrapper {
  margin-left: auto;
  margin-right: auto;
  width: 52%;
  &.sol-bottom {
    padding-top: 25px;
    padding-bottom: 44px;
  }
  .sol-publish-info {
    padding: 0;
    margin-bottom: 0;
  }
  @media @tablet {
    width: 80%;
    padding-top: 30px;
  }
  @media @phone {
    padding-top: 25px;
    width: 89%;
  }
  .sol-btn {
    @media @phone {
      display: flex;
      justify-content: center;
    }
  }
  .sol-article-date {
    > span {
      font-size: 14px;
      font-weight: 600;
      line-height: 0.79;
      letter-spacing: 0.3px;
      text-align: left;
      color: @gray-3;
    }
  }
  .sv-image-portlet {
    position: relative;
    overflow: hidden;
    .sol-article-date {
      position: absolute;
      left: -45px;
      top: -45px;
      width: 100px;
      height: 100px;
      padding-top: 47px;
      padding-left: 31px;
      border-radius: 100%;
      background-color: @news-item-date-bg;
      color: @news-item-date-color;
      text-align: center;
      @media @phone {
        left: -40px;
        top: -40px;
        width: 90px;
        height: 90px;
        padding-top: 40px;
      }
      &-day {
        display: block;
        font-size: 16px;
        line-height: 11px;
        font-weight: 700;
        @media @phone {
          font-size: 14px;
          line-height: 7px;
        }
      }
      &-month {
        font-size: 12px;
        line-height: 22px;
        display: block;
      }
    }
  }
  & .pagecontent {
    .sol-clearfix;
  }
  .sol-contact-rating-cnt {
    margin-top: 25px;
  }
}

.sol-news-menu {
  .sol-menu-item[data-expanded="false"] {
    .sol-menu-list {
      display: none;
    }
  }
}
