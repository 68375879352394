
.no-scroll {
  .sol-cookie-message {
    z-index: -1;
  }
}
.sol-cookie-message {
  .sol-clearfix;
  justify-content: center;
  align-items: center;
  position: relative;
  right: 0;
  left: 0;
  top: 0;
  z-index: 99;
  border-top-left-radius: 3px;
  background-color: @cookie-bg;
  color: @cookie-text;
  padding: 15px 0;
  text-align: center;
  font-size: 13px;
  @media @tablet {
    padding-left: 15px;
    padding-right: 53px;
    text-align: left;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  @media @tablet-strict {
    padding-top: 7px;
    padding-bottom: 6px;
    height: 47px;
    > span {
      line-height: 1.2;
    }
  }
  @media @phone {
    height: 97px;
    top: 0;
    span {
      line-height: 1.3;
    }
  }
  & a {
    color: @cookie-text;
    text-decoration: none;
    color: @downriver;
    &:hover{
      text-decoration: underline;
    }
  }

  & .sol-cookie-close {
    .focus-outline;
    .no-appearance;
    display: inline-block;
    border-radius: 2px;
    background-color: @cookie-close-bg;
    font-size: 10px;
    line-height: 20px;
    font-weight: 600;
    cursor: pointer;
    margin-left: 11px;
    padding: 0;
    width: 20px;
    height:20px;
    @media @tablet {
      position: absolute;
      top: 14px;
      right: 14px;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.sol-editor-bottom {
  bottom: 60px !important;
}
