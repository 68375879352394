.sol-popular-pages {
  background-color: @pale-sea;
  padding: 30px 2.4% 20px;
  @media @phone {
    padding-left: 6%;
    padding-right: 6%;
  }
  > div {
    width: 100%;
  }
  &__list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    h2.sol-popular-pages__list__title {
      flex-basis: 100%;
      text-align: center;
      font-size: 31px;
      color: @downriver !important;
      font-weight: 400;
      margin-top: 0;
      margin-bottom: 30px;
      line-height: 22px;
      @media @tablet-strict {
        font-size: 27px;
      }
      @media @phone {
        font-size: 26px;
      }
    }
  }
  &__page-btn {
    .sol-btn();
    padding: 10px 5.6%;
    flex-basis: 46%;
    max-width:46%;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 700;
    text-transform: inherit;
    &:nth-of-type(odd) {
      margin-right: auto;
      @media @tablet-strict {
        margin-right: 0;
      }
    }
    &:nth-of-type(3n+2) {
    @media @tablet-strict {
      margin-left: 5%;
      margin-right: 5%;
    }
    }
    @media @tablet-strict {
      flex-basis: 30%;
    }
      margin-bottom: 21px;
  }
}
