.sol-breadcrumbs-wrapper {
  .sol-hide-phone;
  background-color: @breadcrumbs-bg;
  padding-top: 15px;
  padding-bottom: 15px;
  @media @phone {
    display: none;
  }
  .sol-breadcrumbs {
    padding-left: 4.8%;
    padding-right: 4.1%;
    .sol-clearfix;
    & li {
      float: left;
      & > a,
      & > span {
        font-size: 15px;
        text-align: center;
        font-weight: normal;
      }
      & > a {
        text-decoration: none;
        color: @breadcrumbs-text;
        &:hover {
          color: @breadcrumbs-text-hover;
          text-decoration: underline;
        }
      }
      & > span {
        color: @breadcrumbs-text-active;
        &.sol-divider {
          margin-right: 8px;
          margin-left: 12px;
        }
      }
      &:first-child {
        > a {
          border-radius: 5px;
          padding: 5px;
          padding-right: 12px;
          padding-left: 12px;
          background-color: @white;
        }
      }
    }
  }
}
