
.sol-crisis-container {
  // Röd variant
  @crisis-bg: #b61b42;
  @crisis-text: @white;
  @crisis-btn-bg: transparent;
  @crisis-btn-bg-hover: rgba(255, 255, 255, 0.16);
  @crisis-btn-border: @white;

  // Gul varianten
  @crisis-bg--alt: #eeb952;
  @crisis-text--alt: #3b3b3b;
  position: relative;
  background-color: @crisis-bg;
  color: @crisis-text;
  .transition(margin-top, 150ms, linear);
  &.yellow {
    background-color: @crisis-bg--alt !important;
    color: @crisis-text--alt !important;
    .sol-crisis-message {
      &__title {
        color: @crisis-text--alt;
        &::after {
          background-color: @crisis-text--alt;
        }
      }
      &__desc {
        color: #3b3b3b;
      }
      &__more  {
        color: @crisis-text--alt;
        border-color: @crisis-text--alt;
      }
      &__expanding-btn {
        .sol-icon--chevron--upp {
          &::after {
            background-image: url('/webdav/files/System/resources/graphics/icons/arrow--up--black.svg');
          }
        }
        &__text {
          color: #3b3b3b;
        }
      }
    }
  }
  .sol-icon {
    margin-right: 4.2%;
    @media @tablet-strict {
      margin-right: 2.6%;
    }
    &--warning , &--warning--black{
      .icon-size(53px, 60px);
      @media @phone {
        .icon-size(45px, 40px);
      }
    }
  }
  &__content {
    position: relative;
    padding: 32px 1.8% 40px 20%;
    display: flex;
    @media @tablet-strict {
      padding: 35px 3.8% 34px;
    }
    @media @phone {
      padding: 51px 20px 25px 20px;
    }
  }
  & .sv-row {
    position: relative;
    display: inline-block;
    @media @tablet {
      padding: 20px 20px 20px 115px;
    }
    @media @phone {
      padding: 20px 20px 20px 60px;
    }
  }

  & i.fa-warning {
    position: absolute;
    left: 10px;
    top: 50%;
    margin-top: -55px;
    font-size: 100px;
    line-height: 110px;
    @media @tablet {
      left: 30px;
      top: 20px;
      margin-top: 0;
      font-size: 45px;
      line-height: 50px;
    }
    @media @phone {
      left: 20px;
      font-size: 25px;
      line-height: 30px;
    }
  }

  .sol-crisis-message {
    @media @tablet-strict {
      display: flex;
      flex-direction: column;
    }
    &__title {
      display: inline-table;
      margin-bottom: 10px;
      color: @crisis-text;
      text-decoration: none;
      font-size: 30px;
      font-weight: bold;
      letter-spacing: -0.1px;
      position: relative;
      margin-right: 38px;
      &::after {
        display: block;
        content: '';
        width: 100%;
        height: 3px;
        background-color: #ffffff;
        position: absolute;
        bottom: -6px;
        left: 0;
      }
      @media @tablet-strict {
        font-size: 25px;
        margin-top: 14px;
      }
      @media @phone {
        font-size: 20px;
        margin-top: 7px;
      }
    }

    &__desc {
      display: none;
      color: @crisis-text;
      line-height: 1.4em;
      @media @tablet-strict {
        font-size: 20px;
      }
      @media @phone {
        font-size: 18px;
        line-height: 1.42;
      }
    }

    &__expanding-btn {
      display: flex;
      align-items: center;
      background: none;
      border: 0;
      color: @white;
      cursor: pointer;
      /* cursor: default; */
      font: inherit;
      line-height: normal;
      overflow: visible;
      padding: 0;
      -webkit-appearance: button; /* for input */
      -webkit-user-select: none; /* for button */
      -moz-user-select: none;
      -ms-user-select: none;
      position: absolute;
      right: 22px;
      top: 13px;
      outline: 0;
      &__text {
        font-size: 16px;
        letter-spacing: -0.1px;
        color: @white;
      }
      .sol-icon {
        .icon-size(11px, 13px);
        color: @white;
        transform: rotate(180deg);
        transition: all .3s ease-in-out;
        margin-right: 10px;
        margin-top: 5px;
      }
      &[aria-pressed="true"] {
        .sol-icon {
          transform: rotate(0);
          margin-top: -5px;
        }
      }
    }
    &__more {
      width: 128px;
      height: 40px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-align: right;
      padding: 4px 8px;
      margin-right: 10px;
      border: 2px solid @crisis-btn-border;
      border-radius: 5px;
      background-color: @crisis-btn-bg;
      color: @crisis-text;
      font-weight: 600;
      text-decoration: none;
      .transition(background-color);
      margin-top: 17px;
      @media @tablet-strict {
        margin-top: 28px;
      }
      @media @phone {
        font-size: 12px;
        margin-top: 17px;
      }

      &:hover {
        background-color: @crisis-btn-bg-hover;
      }
    }
  }

  .sol-close-crisis {
    position: absolute;
    top: 10px;
    right: 15px;
    z-index: 10;
    color: @crisis-text;
    cursor: pointer;
    @media @tablet {
      font-size: 0;
    }
    @media @phone {
    }

    & i.fa {
      margin-left: 10px;
      opacity: 0.55;
      @media @tablet {
        font-size: 20px;
      }
    }
  }
}

.no-scroll {
  .sol-crisis-container {
    right: -17px;
  }
}

.sol-no-script {
  i.fa-warning {
    font-size: 50px;
    margin-top: -57px;
  }

  .sv-row {
    padding: 15px 20px 0 90px;
  }

  .sol-crisis-message-title {
    margin-bottom: 5px;

  }
}
