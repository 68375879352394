// förtroendemanna-register proxy styling
#troman {
  margin-bottom: 40px;

  & header {
    display: none;
  }

  & a {
    color: @normal-link;
    &:hover {
      color: @normal-link-hover;
    }
  }

  & ul {
    font-size: 21px;
    margin: 0 0 20px 0;
    padding: 0 0 0 40px;

    & li {
      line-height: 31.5px;
    }
  }

  & table {
    font-size: 15px;

    & thead {
      color: @thead-color;
    }

    & th {
      line-height: 27px;
    }

    & td {
      border-right: 1px solid rgb(235, 244, 247);
      border-top: none;
      line-height: 22px;
    }

    & th, td {
      padding: 10px 0 10px 20px;
    }

    & tbody {
      background-color: @tbody-bg;
    }
  }

  & button {
    outline: none;
    font-weight: bold;
    font-size: 17px;
    text-shadow: none;
    box-shadow: none;
    background-image: none;
    background-color: @button-bg;
    border-color: @button-bg;
    color: @button-text;
    padding: 10px 34px;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    &:hover {
      background-image: none;
      color: @button-text-hover;
      background-color: @button-bg-hover;
      border-color: @button-border-color-hover;
    }
  }

  & input[type=text], select {
    padding: 0 11px;
    line-height: 22px;
    height: 35px;
    background-color: @form-input-bg;
    color: @form-input-text;
    border-color: @form-input-border;
    &:hover {
      border-color: @form-input-hover-border;
    }
    &:focus {
      background-color: @form-input-focus-bg;
      border-color: @form-input-focus-border;
    }
  }

  & .form-search {
    margin: 20px 0;
  }

  & .form-horizontal .control-label {
    padding-top: 11px;
    width: 170px;
  }

  & .help-block {
    margin-bottom: 4px;
  }

  & .search-query {
    border-radius: 4px;
    transform: translateY(4px);
    margin-right: 10px;
  }
}
