/* Modal Base CSS */
.sol-modal {
  top: -300%;
  display: block;
  position: absolute;
  z-index: 9999;
  left: 50%;
  opacity: 0;
  width: 94%;
  padding-top: 45px;
  padding-bottom: 36px;
  -webkit-transition: 1ms opacity ease;
     -moz-transition: 1ms opacity ease;
      -ms-transition: 1ms opacity ease;
       -o-transition: 1ms opacity ease;
          transition: 1ms opacity ease;

  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
       -o-transform: translateX(-50%);
          transform: translateX(-50%);

  border-radius: 2px;
  background: #fff;
  &.open {
    opacity: 1;
    top: 98px;
    -webkit-transition: 500ms top 500ms ease;
       -moz-transition: 500ms top 500ms ease;
        -ms-transition: 500ms top 500ms ease;
         -o-transition: 500ms top 500ms ease;
            transition: 500ms top 500ms ease;
  }
  &__overlay {
    display: block;
    opacity: 0;
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    opacity: 0;

    width: 100%;
    height: 100%;

    -webkit-transition: 1ms opacity ease;
       -moz-transition: 1ms opacity ease;
        -ms-transition: 1ms opacity ease;
         -o-transition: 1ms opacity ease;
            transition: 1ms opacity ease;

    background: rgba(0,0,0,.6);
    &.open {
      opacity: 1;
    }
  }
}




.scotch-modal.scotch-open.scotch-anchored
{
    top: 20px;

    -webkit-transform: translate(-50%, 0);
       -moz-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
         -o-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
}

.scotch-modal.scotch-open
{

}

.scotch-overlay.scotch-open
{
    opacity: 1;

}

/* Close Button */
.scotch-close
{
    font-family: Helvetica,Arial,sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 12px;

    position: absolute;
    top: 5px;
    right: 5px;

    padding: 5px 7px 7px;

    cursor: pointer;

    color: #fff;
    border: 0;
    outline: none;
    background: #e74c3c;
}

.scotch-close:hover
{
    background: #c0392b;
}

/* Default Animation */

.scotch-overlay.fade-and-drop
{
    display: block;

    opacity: 0;
}

.scotch-modal.fade-and-drop
{

}

.scotch-modal.fade-and-drop.scotch-open
{
    top: 50%;

    -webkit-transition: 500ms top 500ms ease;
       -moz-transition: 500ms top 500ms ease;
        -ms-transition: 500ms top 500ms ease;
         -o-transition: 500ms top 500ms ease;
            transition: 500ms top 500ms ease;
}

.scotch-modal.fade-and-drop.scotch-open.scotch-anchored
{

    -webkit-transition: 500ms top 500ms ease;
       -moz-transition: 500ms top 500ms ease;
        -ms-transition: 500ms top 500ms ease;
         -o-transition: 500ms top 500ms ease;
            transition: 500ms top 500ms ease;
}

.scotch-overlay.fade-and-drop.scotch-open
{
    top: 0;

    -webkit-transition: 500ms opacity ease;
       -moz-transition: 500ms opacity ease;
        -ms-transition: 500ms opacity ease;
         -o-transition: 500ms opacity ease;
            transition: 500ms opacity ease;

    opacity: 1;
}

.scotch-modal.fade-and-drop
{
    -webkit-transition: 500ms top ease;
       -moz-transition: 500ms top ease;
        -ms-transition: 500ms top ease;
         -o-transition: 500ms top ease;
            transition: 500ms top ease;
}

.scotch-overlay.fade-and-drop
{
    -webkit-transition: 500ms opacity 500ms ease;
       -moz-transition: 500ms opacity 500ms ease;
        -ms-transition: 500ms opacity 500ms ease;
         -o-transition: 500ms opacity 500ms ease;
            transition: 500ms opacity 500ms ease;
}

/* Demo Styles */
html
{
    box-sizing: border-box;
}

*
{
    box-sizing: inherit;
}

body
{
    font-family: Helvetica,Arial,san-serif;
    font-size: 16px;

    margin: 0;
    padding: 0;
}

#content
{
    display: none;
}

.trigger-button
{
    font-size: 17px;

    position: relative;
    top: 100px;

    display: block;

    margin: auto;
    padding: 10px 30px;

    cursor: pointer;

    color: #fff;
    border: 0;
    border-radius: 3px;
    outline: none;
    background: #2ecc71;
    box-shadow: 0 5px 1px #27ae60;
}

.trigger-button:hover
{
    background: #27ae60;
    box-shadow: 0 5px 1px #145b32;
}

.trigger-button:active
{
    border-top: 5px solid white;
    box-shadow: none;
}
