.sol-landingpage-wrapper {
  .sol-startsida-news {
    width: 73%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    @media @phone {
      width: 91.6%;
    }
  }
}