.sol-login-wrapper {
  height: 100vh;
  width: 100vw;
  position: relative;
  .sol-box-decoration {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 375px;
  }
}
body {
  text-rendering: optimizeLegibility;
  // NY
  .sol-body-overlay {
    &--desktop {
      position: fixed;
      background-color: #000;
      opacity: 0;
      visibility: hidden;
      z-index: 998;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: all .2s ease;
    }
  }
  .sol-body-overlay {
    @media @desktop {
      position: fixed;
      background-color: #000;
      opacity: .55;
      z-index: -1;
      top: 0;
      bottom: 0;
    }
    @media @tablet {
      display: none;
    }
  }
  &.no-scroll {
    height: 100vh;
    overflow: hidden;
    position: fixed;
    width: 100%;
    .sol-body-overlay {
      &--desktop {
        opacity: .55;
        visibility: visible;
        transition: all .2s ease;
      }
    }
    // Ny


    // Gammal
    .sol-body-overlay {
      @media @desktop {
        z-index: 56;
        width: 100%;
      }
    }
  }
}

.sol-subpage-wrapper .sv-linkicon,
.sol-article-wrapper .sv-linkicon,
.sol-landingpage-wrapper .sv-linkicon,
.sol-service-message-wrapper .sv-linkicon,
.sol-event-page-wrapper .sv-linkicon,
.sol-startpage-wrapper .sv-linkicon {
  display: none;
}

.goog-logo-link {
    @media @tablet {
      & img {
        width: auto !important;
        margin-bottom: 0 !important;
      }
  }
}

.sol-content {
  min-height: calc(100vh - 555px);
}