//.sol-mini-site {
  table {
    border: 1px solid @ms-table-border-color;
    border-radius: 3px;
    width: 100%;
    thead {
      > tr {
        background-color: @ms-table-header-bg;
        > th {
          border-right: 1px solid @ms-table-header-border-color;
        }
        p.font-normal {
          font-size: 15px;
          font-weight: bold;
          line-height: 1.8;
          color: @ms-table-header-text-color;
          padding-top: 10px;
          padding-bottom: 10px;
          padding-left: 20px;
        }
        th:last-child {
          p.font-normal {
            border-right: 0;
          }
        }
      }
    }
    tbody {
      tr:nth-child(odd) {
        background-color: @ms-table-row-bg--odd;
      }
      tr:nth-child(even) {
        background-color: @ms-table-row-bg--even;
      }
      td {
        border-right: 1px solid #EBF4F7;
        p.font-normal {
          padding-top: 10px;
          padding-bottom: 10px;
          padding-left: 20px;
          color: @black;
          font-size: 15px;
          line-height: 1.47;
          text-align: left;
        }
      }
    }
  }
//}
