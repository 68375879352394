.sol-feedback__form {
  & textarea {
    width: 100%;
    min-height: 200px;
  }
}
.sol-feedback {
  color: @black;
  font-size: 16px;
  font-weight: 700;
  background-color: @pale-sea;
  padding: 5px 15px;
  margin-top: 16px;
  max-height: none;
  min-height: 45px;
  line-height: 35px;
  border-radius: 4px;
  position: relative;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @media @phone {
      max-height: none;
    }
  }

  @media @phone {
    max-height: none;
    height: 100px;
  }
}

.sol-feedback__content { 
  margin-bottom: 0px;
  display: block;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: block;
  }

  & > span {
    display: inline;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      display: inline-block;
      float: left;
    }
  }
}

.sol-feedback__answers { 
  display: inline;
  white-space: nowrap;
  padding: 0;
  border:solid 0px #ccc;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: inline-block;
    float: right;
    margin-top: -10px;
  }
}

.sol-js-feedback-yes { 
  margin-right: 5px;
  margin-left: 5px;
  color: #fff;
  font-size: 17px;
  background-color: #0e3c5d;
  height: 35px;
  width: 60px;
  border-radius:4px;
  box-shadow: none;
  border: none;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: inline-block;
    float: left;
    width: 45px;
    padding: 5px 10px;
    margin: 0 10px;
  }

  @media @phone {
    margin-left: 0%;
  }
  &:hover{ 
    opacity: 0.85;
  }
}

.sol-js-feedback-no { 
  margin-right: 5px;
  color: #fff;
  font-size: 17px;
  background-color: #0e3c5d;
  height: 35px;
  width: 60px;
  border-radius:4px;
  box-shadow: none;
  border: none;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: inline-block;
    float: left;
    width: 45px;
    padding: 5px 10px;
    margin: 0 10px;
  }

  @media @phone {
    margin-right: 0%;
  }
  &:hover{ 
    opacity: 0.85;
  }
}

.sol-feedback__form {
  position: absolute;
  z-index: 99;
  background-color: @pale-sea !important;
  border: 3px solid #0e3c5d;
  padding: 30px 20px !important;
  clear:both;
  font-size: 14;
  font-weight: 200;

  &--close {
    position: absolute;
    right: -10px;
    top: -10px;
    font-size: 24px;
    color: #0e3c5d;
    text-align: center;
    background-color: @pale-sea;
    border: 3px solid #0e3c5d;
    border-radius: 50%;
    width: 32px;
    height: 32px;

    .fa-close {
      position: absolute;
      left: 0;
      right: 0;
    }
  }

  &.sol-hidden {
    padding:20px 0 0 0;

    & span {
      display: block;
      margin-bottom: 0;
      font-size: 17px;
    }

    & textarea {
      margin-top: 10px;
      margin-bottom: 10px;
      width: 100%;
      resize: none;
      min-height: 0;

      &#feedbackMessage {
        height: 100px;
        margin-bottom: 20px;
        margin-top: 15px;
      }
      &#feedbackSender {
        height: 45px;
        margin-top: 0px;
      }
    }
  }
  & label {
    margin-bottom: 0;
    font-size: 16px !important;
  }
}

.sol-feedback__thanks { 
  position: absolute;
  background-color: @pale-sea !important;
  border: 3px solid #0e3c5d;
  padding: 30px 20px !important;
  clear:both;
  padding:20px 0 0 0;

  & p {
    font-size: 16px !important;
    font-weight: normal !important;
    margin-top: -10px !important;
  }
    & span {
      font-weight: 600;
      font-size: 17px;
      margin-bottom: -15px;
      display: block;
    }
  }
