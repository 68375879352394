.sol-uvk {
  .sv-grid-main {
    padding: 0;
  }
  .sol-beige-container {
    background-color: @beige;
  }
}
  .sol-landingpage-wrapper {
    .sol-children-pages{
      @media @tablet {
        padding-left: 20px;
        padding-right: 20px;
      }
      &--big {
        width: 73%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        .sol-child-page-box {
          display: flex;
          flex-direction: column;
          position: relative;
          margin-bottom: 25px;
          flex-basis: 31%;
          @media @tablet {
            width: 100%;
          }
          @media @tablet-strict {
            flex-basis: auto;
            width: 48%;
            &:nth-child(2n + 2) {
              margin-left: auto;
            }
          }
          @media @phone {
            flex-basis: 100%;
            height: 50px;
            margin-bottom: 10px;
          }
          @media (min-width: 1024px) {
            &:nth-child(3n + 2) {
              margin-left: 3.5%;
              margin-right: 3.5%;
            }
          }
          &__header {
            display: flex;
            align-items: center;
            position: relative;
            padding-left: 22px;
            background-color: @ms-color-1;
            font-size: 20px;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
            font-weight: bold;
            text-align: left;
            color: #ffffff;
            min-height: 45px;
            padding: 10px 50px 10px 22px;
            > span,
            > h2 > a {
              color: @white;
              font-size: 20px;
              text-decoration: none;
              @media @phone {
                font-size: 16px;
              }
            }
            > h2 {
              margin: 0;
            }
            &__icon {
              width: 16px;
              height: 16px;
              background-image: url(/webdav/files/System/resources/graphics/icons/pil-h-vit.svg);
              background-size: 16px 16px;
              display: inline-block;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 28.5px;
            }
          }
          &__content {
            @media @phone {
              display: none;
            }
            padding: 9px 15px 13px;
            background-color: @white;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            transition: all .2s ease-in-out;
            p {
               margin: 0;
               font-size: 19px;
               font-weight: 300;
               line-height: 1.4;
               text-align: left;
               color: #3b3b3b;
            }
          }
          &__link {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            text-decoration: none;
            z-index: 2;
            &:hover {
              & + .sol-child-page-box__content {
                text-decoration: underline;
                background-color: #ebf4f7;
              }
            }
          }
          &:hover {
            .sol-child-page-box__content {
              text-decoration: underline;
            }
          }
        }
      }
      &--small {
        width: 73%;
        @media @tablet {
          width: 100%;
        }
        @media @tablet-strict {
          padding-left: 4.6%;
          padding-right: 4.6%;
        }
        @media @phone {
          margin-top: 15px;
          margin-bottom: 24px;
        }
        margin-bottom: 41px;
        margin-left: auto;
        margin-right: auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        background-color: @white;
        padding-left: 30px;
        padding-top: 23px;
        padding-bottom: 30px;
        .sol-child-page-box {
          flex-basis: 33.33%;
          margin-bottom: 23px;
          position: relative;
          padding-left: 15px;
          &:nth-last-child(-n+3) {
            margin-bottom: 0;
            @media @tablet {
              margin-bottom: 23px;
            }
          }
          &:nth-last-child(-n+2) {
            @media @tablet-strict {
              margin-bottom: 0;
            }
          }
          &:last-child {
            @media @phone {
              margin-bottom: 0;
            }
          }
          @media @tablet-strict {
            flex-basis: 50%;
            display: flex;
            justify-content: flex-start;
          }
          @media @phone {
            flex-basis: 100%;
            display: flex;
            justify-content: flex-start;
          }
          &__link {
            text-decoration: none;
            font-size: 16px;
            font-weight: bold;
            text-align: left;
            color: @downriver;
            &:hover {
                text-decoration: underline;
              }
          }
          .fa {
            color: rgba(14, 60, 93, 0.4);
            font-size: 12px;
            position: absolute;
            top: 0;
            left: 0;
            line-height: 21px;
          }
        }
      }
      &--big, &--small {
        @media @phone {
          width: 100%;
        }
      }
      &--lvl3 {
        display: flex;
        flex-wrap: wrap;
        padding-top: 32px;
        padding-bottom: 32px;
        padding-left: 11.5%;
        padding-right: 11.5%;
        @media @tablet {
          padding-left: 2.6%;
          padding-right: 2.6%;
        }
        @media @phone {
          padding-left: 4.2%;
          padding-right: 4.2%;
        }
        .sol-child-page-box {
          position: relative;
          flex-basis: 49%;
          max-width: 49%;
          flex-grow: 0;
          min-height: 75px;
          height: auto;
          background-color: @white;
          padding: 12px 21px;
          cursor: pointer;
          transition: all .2s ease;
          &:hover {
            background-color: @pale-sea;
            span, p {
              text-decoration: underline;
            }
          }
            margin-bottom: 15px;
          &:nth-child(odd){
            margin-right: 2%;
            @media @phone {
              margin-right: 0;
            }
          }
          @media @tablet-strict {
            min-height: 102px;
          }
          @media @phone {
            flex-basis: 100%;
            max-width: 100%;
            min-height: 47px;
            height: 47px;
            margin-bottom: 15px;
          }
          &__header {
            span,
            h2 > a {
              &:first-child {
                font-size: 19px;
                font-weight: bold !important;
                line-height: 1.3;
                text-align: left;
                color: @downriver;
                font-size: 19px;
                font-weight: 300;
                text-align: left;
                text-decoration: none;
              }
            }
            h2 {
              margin: 0;
            }

            &__icon {
              width: 16px;
              height: 16px;
              background-image: url(/webdav/files/System/resources/graphics/icons/Pil-h.svg);
              background-size: 16px 16px;
              display: inline-block;
              position: absolute;
              right: 28.5px;
              top: 14px;
            }
          }
          &__content {
            @media @phone {
              display: none;
            }
            > p {
              line-height: 1;
              font-size: 19px;
              font-weight: 300;
              margin-top: 5px;
              color: @black;
            }
          }
          &__link {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
          }
        }

      }
    }

  }
