.sol-subpage-wrapper .sol-menu {
  border-radius: 4px;
  background-color: @box-decoration-bg;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1);
  margin-top: 48px;
  margin-bottom: 20px;

  
  &__root-name {
    display: block;
    padding: 11px 15px 14px 30px;
    background-color: @downriver;
    border-bottom: 1px solid #6e8a9e;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    text-align: left;
    margin: 0;
    transition: background-color .2s ease-in-out;
    
    a {
      color: @box-decoration-header-text;
      text-decoration: none;
      line-height: 1;
      font-weight: 700;
      font-size: 20px;
    }
    
    &:hover {
      background-color: #06253c !important;
    }
    
    a & {
      .focus-outline;
    }
  }
  
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .sol-menu-item {
    position: relative;

    & > a {
      border-bottom: 1px solid @submenu-item-border-color;
    }

    &[data-expanded="true"] > .sol-menu-item__button i {
      transform: rotate(90deg);
    }

    a {
      text-decoration: none;
      display: block;
      padding-top: 11px;
      padding-bottom: 14px;
      transition: background-color .3s ease-in-out;

      span {
        font-size: 18px;
      }
    }

    &[data-menu-level="0"] a {
      .focus-outline;
      .sol-relative;
      display: block;
      padding: 8px 34px 8px 20px;
      color: @submenu-text;
      text-decoration: none;
      .transition(~'color, background-color, border');
    }

    &[data-menu-level="1"] > a {
      padding-left: @submenu-lvl1-padding;
    }

    &[data-menu-level="2"] > a {
      padding-left: @submenu-lvl2-padding;
    }

    &[data-menu-level="3"] > a {
      padding-left: @submenu-lvl3-padding;
    }

    &[data-menu-level="4"] > a {
      padding-left: @submenu-lvl4-padding;
    }

    &[data-expanded="true"][data-menu-level="0"] {
      & > a,
      & > a:hover {
        & i {
          color: red;
        }
      }

      & > .sol-menu-list > ul {
        border-left: 2px solid @submenu-border--expanded;
      }
    }

    &[data-expanded="true"]:not([data-menu-level="0"]) {
      & > a > span {
        color: @submenu-text--expanded;
      }
  
      &[data-menu-level="1"], &[data-menu-level="2"], &[data-menu-level="3"], &[data-menu-level="4"] {
        > i {
          color: @submenu-icon-color;
        }

        & > a {
          background-color: @submenu-bg--expanded--current;
        }
      }

      &[data-expanded="true"] {
        & > a > span {
          font-weight: bold;
          color: @white;
        }

        & > .sol-menu-item__button > i {
          color: @submenu-icon-color--expanded;
        }

        &[data-has-children="true"] {
          &[data-is-current="true"] {
            & > a {
              background-color: @submenu-bg--current;
            }

            & > .sol-menu-item__button > i {
              color: @submenu-icon-color--current;
            }
          }

          &[data-is-current="false"] > a:hover {
            background-color: #0e3c5d;
          }
        }
        &:not([data-has-children="true"]) {
          &[data-is-current="true"] {
            & > a {
              background-color: @submenu-bg--current;

              & > span {
                color: @black;
              }
            }
            & > .sol-menu-item__button > i {
              color: @submenu-icon-color--current;
            }
          }
        }
      }

      &[data-is-current="true"] {
        & > a > span {
          color: @submenu-text--expanded;
        }
      }
    }

    &[data-expanded="true"] > a i {
      color: @white;
      transform: rotate(90deg);
    }

    &[data-expanded="true"][data-has-children="false"] > a i {
      color: @submenu-text--expanded;
    }

    .sol-menu-item__button {
      .no-appearance;
      .focus-outline;
      position: absolute;
      right: 0;
      width: 50px;
      height: 50px;
      text-align: right;
      font-family: FontAwesome;
      font-size: 17px;
      top: 0;
      bottom: 1px;
      padding-right: 20px;
      cursor: pointer;
      & > i {
        position: absolute;
        font-style: normal;
        top: 50%;
        right: 25px;
        margin-top: -9px;
        .transition(all);
      }
    }
  }
}
