.sol-collapsible-decoration,
.sol-collapsible-decoration-small {
  margin-bottom: 15px;
  width: 650px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  .sol-collapsible-header {
    .no-appearance;
    border-left: 5px solid @ms-plusbox-border-color;
    background-color: @white;
    padding: 8px 18px 11px;
    display: block;
    width: 100%;
    text-align: left;
    position: relative;
    cursor: pointer;
    line-height: normal;
    min-height: 50px;
    overflow: visible;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
    .transition(background-color);
    &-text {
      display: block;
      font-family: @main-font;
      line-height: 1.1;
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      text-align: left;
      .transition(color);
      @media @phone {
        font-size: 16px;
        padding-right: 25px;
      }
      /*@media @webkit-only {
        display: block;
        padding-top: 2px;
      }*/
    }
    &:hover .sol-collapsible-header-text {
      text-decoration: underline;
    }
    &-icon {
      display: block;
      width: 32px;
      height: 32px;
      border: 2px solid @ms-plusbox-border-color;
      border-radius: 50%;
      background-color: @white;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      &::after {
        font-family: fontAwesome;
        content: "\f054";
        display: block;
        color: @ms-plusbox-header-bg;
        .transition(all);
      }
    }
    &.sol-active {
      background-color: @ms-theme-color;
      & .sol-collapsible-header-text {
        color: @white;
      }
      & .sol-collapsible-header-icon {
        &::after {
          .rotate(90deg);
        }
      }
    }
  }
  & .sol-collapsible-content,
  & .sv-decoration-content {
    display: none;
    margin-left:  auto;
    margin-right: auto;
    width: 95%;
    border-radius: 2px;
    background-color: white;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
    & > *:last-child {
      margin-bottom: 0;
    }
    .sv-editing-mode & {
      display: block;
    }

    & ul {
      @media @phone{
        padding-left: 19px;
      }
    }
  }
  .sol-blue-box, .sol-informationbox {
    padding: 0;
    .sv-decoration-content {
      display: block;
      margin: 0;
      box-shadow: none;
    }
  }
  .sol-blue-box {
    .sv-decoration-content {
      background-color: #ebf4f7;
    }
  }
}
.sol-collapsible-decoration-small {
  margin-bottom: 20px;
  @media @tablet-strict {
    margin-bottom: 15px;
  }
  .sol-collapsible-header {
    min-height: 35px;
    padding-top: 9px;
    padding-bottom: 10px;
    background-color: @lgt-color-2;
    &-text {
      font-size: 14px;
      color: @color-3;
    }
    &-icon {
      height: 37px;
      width: 37px;
      right: -17px;
      //top: -1px;
      margin-top: -19px;
      &::after,
      &::before {
        margin-left: -8px;
        margin-top: -1px;
        width: 16px;
      }
    }
    &.sol-active {
      background-color: @color-3;
      & .sol-collapsible-header-text {
        color: @white;
      }
    }
  }
}
