.sol-process-flow {
  margin-right: 15px;
  margin-left: 15px;
  opacity: 0;
  transition: opacity .2s ease;
  .slick-track, .slick-list {
    height: 100%;
  }
  .slick-next, .slick-prev {
    transform: none;
    top:7px;
    z-index: 9;
    background-color: @downriver;
    width: 29px;
    height: 29px;
    border-radius: 50%;
    &:before {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      content: '';
      background-image: url('/webdav/files/System/resources/graphics/icons/Chevron-down-white.svg');
      width: 15px;
      height: 20px;
      background-size: 100% 100%;
    }
    &.sol-hidden {
      display: none !important;
    }
  }
  .slick-next {
    right: -10px;
    &:before {
      transform: translate(-50%, -50%) rotate(-90deg);
    }
  }
  .slick-prev {
    left: -10px;
    &:before {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
  .sv-decoration-content {
    padding: 25px 25px 31px;
    background-color: white;
    width: 100% !important;
    display: block;
    > div {
      display: none;
    }
  }
  .sol-collapsible-decoration {
    .sv-decoration-content {
      > div {
        display: block;
      }
    }
  }

  &__stage {
    &__header {
      position: relative;
      display: inline-flex !important;
      padding-top: 35px;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      outline: 0;
      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        background-color: #ececec;
        left: 0;
        top: 20px;
        right: 0;
        height: 3px;
      }
      &::after {
        box-sizing: border-box;
        content: "";
        display: inline-block;
        position: absolute;
        left: 50%;
        top: 14px;
        -webkit-transform: translate(-50%);
        -moz-transform: translate(-50%);
        -ms-transform: translate(-50%);
        -o-transform: translate(-50%);
        transform: translate(-50%);
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: #92B461;
        z-index: 2;
      }

      &.user-selected{
        background-color: @white;
      }
      &[data-stage-status="completed"] {
        &::after {

        }
      }
      &[data-stage-status="ongoing"] {
        &::after {
          width: 25px;
          height: 25px;
          background-color: @white;
          border: 5px solid #92B461;
          top: 9px;
        }
      }

      &[data-stage-status="unhandled"] {
        .sol-process-flow__stage__num, .sol-process-flow__stage__name {
          color: tint(@black, 50%);
        }
        &::after {
          background-color: @pale-sea;
          border: 2px solid #e7e7e7;
        }
      }
      &[data-is-last-stage="true"] {
        &::before {
          transform: translateX(-50%);
        }
      }
      &[data-stage-num='1']{
        &::before {
          left: 50%;
          top: 20px;
          right: 0;
          height: 3px;
        }
      }
    }
    &__num {
      font-size: 20px;
      font-weight: bold;
    }
    &__name{
      font-size: 12px;
      line-height: 12px;
      text-align: center;
    }
  }
  &__header {
    background-color: @downriver;
    height: 42px;
    .flex-centered;
  }
  &__title {
    color: @white;
    font-size: 17px;
    font-weight: bold;
    line-height: 1.88;
  }
  &__stages {
    background-color: @pale-sea;
    height: 85px;
    display: flex;
  }
}
