.sol-landing-page__puffar {
  display: flex;
  flex-wrap: wrap;
  padding: 42px 6.3%;
  @media @tablet-strict {
    padding: 23px 2.6%;
  }
  @media @phone {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 7px;
  }
}

.sol-landing-page__puff {
  &--image, &--no-image {
    display: inline-flex;
    position: relative;
    width: 32.5%;
    height: 335px;
    background-position: 50% 50%;
    background-size: cover;
    margin-bottom: 30px;
    &:nth-child(3n + 2) {
      margin-left: 1.25%;
      margin-right: 1.25%;
      @media @phone {
        margin-left: 0;
        margin-right: 0;
      }
    }
    @media @tablet-strict {
      height: 300px;
    }
    @media @phone {
      width: 100%;
      margin-bottom: 16px;
    }
    &:hover {
      .sol-landing-page__puff__preamble {
        text-decoration: underline;
      }
    }
  }
  &__text-container {
    width: 90%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 5.3%;
    padding: 18.5px 13.5px 40px 13.5px;
    min-height: 179px;
    background-color: fade(@pale-sea, 90%);
    .sol-top {
      display: flex;
      flex-direction: column;
      .sol-landing-page__puff__title {
        font-size: 18px;
        font-weight: bold;
        color: @black;
        margin-bottom: 8px;
      }
    }
    .sol-bottom {
      position: absolute;
      bottom: 0;
      left: 13.5px;
      right: 13.5px;
      height: 40px;
      border-top: 1px solid #a7a7a7;
      background-image: url('/webdav/files/System/resources/graphics/icons/Pil-h.svg');
      background-size: 16.5px 16.5px;
      background-repeat: no-repeat;
      background-position: 100% center;
    }
  }
  &--no-image {
    background-color: @pale-sea;
    .sol-landing-page__puff__text-container {
      background-color: @white;
    }
  }
}
