* {
  box-sizing: border-box;
}

a {
  .focus-outline;
}

.sol-ul,
.sol-ul-container ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sol-float-right {
  float: right;
}

.sol-float-left {
  float: left;
}

.sol-hidden {
  display: none;
}

.sol-modal-overflow-hidden,
.sol-overflow-hidden {
  overflow: hidden;
}

.sol-relative {
  position: relative;
}

.sol-no-anim * {
  transition: none !important;
}

.sv-grid-main {
  padding-left: 45px;
  padding-right: 45px;
  @media @tablet {
    padding-right: 20px;
    padding-left: 20px;
  }
  @media @phone {
    padding-left: 15px;
    padding-right: 15px;
  }
  @media @phone-sm {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.sv-grid-main div[class*='sv-column'] {
  padding-left: 10px;
  padding-right: 10px;
}

.sv-grid-main .sv-column-12 {
  margin-bottom: 0;
}

.sv-grid-main .sv-row {
  margin-left: -10px;
  margin-right: -10px;
}

.sol-section {
  padding-top: 35px;
  padding-bottom: 50px;
  @media @tablet {
    padding-top: 20px;
    padding-bottom: 40px;
  }
  @media @phone {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  @media @phone-sm {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

.sol-external-link-icon {
  margin-left: 6px;
  font-size: 10px;
}
