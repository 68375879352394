  body {
    font-family: @main-font;
    color: @general-text;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    background-color: @body-bg;
    overflow-x: hidden;
  }

  h1, h2, h3, h4, h5, h6, p, span, blockquote, strong, div {
    font-family: 'Source Sans Pro', sans-serif !important;
    color: @black;
  }
  p,
  p.font-normal,
  .font-normal,
  p.font-description,
  .font-description
  {
    margin-top: 0;
    margin-bottom: 20px;
    font-family: @main-font;
    color: @normal-text;
    font-size: 21px;
    line-height: 1.5em;
    font-weight: 400;
    @media @phone {
      font-size: 18px;
    }
    a&,
    a {
      color: @normal-link;
      text-decoration: underline;
      &:hover {
        color: @normal-link-hover;
      }
    }
  }

  p.font-description, .font-description-old {
    color: @desc-text;
    font-weight: 700;
    a&,
    a {
      color: @desc-link;
      &:hover {
        color: @desc-link-hover;
      }
    }
  }
  h1,
  .font-heading-1,
  h1.font-heading-1 {
    font-family: @main-font;
    color: @h1-text;
    margin-top: 0px;
    margin-bottom: 12px;
    font-size: 28px;
    line-height: 1.3em;
    font-weight: 800;
    @media @tablet {
      font-size: 31px;
      line-height: 25px;
    }
    @media @phone {
      font-size: 26px;
    }
    @media @phone-sm {
      margin-bottom: 10px;
      font-size: 20px;
      line-height: 26px;
    }
  }

  h2,
  h2.font-heading-2,
  .font-heading-2 {
    font-family: @main-font;
    color: @h2-text;
    margin-bottom: 5px;
    font-size: 20px;
    line-height: 1.2em;
    font-weight: 800;
    @media @phone-sm {
      font-size: 18px;
      line-height: 29px;
    }
  }

  h3,
  h3.font-heading-3,
  .font-heading-3 {
    font-family: @main-font;
    color: @h3-text;
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 1.2em;
    font-weight: 800;
    @media @phone-sm {
      font-size: 14px;
    }
  }

  h4,
  h4.font-heading-4,
  .font-heading-4 {
    font-family: @main-font;
    color: @h4-text;
    margin-bottom: 5px;
    font-size: 13px;
    line-height: 1.2em;
    font-weight: 800;
    @media @phone-sm {
      font-size: 12px;
    }
  }

  h5,
  h5.font-heading-5,
  .font-heading-5 {
    font-family: @main-font;
    color: @h4-text;
    margin-bottom: 5px;
    font-size: 13px;
    line-height: 1.2em;
    font-weight: 800;
    @media @phone-sm {
      font-size: 12px;
    }
  }

  blockquote.sv-blockquote,
  blockquote.sv-blockquote-edit {
    padding-left: 0;
    margin: 0;
    &:before {
      width: 0 !important;
      background: transparent !important;
    }
  }
  p.font-quote,
  .font-quote,
  .sv-blockquote p,
  .sv-blockquote-edit p {
    position: relative;
    font-family: @main-font;
    color: @quote-text;
    font-size: 22px;
    line-height: 29px;
    font-weight: 100;
    margin-top: 20px;
    margin-bottom: 30px;
    padding-left: 55px;
    @media @tablet{
      padding-left: 35px;
      font-size: 20px;
      line-height: 27px;
    }
    @media @phone{
      font-size: 18px;
      line-height: 25px;
    }
    @media @phone-sm{
      padding-left: 30px;
    }
    &::before {
      .icon(@fa-var-quote-right, 20px);
      position: absolute;
      left: 20px;
      top: 0px;
      opacity: 0.3;
      line-height: 35px;
      @media @tablet{
        left: 5px;
        line-height: 33px;
      }
      @media @phone-sm{
        left: 5px;
        line-height: 31px;
      }
    }

  }

  ol,
  ul {
    &.sol-bullet-list,
    &.sol-numbered-list {
      margin-top: 5px;
      margin-bottom: 20px;
      padding-left: 0px;
      list-style: none !important;
      & li {
        position: relative;
        margin-bottom: 7px;
        padding-left: 15px;
        &::before {
          position: absolute;
          left: 0;
          top: 4px;
          display: inline-block;
          font-weight: 700;
        }
      }
    }
  }

  .sol-bullet-list li::before {
    .icon(@fa-var-angle-right, 16px);
    color: @list-icon;
  }

  ol,
  ul {
    &.sol-numbered-list {
      & li {
        counter-increment: step-counter;
        padding-left: 20px;
        &::before {
          content: counter(step-counter) '.';
          color: @list-number;
          font-size: 14px;
          text-align: center;
          top: 0;
        }
      }
    }
  }

.sv-font-kontaktbox-dagar {
  font-size: 17px;
  line-height: 1.53;
  text-align: left;
  color: @black;
  margin-bottom: 5px;
}
.sol-uvk h2.sv-font-kontaktbox-fett-stor {
  font-size: 31px;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 20px;
  line-height: 22px;
  text-align: left;
  color: @black;
  @media @tablet-strict {
    font-size: 27px
  }
  @media @phone {
    font-size: 26px;
  }
}
.sv-font-kontaktbox-fett-liten {
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.4px;
  text-align: left;
  color: @black;
  margin-bottom: 5px;
}
.sv-font-kontaktbox-ljusgra {
  font-size: 23px;
  font-weight: 300;
  line-height: 1.41;
  text-align: left;
  color: @black;
  margin-bottom: 30px;
  @media @tablet-strict {
    font-size: 20px;
    margin-bottom: 20px;
  }
  @media @phone {
    font-size: 18px;
  }
}
