.sol-uvk {
  .sol-dualbox {
    width: 94%;
    padding-left: 0;
    padding-right: 0;
    .margin-auto();
    @media @tablet {
      width: 100%;
    }
    >.sv-decoration-content {
      > div {
        flex-basis: 35%;
        &.sol-startsida-news {
          flex-basis: 52%;
          @media @tablet {
            flex-basis: 100%;
          }
          }
      }
    }
  }

  }
.sol-startsida-news {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.03);
  padding-left: 28px;
  padding-right: 52px;
  padding-top: 43px;
  padding-bottom: 31px;
  background: @white;
  margin-bottom: 85px;
  @media @tablet {
    margin-bottom: 0;
  }
  @media @phone {
    box-shadow: none;
  }
  h2.sol-startsida-news__header,
  &__header {
    font-size: 31px;
    font-weight: normal;
    line-height: 22px;
    margin-top: 0;
    margin-bottom: 25px;
    display: flex;
    @media @tablet-strict {
      font-size: 27px;
    }
    @media @phone {
      font-size: 26px;
    }
  }
  &__btn {
    .sol-btn;
    margin-top: 35px;
    font-size: 17px;
    font-weight: 700;
    margin-top: 35px;
    padding: 10px 34px;
    text-transform: inherit;
    @media @phone {
      margin-left: 0;
    }
  }
  .sol-article-item {
    padding-top: 0;
    padding-bottom: 20px !important;
    text-decoration: none;
    * {
      text-decoration: none;
    }
    &:hover {
      .sol-article-item__desc {
        text-decoration: underline;
      }
    }
    &__img {
      @media @phone {
        margin-right: 15px;
      }
      & + .sol-article-item__content {
        @media @phone {
          width: calc(~'100% - 116px');
        }
      }
      img {
        min-width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50%;
        font-family: 'object-fit: cover; object-position: 50%;';
      }
    }
    &__date {
      margin-bottom: 10px;
      > span {
        text-decoration: none;
        font-size: 14px;
        font-weight: 600;
        text-align: left;
        color: @gray-3;
        margin-right: 3px;
        text-transform: none;
      }
    }
    &__content {
      margin-bottom: 22px;
      .sol-article-item-heading {
        display: block;
        margin-bottom: 6.2px;
        font-size: 18px;
        font-weight: bold;
        text-align: left;
        color: #3b3b3b;
      }
      .sol-article-item__desc {
        display: block;
        font-size: 18px;
        line-height: 1.33;
        text-align: left;
        color: #3b3b3b;
      }
    }
  }
  .sol-article-item__img + .sol-article-item__content {
    .sol-article-item__desc {
      @media @phone {
        display: none;
      }
    }
  }
}
.sol-event-search__filter__content {
  transition-delay: 300ms ;
  transition-property: all;
  transition-duration: 300ms;
}

.sol-event-search__filter--collapsed .sol-event-search__filter__content  {
  overflow: visible !important;
}
