.sol-landingpage-wrapper {
  .sol-permission-top {
    .sv-row {
      display: flex;
      align-items: flex-end;

      .sv-column-6:first-child {
        .pagecontent {
          padding: 0 20px 30px 80px;

          @media @tablet {
            padding-bottom: 10px;
          }

          @media @phone {
            padding-left: 30px;
          }
        }

        @media @tablet {
          width: 100% !important;
          padding-top: 40px;
        }
      }

      .sv-column-6:last-child {
        padding: 0;

        @media @tablet {
          display: none;
        }
      }
    }
  }

  .sol-permission-middle.sol-beige-container--lvl2 {
    .sol-children-pages--lvl2 .sol-children-pages--big {
      width: 73%;

      .sol-child-page-box {
        flex-basis: 48%;

        &:nth-child(3n + 2) {
          margin-right: 0;
          margin-left: 0;
        }

        &:nth-child(2n) {
          margin-left: 4%;

          @media @phone {
            margin-left: 0;
          }
        }

        @media @phone {
          flex-basis: 100%;
        }
      }

      @media @tablet {
        width: 100%;
      }
    }

    .sv-row:last-child .pagecontent {
      width: 73%;
      margin-right: auto;
      margin-left: auto;
      display: flex;

      .sv-text-portlet {
        flex-basis: 30%;
        margin-right: 5%;

        &:last-child {
          margin-right: 0;
        }

        a {
          display: block;

          @media @phone {
            margin: 0;
          }
        }

        @media @phone {
          flex-basis: 100%;
          margin-right: 0;

          p.font-btn {
            padding: 0;
          }
        }
      }

      @media @tablet {
        width: 100%;
        flex-wrap: wrap;
        margin-top: 30px;
      }
    }
  }
}
