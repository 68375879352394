.sol-mini-site {
  .sol-article-list-page {
    .sv-archivemenu-portlet {
      @media @tablet{
        width: 100%;
      }
      .sol-news-filter {
        @media @tablet{
          width: 100%;
        }
        .sol-news-filter-button {
          cursor: pointer;
          @media @tablet{
            width: 100%;
          }
        }
      }
    }
    .sol-article-item-date {
      > span {
         font-size: 10px;
         font-weight: 600;
         line-height: 1;
         text-transform: uppercase;
      }
    }
  }
  .sol-archive-filter-cnt {
    display: flex;
    //border-bottom: 5px solid #ebf4f7; 
    margin-bottom: 20px;
    @media @phone {
      flex-direction: column;
    }
    .sol-news-filter-list {
      z-index: 8;
    }
    > div:first-child {
      .sol-news-filter-list {
        z-index: 9;
      }
    }
  }
  .sol-article-date {
    font-size: 18px;
    font-weight: bold;
    color: @search-top-field-text;
  }
  .sol-article-wrapper {
    .sol-simple-box-decoration {
      .sol-js-print {
        .fa-print {
          color: @downriver;
        }
        color: @downriver;
      }
    }
  }

  .sol-subpage-wrapper {
    .sv-archive-portlet {
      .sv-channel-item  {
          border-color: #ececec;
      }
      .sol-article-item {
        position: relative;
        display: flex;
        flex-direction: row;
        padding-bottom: 13px;
        padding-left: 230px;
        @media @phone {
          padding-left: 0;
        }
        &.sol-no-img {
          padding-left: 0;
        }
      }
      .sol-article-item-img {
        position: absolute;
        left: 0;
        width: 200px;
        height: 130px;
        margin-right: auto;
        background-size: cover;
        background-position: 50% 50%;
        @media @phone {
          position: static;
          width: 100px;
          height: 100px;
        }
      }
      .sol-article-text-content {
        width: calc(~'100% - 116px');
        .sol-article {
          margin-bottom: 15px;
        }
        @media @phone  {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

  }
}
