.sol-dualbox {
  padding-left: 78px;
  padding-right: 78px;
  @media @tablet {
    padding: 0;
  }
  > .sv-decoration-content {
    display: flex;
    width: 100%;
    > div {
      flex-basis: 50%;
    }
    @media @tablet {
      padding: 0;
      flex-wrap: wrap;
      > div {
        flex-basis: 100%;
      }
    }
  }
}

.sol-beige-container {
  background-color: @beige;
  padding: 30px;
  margin-left: auto;
  margin-right: auto;
  @media @phone {
    padding: 10px;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
  }
  > div {
    max-width: 828px;
    margin-left: auto;
    margin-right: auto;
  }
}
.sol-informationbox {
  background-color: @white;
  padding: 30px 65px;
  @media @phone {
    padding: 30px 20px;
  }
  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
  }
  p {
    font-size: 18px;
    margin: 0;
    line-height: 1.67;
    text-align: left;
  }
}
.sol-btn-decoration {
  display: inline-block;
  p.font-normal {
    margin: 0;
  }
  a {
    &:extend(.sol-btn);
  }
}

.sol-blue-box {
    background-color: @ms-color-2;
    align-self: flex-start;
    padding: 35px 48px 48px;
    h1.font-heading-1, h2.font-heading-2, h3.font-heading-3,
    h4.font-heading-4, h5.font-heading-5, h6.font-heading-6, p.font-normal {
      font-weight: 300;
    }
    .sol-btn-decoration {
      p {
        margin-bottom: 0;
      }
    }
    .sol-link-tablet {
      display: inline-flex;
      width: auto;
    }
    @media @tablet-strict {
      padding-left: 80px;
      padding-right: 80px;
    }

    @media @phone {
      padding: 22px 28px 34px;
    }
    @media @phone {
      p.font-normal {
        font-size: 18px !important;
      }
      h2.font-heading-2 {
        margin-top: 0;
      }
    }
    &__btn-cnt {
      display: flex;
      flex-wrap: wrap;
      > div {
        margin-right: 20px;
      }
      @media @phone {
        > div {
          width: 100%;
        }
      }
    }
}
.sol-box {
  &--white {
    padding: 43px 52px 48px 28px;
    background: @white;
    h1.font-heading-1, h2.font-heading-2, h3.font-heading-3,
    h4.font-heading-4, h5.font-heading-5, h6.font-heading-6 {
      font-weight: 300;
    }
  }
}
.sol-container {
  &--white {
    background-color: #fff;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.sol-icon-email, .sol-icon-mobile {
  & + a {
    font-weight: 400;
    font-size: 22px;
    line-height: 1.59;
    color: @ms-color-1 !important;
  }
  margin-right: 9px;
  width: 20px;
  height: auto;
  &:before {
    color:  @gray-3;
    .fa-fw;
    display: inline-block;
    font-family: fontAwesome !important;
  }
}
.sol-icon-email {
  &:before {
    content: "\f003";
  }

}
.sol-icon-mobile {
  &:before {
    content: "\f10b";
  }
}

.sol-dualbox-container {
  background-color: @beige;
  padding-top: 45px;
  padding-bottom: 45px;
  @media @tablet {
    padding-top: 0;
    padding-bottom: 0;
  }
  margin-left: auto;
  margin-right: auto;
}
