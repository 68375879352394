.sol-feedback {
  border-radius: 5px;
  @pale-sea: #ebf4f7;
  @feedback_bg: @pale-sea;
  @feedback_border-color: @pale-sea;
  background-color: @feedback_border-color ;
}
.sol-contact-rating-cnt {
  @pale-sea: #ebf4f7;
  @feedback_bg: @pale-sea;
  @feedback_border-color: @pale-sea;

  h3 {
    font-size: 16px !important;
  }

  label[for="feedbackMessage"] {
    font-size: 15px;
    line-height: 1.23;
  }

  .sol-feedback {
    &__error {
      background-color: #ebf4f7;
      padding: 0 20px 15px;
      h3 {
        background-color: #fff;
        border-radius: 5px;
        padding: 15px 30px 21px 17px;
      }
    }
    &__thanks {
      &__wrapper {
        background-color: @pale-sea;
        padding: 0 20px 15px;
        @media @tablet {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
      background-color: @white;
      border-radius: 5px;
      padding: 15px 30px 21px 17px;
      h3 {
        font-size: 16px;
        margin-top: 0;
        & + span {
          @media @phone {
            font-size: 15px;
          }
        }
      }
    }
  }
  .sol-feedback__container {
    background-color: @feedback_bg;
    border-color: @feedback_border-color;
    padding-top: 0;
  }


  .sol-feedback__form {
    background-color: @white;
    padding: 12px 15px 29px;
    border-radius: 5px;
    &__info {
      margin-top: 10px;
      font-size: 15px;
      line-height: 1.23;
    }
    label {
      display: block;
    }
    textarea {
      background-color: @white;
    }
  }
}

.sv-rating-portlet {
  .thank-you {
    display: flex !important;
    align-items: center;
    background-color: @pale-sea;
    width: 490px;
    height: 45px;
    padding: 12px 32px;
    border-radius: 5px;
    color: @black;
    font-size: 16px;
    font-weight: bold;
  }
  form {
    display: flex;
    align-items: center;
    background-color: @pale-sea;
    width: 100%;
    height: 45px;
    padding: 12px 25px;
    border-radius: 5px;
    @media @tablet-strict {
      width: 100%;
    }
    @media @phone {
      width: 100%;
      height: 70px;
      justify-content: space-between;
    }
    .sol-feedback-title {
      color: @black;
      font-size: 16px;
      font-weight: bold;
      text-align: left;
      margin-right: 34px;
      line-height: 1.4;
      @media @phone {
        margin-right: 0;
        font-size: 14px;
        font-weight: bold;
      }
      & + div {
        display: inline-flex;
      }
    }

    > div {
      @media @phone {
        flex-basis: 150px;
        text-align: right;
      }
    }
    input[type="button"] {
      .inline-flex-centered();
      border-radius: 4px;
      border-color: transparent;
      outline: none;
      width: 45px;
      height: 35px;
      font-size: 13px;
      font-weight: bold;
      text-align: center;
      color: #3b3b3b;
      background: @white;
      cursor: pointer;
      &[value="Ja"] {
        margin-right: 11px;
      }
    }
  }
} 
