.sol-form-control--file {
  &__file-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
  }
  &__file-item {
    background-color: @pale-sea;
    padding: 5px 10px;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    &__name {
      font-size: 14px;
      margin-right:5px;
    }
    &__size {
      font-size: 12px;
      font-weight: bold;
      margin-right: 10px;
    }
  }
  &__remove-btn {
    cursor: pointer;
  }
}

.sv-dbform-portlet {
  form {
    padding-top: 20px;
  }
  > div:nth-child(n+1) {
      border-radius:5px 5px 0 0;
      margin-right: 1px;
    a {
      text-decoration: none;
    }
  }
}

.sv-formField {
    table {
        max-width: 100%;
        width:100%;
        tbody {
            td {
              border-right:none;
                select,input {
                    margin:0;
                    margin-top:5px;
                    margin-bottom:5px;
                }
            }
            th {
              label {
                padding:0;
                margin:10px 0;
              }
            }
        }
    }
}

.sv-signup-portlet {
    .font-normal {
        font-size:14px;
    }
}


.sol-form,
.sol-form-decoration, .sv-form-portlet, .sv-dbform-portlet, .sv-signup-portlet {
  border: solid 1px #ececec;
  border-radius: 3px;
  padding: 20px 20px 40px;
  background-color: @form-bg;
  margin-bottom: 20px;
  @media @tablet {
    padding: 20px;
  }
  @media @phone {
    padding-right: 10px;
    padding-left: 10px;
  }


  & h2 {
    margin-top: 0;
  }

  & form {
    & input[type=email],
    & input[type=number],
    & input[type=password],
    & input[type=tel],
    & input[type=text],
    & input[type=url],
    select,
    textarea {
      .placeholder( { color: @form-placeholder; } );
      .no-outline-focus;
      display: block;
      -webkit-appearance: none;
      -moz-appearance: none;
      width: 100%;
      padding: 8px 15px;
      font-size: 14px;
      font-family: @main-font;
      font-weight: 300;
      color: @form-input-text;
      vertical-align: middle;
      height: 40px;
      border: 1px solid @form-input-border;
      border-radius: 2px;
      background-color: @form-input-bg;
      .transition(~  'border, background-color, box-shadow');
      box-shadow: none;
      background-size: 20px;
      background-repeat: no-repeat;
      background-position: 99% 8px;
      background-position: right 10px top 8px;
      &:focus,
      &:hover {
        background-color: @form-input-focus-bg;
        border-color: @form-input-focus-border;
      }
      &:hover {
        border-color: @form-input-hover-border;
      }
      &:invalid {
        //border-color: @form-red-2;
        //background-color: @form-red-3;
        background-image: url(/webdav/files/System/resources/graphics/icons/Stang.svg);
      }
      &:required:valid {
        //border-color: @form-green-2;
        //background-color: @form-green-3;
        background-image: url('@{sol-graphics-path}/icons/check_valid.svg');
      }

      & ~ span.font-normal {
        margin-top: -15px;
        margin-bottom: 20px;
        font-size: 15px;
      }
    }

    & textarea {
      height: auto;
    }

    select {
      cursor: pointer;
      background-image: url('@{sol-graphics-path}/icons/chevron-down.svg');
      background-size: 13px;
      background-position: right 15px top 12px;
    }


    & .sol-form-field {
      margin-bottom: 10px;
    }
    & .sv-formField {
      float: none;

      span.font-normal {
        font-size: 15px;
        margin-bottom: 5px;
      }

      .sv-form-consent {
        padding-left: 0;
      }
    }

    & .sol-label-container,
    & .sv-field-title-container,
    & .sv-login-input-group {
      & > label {
        display: block;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 5px;
      }
      & > span {
        display: block;
        margin-bottom: 12px;
        font-size: 14px;
        font-weight: 700;
      }
    }

    .sol-form-control--file__label {
      .sol-btn--sea;
      display: inline-block !important;
      margin-top: 20px;
    }

    & .sv-form-datefield {
      & > div {
        width: 50%;
        margin: 0 !important;
        &:nth-of-type(1) {
          padding-right: 5px;
        }
        &:nth-of-type(2) {
          padding-left: 5px;
        }

        & select {
          min-width: 100%;
          margin-bottom: 10px;
        }
      }
    }

    & br {
      display: none;
    }

    .font-normal {
      display: block;
    }

    & .sv-formField {
      margin-bottom: 0;
      @media @phone {
        width: 100%;
        padding: 0;
      }
    }

    & input[type=checkbox],
    & input[type=radio] {
      display: none;
      & + label:before {
        content: "";
        box-sizing: border-box;
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 2px solid @form-input-chk-empty-border;
        border-radius: 3px;
        position: relative;
        top: -1px;
        margin-right: 10px;
        float: left;
        text-align: center;
        position: absolute;
        top: -1px;
        left: 0;
        .transition(~  'color, background-color, border-color, border-width');
      }

      & + label {
        display: block;
        font-size: 14px;
        line-height: 1.3em;
        font-weight: 300;
        margin-bottom: 10px;
        cursor: pointer;
        position: relative;
        padding-left: 30px; 
      }
    }

    & input[type=checkbox] {
      &:checked + label:before {
        .icon(@fa-var-check, 12px);
        padding-top: 2px;
        background-color: @form-input-chk-bg;
        border-color: @form-input-chk-border;
        color: @form-input-chk-text;
      }
    }

    & input[type=radio] {
      & + label:before {
        border-radius: 50%;
      }
      &:checked + label:before {
        border-width: 4px;
        border-color: @form-input-chk-border;
      }
    }

    & button[type=submit],
    & input[type=submit] {
      .sol-btn;
      margin-top: 20px;
      padding-left: 25px;
      padding-right: 25px;

      & > i.fa {
        margin-right: 11px;
        margin-left: -15px;
      }
    }
    input[type="file"] {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
      & + label {
        display: flex;
        align-items: center;
        font-size: 12px;
        &:extend(.sol-btn);
        padding: 5px 13px 5px 9px !important;
        &:hover {
          &:extend(.sol-btn:hover);
        }
        &:active {
          &:extend(.sol-btn:active);
        }
        &:before {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          content: "\f067";
          font-family: fontAwesome;
          width: 30px;
          height: 30px;
          color: @ms-btn-bg;
          background-color: @white;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
    }

    & .sol-form-divider {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px dashed @form-divider;
    }
  }

  .sol-collapsible-decoration & {
    padding: 0;
    border: none;
  }
}

#berakningskalkyl-table {
  td {
    padding: 10px 20px;
  }
}

.sol-kalkyl-scrollbar {
  overflow: auto;
  position: relative;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: rgba(0,0,0,.5);
    box-shadow: 0 0 1px rgba(255,255,255,.5);
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(0,0,0,.1);
  }

  &--inner {
    width: 752px;
    height: 20px;
  }
}

.sol-form-with-table {
  padding: 0;
  padding-bottom: 20px;
  border: none;
  overflow: auto;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: rgba(0,0,0,.5);
    box-shadow: 0 0 1px rgba(255,255,255,.5);
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(0,0,0,.1);
  }

  &::-webkit-scroll {
    position: absolute;
    top: 0;
  }

  & table {
    width: 100%;
    margin: 20px 0;
    & tr {
      height: 49px;
    }
  }

  & input[type=text] {
    background-color: #fff !important;
    display: inline-block !important;
    width: auto !important;
  }

  & label {
    display: inline-block !important;;
    margin-bottom: inherit !important;
    margin-right: 10px;

    &::before {
      margin-right: 5px !important;
    }
  }

  & input[type=button], button {
    cursor: pointer;
    background-color: @button-bg;
    border: 2px solid @button-bg;
    box-shadow: none;
    border-radius: 5px;
    padding: 7px 34px;
    color: @button-text;
    text-decoration: none !important;
    font-weight: 600;
    letter-spacing: 0.2px;
    text-align: center;
    transition: all 0.2s ease;

    &:hover {
      background-color: @button-bg-hover;
      color: @button-text-hover;
      border: 2px solid @button-border-color-hover;
    }
  }


}


