.sol-landingpage-wrapper {
  .sol-landingpage__top {
    &--lvl3 {
      padding: 35px 36.2% 30px 11.5%;
      @media @phone {
        padding-left: 4.2%;
        padding-right: 4.2%;
      }
    }
  }
  .sol-beige-container {
    &--lvl2, &--lvl3 {
      background-color: @beige;
      padding: 30px;
      margin-left: auto;
      margin-right: auto;
      @media @tablet {
        padding: 0;
      }
      @media @phone {
        box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
      }
    }
    &--lvl2 {
      padding-top: 38px;
      padding-bottom: 38px;
      @media @tablet {
        padding-left: 2.6%;
        padding-right: 2.6%;
      }
      @media @phone {
        padding-left: 4.2%;
        padding-right: 4.2%;
      }
    }
    &--lvl3 {
      padding: 0;
    }
  }

  .sol-related, .sol-related-info__title {
    max-width: none !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
    .sol-link-tablet {
      width: 650px;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 25px;
      @media @tablet-strict {
        width: 77%;
      }
      @media @tablet {
        margin-bottom: 0;
      }
      @media @phone {
        width: 92%;
      }
    }
}
