.sol-searchpage-wrapper {
  img[src*="fa-file-o_40.png"] {
    display: none;
  }
}
.sv-facetedsearch-portlet {
  background-color: @beige;
  padding-top: 50px;
  @media @phone {
    padding-top: 20px;
  }
  .sol-search-hit-path {
    color: #0e3c5d;
    margin-top: 10px;
      > a.sv-vamiddle {
        font-size: 13px !important;
        font-weight: normal;
        text-align: left;
        color: @downriver;
        text-decoration: underline;
      }
  }
  h1.font-heading-1 {
    max-width: 700px;
    margin-right: auto;
    margin-bottom: 20px;
    margin-left: auto;
  }
  p.font-normal {
    max-width: 95%;
  }
  p.sol-search-hits-num, .sol-search-didyoumean {
    font-size: 13px;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 95%;
    @media @phone {
      margin-left: auto;
      margin-right: auto;
    }
  }
  .sv-search-result, ul, ol {
    list-style: none;
    width: 750px;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 0 !important;
    @media @tablet {
      padding-left: 38px;
    }
    @media @phone {
      width: 92%;
      padding-left: 0;
    }
  }
  .sv-search-hit {
    border-bottom: 1px solid #ececec;
    padding-bottom: 20px !important;
    padding-top: 13px !important;
    padding-left: 38px !important;
    @media @phone {
      padding-left: 0 !important;
    }
    img {
      width: 18px;
      margin-right: 6px;
    }
    .sv-vamiddle {
      font-size: 18px !important;
    }
  }
  .sol-search-hit__date-size {
    margin-bottom: 5px;
    > p {
      font-size: 13px;
      font-weight: 600;
      color: @gray-3;
      margin: 0 !important;
    }
  }
  .sol-search-hit__summary {
    font-size: 18px !important;
     line-height: 1.33 !important;
     font-weight: normal;
     text-align: left;
     color: #3b3b3b;
     margin-top: 5px;
     margin-bottom: 0;
  }
  .sol-white-cnt {
    background-color: @white;
  }
  > form {
    height: 45px;
    width: 700px;
    @media @phone {
      width: 90%;
      margin-left: 2.5%;
    }
    margin-left: auto;
    margin-right: auto;
    position: relative;
    input[type="search"] {
      .no-appearance;
      .placeholder( { color: @gray-3; } );
      width: 100%;
      height: 45px;
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 30px;
      font-size: 20px;
      font-weight: 300;
      line-height: 0.8;
      margin-bottom: 0;
      background-color: @white;
      border: 2px solid @search-top-field-border;

      &:hover,
      &:focus {
        border-color: @search-top-field-border-hover;
      }
    }
    button[type="submit"] {
      position: absolute;
      top: 50%;
      right: -15px;
      transform: translateY(-50%);
      background: none;
      transition-property: background-color;
      transition-duration: 200ms;
      transition-timing-function: ease;
      position: absolute;
      width: 53px;
      height: 53px;
      border-radius: 50%;
      background-color: #0e3c5d;
      color: #fff;
      font-size: 25px;
      text-align: center;
      text-decoration: none;
      border: none;
      box-shadow: none;
      cursor: pointer;
      padding-left: 0;
      padding-right: 0;
    }
  }
  .sol-pagination {
    text-align: center;
    background-color: @white;
    .pagination {
    margin-left: auto;
    margin-right: auto;
    width: 700px;
    @media @phone {
      width: 95%;
    }
      .sol-clearfix;
      padding-top: 20px;
      & a,
      & span.current {
        width: 30px;
        height: 30px;
        margin-top: 0;
        margin-right: 2px;
        margin-left: 2px;
        padding: 4px 0 0;
        border-radius: 100%;
        background-color: @pagination-bg;
        box-shadow: none;
        color: @pagination-text;
        font-size: 13px;
        font-weight: 400;
        text-align: center;
        border: none;
        text-decoration: none;
        line-height: 22px;

        &:hover {
          background-color: @pagination-hover-bg;
        }
      }

      & span.current,
      & span.current:hover {
        font-weight: 700;
        background-color: @pagination-current-bg;
        color: @pagination-current-text;

        & strong {
          font-weight: 300;
        }
      }

      & > *:first-child:not(span),
      & > *:last-child:not(span) {
        border: none;
        width: auto;
        height: auto;
        border-radius: 0;
        font-size: 14px;
        padding: 4px 0 0;
        text-decoration: none;
        margin-left: 15px;
        margin-right: 15px;
        color: @ms-color-1;
        &:hover {
          background-color: @pagination-bg;
          color: @pagination-current-bg;
        }
      }

      & span.current.next,
      & span.current.prev {
        display: none;
      }
    }
  }
}
.sol-results-facets-cnt {
  margin-left: auto;
  margin-right: auto;
  background-color: @beige;
  > p {
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    background-color: @beige;
  }
  > ul {
    > li:last-child {
      margin-bottom: 0;
    }
    padding-bottom: 1rem;
  }
}
.sv-search-facets {
  float: none !important;
  display: flex;
  justify-content: space-between;
  padding: 0 !important;
  padding-bottom: 20px !important;
  width: 700px;
  margin-left: auto;
  margin-right: auto;
  @media @phone {
    width: 95%;
    flex-wrap: wrap;
  }
}
.sol-search-facet {
  position: relative;
  flex-basis: 49%;
  @media @phone {
    flex-basis: 100%;
    &:first-child {
      margin-bottom: 15px;
    }
  }
  &__top {
    height: 50px;
    background-color: @white;
    display: flex;
    border-left: 5px solid @ms-filter-top-border-color;
    .sol-search-facet__name {
      color: @ms-filter__top-text-color;
      font-size: 14px;
      font-weight: bold;
      flex-basis: 75%;
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }
    .sol-search-facet__button {
      .focus-outline;

      background: none;
      border: none;
      outline: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 15px;
      padding-left: 20px;
      cursor: pointer;
      width: 100%;
      .fa-chevron-right {
        background-color: @white;
        border: 2px solid @ms-filter__arow-border-color;
        color: @ms-filter__arow-color;
        width: 31px;
        height: 31px;
        border-radius: 50% !important;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all .2s ease-in-out;
      }
    }
  }
  .sol-search-facet-items {
    background-color: @white;
    display: none;
    position: absolute;
    top: 50px;
    left: 50%;
    width: 90%;
    z-index: 9999;
    transform: translateX(-50%);
    padding: 11px 12px 17px;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
    .sol-search-facet-item {
      margin-bottom: 15px;
      margin-right: 15px;
      position: relative;
      padding: 0;
      display: inline-block;
      line-height: 1;
      text-decoration: none;
      border: none;
      font-size: 13px;
      font-weight: bold;
      letter-spacing: 0.2px;
      text-align: center;
      text-decoration: none;
      color: #3b3b3b;
      border-radius: 5px;
      border: 2px solid @ms-color-1;
      &--not-selected {
        padding: 9px;
      }
      a {
        padding: 9px;
        padding-right: 45px;
        display: block;
        width: 100%;
        height: 100%;
        line-height: 20px;
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 0.2px;
        text-align: center;
        text-decoration: none;
        color: #3b3b3b;
      }

      .sol-search-facet__results-count {
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 0.2px;
        position: absolute;
        line-height: 20px;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
      }
    }
  }
  .sv-search-facet-item {
    border: 2px solid @ms-color-1;
    &--selected {
      background-color: @ms-color-1;
      color: @white;
      > a {
        color: @white !important;
      }

      .sol-search-facet__results-count {
          color: @white !important;
      }
    }
  }
  .sol-action {
    &:extend(.sol-btn);
    &:hover {
      &:extend(.sol-btn:hover);
    }
    &:active {
      &:extend(.sol-btn:active);
    }
    border-radius: none;
  }
  &.expanded {
    .sol-search-facet__top {
      background-color: @ms-filter__top-bg--active;
      .sol-search-facet__name {
        color: @ms-filter__top-text-color--active;
      }
      .sol-search-facet__button {
        .fa-chevron-right {
          transform: rotate(90deg);
        }
      }
    }
  }
}
.sol-facett-title {
  background-color: @ms-color-1;
  color: @white;
  display: block;
  padding-left: 11px;
  padding-top: 11px;
  padding-bottom: 11px;
  font-size: 14px;
  font-weight: bold;
  border-left: 5px solid tint(@ms-color-1, 40%);
}

.sol-search-top {
  background-color: @beige;
  margin-left: auto;
  margin-right: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  > div {
    width: 100%;
    max-width: 700px;
  }
  input[type="text"] {
    width: 726.5px;
    max-width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 31px;
    font-size: 20px;
    font-weight: 300;
    line-height: 0.8;
  }
}
