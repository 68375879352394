// BREAKPOINTS
@xs-min: 0px;
@sm-min: 480px;
@md-min: 768px;
@lg-min: 992px;
@xs-max: (@sm-min - 1);
@sm-max: (@md-min - 1);
@md-max: (@lg-min - 1);
@phone-sm: ~"(max-width: @{xs-max})";
@phone: ~"(max-width: @{sm-max})";
@phone-strict: ~"(min-width: @{sm-min}) and (max-width: @{sm-max})";
@tablet: ~"(max-width: @{md-max})";
@tablet-strict: ~"(min-width: @{md-min}) and (max-width: @{md-max})";
@desktop: ~"(min-width: @{lg-min})";

//COLUMN SIZE
@col-size: 12;

.generate-columns(@n, @selector, @i: 1) when (@i =< @n) {
  @{selector}-@{i} {
    width: (@i * 100% / @n) !important;
  }
  .generate-columns(@n, @selector, (@i + 1));
}

.sol-row(@prefix){
	*.@{prefix}-row{
	  .flex-display;
	  .flex-direction;
	  .flex-wrap;
	  margin-left: -5px;
	  .justify-content(center);
	  &.sol-row-reverse{
	  	.flex-direction(row-reverse);
	  }
	  & > *[class*='@{prefix}-col'] {
	    margin-bottom: 0 !important;
	    margin-left: 0 !important;
	    padding-left: 5px;
	    padding-right: 5px;
	    float: left !important;
	  }
	  & {
	    .generate-columns(@col-size, ~'> *.@{prefix}-col');
	  }
  }
}
.sv-grid-main.sv-fluid-grid {
	@media @tablet-strict {
  	.sol-row(sol-md);
  }
	@media @phone-strict {
   .sol-row(sol-sm);
	}
}

.sol-hide-tablet{
  @media @tablet{
    display: none;
  }
}

.sol-hide-phone{
  @media @phone{
    display: none;
  }
}

.sol-hide-phone-sm{
  @media @phone-sm{
    display: none;
  }
}

.sol-desktop-strict {
  @media @tablet {
    display: none;
  }
}
.sol-tablet-strict {
  display: none;
  @media @tablet-strict {
    display: block;
  }
}

.sol-phone {
  display: none !important;
  @media @phone {
    display: block !important;
  }
}
.visible-lg, .visible-md, .visible-sm, .visible-xs {
  display: none !important;
}
