.sol-uvk {
  .sol-event-search__filter-n-categories {
    display: flex;
    justify-content: space-between;
    table {
      width: 400px;
      max-width: 80vw;
      .fa {
        color: @white;
      }
      .uib-title {
        strong {
          color: @white;
        }
      }
      thead {
        th {
          color: @white;
          text-align: center;
        }
      }
    }
    @media @phone {
      flex-direction: column;
    }
    > div {
      @media @tablet-strict {
        flex-basis: 46%;
      }
      @media @phone {
        flex-basis: 100%;
        width: 100%;
        margin-bottom: 15px;
      }
    }
   .sol-event-search__filter, .sol-event-search__categories {
     position: relative;
     width: 360px;
     @media @tablet {
       width: 100%;
     }
     &__header {
       webkit-appearance: none;
       -moz-appearance: none;
       appearance: none;
       background: 0;
       border: 0;
       border-left: 5px solid #6e8a9e;
       background-color: #fff;
       padding: 8px 18px 11px;
       display: flex;
       align-items: center;
       width: 100%;
       height:40px;
       text-align: left;
       position: relative;
       cursor: pointer;
       line-height: normal;
       box-shadow: 0 0 20px 0 rgba(0,0,0,0.05);
       transition: all .3s ease;
       &__btn {
         .flex-centered;
         width: 32px;
         height: 32px;
         border-radius: 50%;
         border: 2px solid #6e8a9e;
         position: absolute;
         top:50%;
         right:15px;
         -webkit-transform: translateY(-50%);
         -moz-transform: translateY(-50%);
         -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
         transform: translateY(-50%);
         .fa {
           transition: all .2s ease;
         }
       }
     }
     &__content {
       background-color: @white;
       width: 95%;
       position: absolute;
       top: 40px;
       z-index: 2;
       left:50%;
       -webkit-transform: translatex(-50%);
       -moz-transform: translatex(-50%);
       -ms-transform: translatex(-50%);
       -o-transform: translatex(-50%);
       transform: translatex(-50%);
       transition: max-height .2s ease-in-out;
       max-height: 0;
       overflow: hidden;
       box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
       > .sol-category {
         &:first-child {
           margin-top: 13px;
         }
       }
       form {
         display: flex;
         flex-wrap: wrap;
         margin: 13px;
         .sol-form-row {
           flex-basis:100%;
           max-width:100%;
           display: flex;
           flex-direction: column;
           margin-bottom: 15px;
           label {
             font-size: 14px;
             font-weight: bold;
             line-height: 1;
             color: @black;
             margin-bottom: 11px;
           }
           input {
             background-color: @beige;
             border: solid 1px #ececec;
             border-radius: 2px;
             height: 40px;
             padding-left: 15px;
             padding-right: 15px;
             &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
               font-size: 15px;
               color: @gray-3;
             }
             &::-moz-placeholder { /* Firefox 19+ */
               font-size: 15px;
               color: @gray-3;
             }
             &:-ms-input-placeholder { /* IE 10+ */
               font-size: 15px;
               color: @gray-3;
             }
             &:-moz-placeholder { /* Firefox 18- */
               font-size: 15px;
               color: @gray-3;
             }
             &#filterStartDate, #filterEndDate {
               position: relative;;
             }
           }
           &:first-child {
             flex-basis:100%;
             max-width:100%;
           }
         }
         .sol-form-btn {
           &__container {
             flex-basis:100%;
             width:100%;
             .sol-btn {
               width:100%;
             }
           }
         }

       }

       &__dates {
         display: flex;
         justify-content:space-between;
         flex-direction: row !important;
         > div {
           display: inline-flex;
           flex-basis: 47%;
           max-width: 47%;
           flex-direction: column;
         }
       }

     }
     &--collapsed {
       .sol-event-search__filter__content, .sol-event-search__categories__content {
         max-height: 1000px;
       }
       .sol-event-search__filter__header, .sol-event-search__categories__header {
         background-color: @downriver;
         color: @white;
         &__btn {
           background-color: @white;
           .fa {
             transform: rotate(90deg);
           }
         }
       }
     }
   }
  }
}
.sol-event-search__categories__content {
  input[type=checkbox],
  & input[type=radio] {
    display: none;
    & + label:before {
      content: "";
      box-sizing: border-box;
      display: inline-block;
      width: 20px;
      height: 20px;
      border: 2px solid @form-input-chk-empty-border;
      border-radius: 3px;
      position: relative;
      top: -1px;
      margin-right: 10px;
      float: left;
      text-align: center;
      .transition(~  'color, background-color, border-color, border-width');
    }

    & + label {
      display: block;
      font-weight: 400;
      margin-bottom: 10px;
      cursor: pointer;
      font-size: 16px;
      line-height: 1.38;
      color: #3b3b3b;
      span {
        font-size: 16px;
        line-height: 1.38;
        color: #969696;
      }
    }
  }

  & input[type=checkbox] {
    &:checked + label:before {
      .icon(@fa-var-check, 12px);
      padding-top: 2px;
      background-color: @form-input-chk-bg;
      border-color: @form-input-chk-border;
      color: @form-input-chk-text;
    }
  }

  & input[type=radio] {
    & + label:before {
      border-radius: 50%;
    }
    &:checked + label:before {
      border-width: 4px;
      border-color: @form-input-chk-border;
    }
  }
}

.sol-event-search__categories__content {
  padding: 0 13px;
}
