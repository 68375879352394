.sol-event-registration-page-wrapper {
  .sol-event-registration {
    width: 52%;
    margin-left: auto;
    margin-right: auto;
    @media @tablet-strict {
      width: 80%;
    }
    @media @phone {
      width: 100%;
      padding-top: 15px;
    }
    &__success-msg {
      background-color: @white;
      padding: 15px;
      border: 2px solid transparent;
      border-radius: 5px;
    }
  }
  button[type="submit"] {
    background-color: @downriver !important;
    border-color: @downriver !important;
    &:hover {
      background-color: #EBF4F7 !important;
      border: 2px solid rgba(14, 60, 93, 0.2) !important;
      color: @black !important;
    }
    @media @phone {
      width: 100%;
    }
  }
  @media @phone {
    background-color: @white;
    padding-bottom: 0;
  }
    .sol-form-header {
      font-size: 22px;
      font-weight: bold;
      line-height: 1.45;
      color: #3b3b3b
    }
  .datetimepicker .active.active {
    background-color: @downriver !important;
    background-image: none;
  }
  .hidden-text-input {
    display: block !important;
    height: 0 !important;
    opacity: 0 !important;
    min-height: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
}
  h1 {
    width: 52%;
    margin-left: auto;
    margin-right: auto;
    @media @tablet-strict {
      width: 80%;
    }
    @media @phone {
      width: 100%;
      padding: 0 4.6%;
    }
  }
  .sol-form-decoration {
    padding: 0;
    background: transparent;
  }
  textarea {
    height: 150px !important;
  }
  label {
    margin-bottom: 12px;
    display: block;
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    color: #3b3b3b;
  }
input {
  background-color: @beige !important;
  padding: 10px 15px !important;
  border: 1px solid #ececec !important;
   border-radius: 2px;
  &[type="file"] {
    display: block;
    height: 0;
    opacity: 0;
  }

}
  .sol-form-btn  {
    .sol-btn;
    padding: 9px;
    background-color: @sea;
    border-color: @sea;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.47;
  }
  .sol-remove-time {
    display: flex;
    align-items: center;
    justify-content: center;
    i.fa {
      margin-left: 0;
      margin-right: 0;
    }
  }
  background-color: @beige;
  padding-top: 45px;
  padding-bottom: 85px;
  .sol-event-registration-form  {
    background-color: @white;
    padding: 31px 4.6% 45px;
  }
  .sol-form-row {
    flex-direction: column;
    padding-bottom: 25px;
    @media @phone {
      display: block !important;
    }
  }
  .sol-organizer-info, .sol-registrar-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media @phone {
      flex-direction: column;
    }
    .sol-form-group {
      flex-basis: 30%;
      max-width: 30%;
      @media @phone {
        flex-basis: 100%;
        max-width: 100%;
      }
      &:not(:last-child) {
        @media @phone {
          margin-bottom: 25px;
        }
      }
    }
  }
  .sol-registrar-info {
    @media @phone {
      border: none;
      margin-bottom: 0 !important;
      padding-bottom: 0;
    }
    > div {
      @media @phone {
        flex-basis: 100%;
        max-width: 100%;
      }
      &:not(:last-child) {
        @media @phone {
          margin-bottom: 25px;
        }
      }
    }
  }
  .sol-form-row-divider {
    border-bottom: solid 1px rgba(51,51,51,0.08);
    margin-bottom: 21px;
    label[for="eventImg"] {
      background-color: transparent !important;
      border: none !important;
      box-shadow: none !important;
      border-radius: 0 ;
      padding: 0 !important;
      margin-bottom: 0 !important;
      color: #fff;
      text-decoration: none!important;
      font-weight: 600;
      letter-spacing: .2px;
      text-align: center;
      transition: all .2s ease;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .sol-form-file-label {
        text-align: left;
      }
      &:before {
        display: none !important;
      }
      .sol-form-file-label {
        flex-basis: 100%;
        max-width:100%;
      }
      .sol-form-file-btn {
        margin-bottom: 0;
      }
    }
  }

  .sol-add-time , .sol-form-file-btn {
    margin-bottom: 20px;
    padding: 5px 27px 5px 9px;
    display: inline-flex;
    align-items: center;
  }
  .sol-form-check {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
    .sol-form-row-small {
      flex-basis: 33.333333%;
      padding-right: 10px;
    }
  }
  .sol-form-radio {
    display: flex;
    > div {
      margin-right: 54px;
    }
  }
  .sol-form-file-label {
    display: block;
    margin-bottom: 15px;
  }
  .sol-form-file-text {
    margin-left: 18px;
  }
  .sol-datetime-input-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    @media @phone {
      flex-direction: column;
    }
    > div {
      flex-basis: 47%;
      margin-bottom: 20px;
      position: relative;
      input {
        width: 100%;
      }
    }
    .sol-datetime-input {
      border-radius: 2px;
      background-color: #ffffff;
      border: solid 1px #e1e1e1;
      font-family: MuseoSans;
      font-size: 15px;
      letter-spacing: .3px;
      padding: 9px 18px 8px;
      width: 100%;
      margin-bottom: 20px;
      min-height: 40px;
      width: 90%;
      margin-bottom: 0;
      .sol-datetime-input-date {
        margin-right: 10px;
      }
      i.fa {
        margin-right: 8px;
      }
      }
      .datetimepicker {
        border-radius: 4px;
        direction: ltr;
        display: block;
        margin-top: 1px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        width: 320px;
        table {
          width: 100%;
          th {
            border: 0;
            border-radius: 4px;
            height: 20px;
            text-align: center;
          }
          thead tr {
            color: @white;
            font-size: 14px;
          }
          td {
            border: 0;
            border-radius: 4px;
            height: 20px;
            text-align: center;
          }
        }
      }
  }
}

.sol-plus-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-right:10px;
  border-radius: 50%;
  background: @white;
  background-image: url(/webdav/files/System/resources/graphics/icons/Plus.svg);
  background-size: 15px 15px;
  background-repeat: no-repeat;
  background-position: center center;
}

