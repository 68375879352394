.sol-startpage-wrapper {
  .sol-top {
    position: relative;
  }
  & .sol-startpage-content-top,
  & .sol-startpage-content-bottom {
    & > div:first-child {
      padding-right: 15px;
      @media @tablet {
        padding-right: 10px;
      }
    }
    & > div:last-child {
      padding-left: 15px;
      @media @tablet {
        padding-left: 10px;
      }
    }
  }
  & .sol-startpage-content-bottom {
    margin-top: 35px;
    @media @tablet {
      margin-top: 15px;
    }
    @media @phone{
      margin-top: 0;
    }
    .sol-left {
      h2.font-heading-2 {
        margin-top: 0;
        margin-bottom: 10px;
      }
    }
    .sol-right {
      padding-top: 33px;
      @media @phone{
        padding-top: 0;
      }
    }
  }
}

@import 'top-images';
@import 'popular-pages';
@import 'popular-services';
@import 'contact_box';
@import 'service_message';
@import 'puffar';
@import 'home_page_news';
@import 'events_suggestion_widget';
@import 'temporary-blurb';
@import 'popular-blurb';
