.sol-temporary-blurb {
  background-color: @downriver;
  padding: 10px 20px 5px;

  &__content {
    padding: 10px 20px 15px;
  }

  &__header {
    margin-top: 0;

    a {
      color: @white;
      text-decoration: none;
      font-size: 21px;
    }
  }

  &__desc {
    color: @white;
  }

  &__icon {
    height: 45px;
    background-image: url(/webdav/files/System/resources/graphics/icons/pil-h-vit.svg);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: right center;
    display: block;
    border-top: 1px solid rgba(235, 244, 247, 0.13);
  }

  &:hover .sol-temporary-blurb__header a,
  &:hover .sol-temporary-blurb__desc {
    text-decoration: underline;
  }
}
