.sol-uvk {
  .sol-startpage-wrapper {
    .sol-events-suggestion__cnt {
      display: inline-flex;
      flex-direction: column;
      flex-basis: 48% !important;
      @media @tablet-strict {
        flex-basis: 94% !important;
        .margin-auto;
      }
      @media @phone {
        flex-basis: 100% !important;
      }
      .sol-event-listing-page-btn {
        color: @downriver;
        margin-top: 14px;
        padding-left: 22px;
        .inline-flex-centered;
        text-decoration: none;
        span {
          color: @downriver;
        }
        svg {
          margin-right: 9px;
        }
        &:hover {
          text-decoration: underline;
        }
      }
      .sol-events-widget {
        background-color: @pale-sea;
        opacity: 0.98;
        padding: 38px 8.5% 48px;
        &__top {
          display: flex;
          align-items: center;
          padding-left: 18px;
          padding-bottom: 22px;
          border-bottom: 1px solid tint(@downriver, 80%);
          @media @tablet-strict {
            justify-content: center;
          }
          &__text {
            display: inline-flex;
            flex-direction: column;
            padding-left: 26px;
          }
        }
        .sv-channel {
          padding-left: 22px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          li {
            flex-basis: 100%;
            margin: 0;
            @media @tablet-strict {
              flex-basis: 48%;
            }
          }
        }
        &__title {
          font-size: 21px;
          font-weight: bold;
          text-align: left;
          color: @black;
          margin-top: 0;
          margin-bottom: 8px;
        }
        &__description {
          font-size: 18px;
          text-align: left;
          color: @black;
        }
        .sol-event-item {
          display: flex;
          margin-left: 0;
          margin-right: 0;
          &__title {
            margin-top: 0;
          }
          &__time-n-place {
            font-weight: 400;
          }
          .sol-left {
            width: 50px;
            height: 50px;
            background-color: @downriver;
            border-radius: 5px;
            margin-right: 13px;
            flex-shrink: 0;
          }
          .sol-right {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
          &__start-date {
            .flex-centered;
            flex-direction: column;
            > span {
              color: @white;
              text-transform: uppercase;
              &:first-child {
                font-size: 12px;
                line-height: 1.8;
              }
              &:nth-child(2) {
                font-size: 23px;
                line-height: 1.04;
              }
            }
          }
        }
      }
      .sol-suggestion-widget {
        background-color: @downriver;
        opacity: 0.97;
        @media @tablet {
          margin-top: 18px;
          margin-bottom: 18px;
        }
        > div {
          display: flex;
          align-items: center;
          padding: 43px 0 47px 97px;
          > div {
            &:not(#BlaInnehallsyta) {
              margin-right: 40px;
            }
            a {
              &:hover {
                opacity: 0.7 !important;
                color: @white;
              }
            }
          }
        }
      }
    }
  }
}
