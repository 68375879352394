.sol-uvk {
  .sol-startpage-wrapper {
    .sol-beige-container {
      @media @phone {
        padding: 0;
      }
      > div {
        max-width: 100%;
      }
    }
  }
}


.sol-contact-popular-pages-cnt {
  display: flex;
  width: 94%;
  margin-left: auto;
  margin-right: auto;
  align-items: flex-start;
  @media @tablet {
    flex-wrap: wrap;
    width: 100%;
  }
  .sol-uvk-contact-box {
    flex-basis: 60%;
    @media @tablet {
      flex-basis: 100%;
    }
    > .sv-layout {
      flex-basis: 100%;
    }
    &__left {
      flex-basis: 50%;
      max-width: 50%;
      flex-grow: 0;
    }
    &__right {
      flex-basis: 50%;
      max-width: 50%;
      flex-grow: 0;
    }
  }
}
