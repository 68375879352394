.sol-event-page-wrapper {
  .sol-contact-rating-cnt {
    margin-top: 45px;
  }
  .sol-informationbox {
    @media @phone {
      box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
    }
  }
  .sol-beige-container {
    @media @phone {
      padding: 30px 0 0;
      background-color: transparent;
    }
  }
  .sol-flex {
    width: 52%;
    margin-left: auto;
    margin-right:auto;
    @media @tablet-strict {
      width: 80%;
    }
    @media @phone {
      width: 89%;
    }
  }
  .sol-btn {
    margin-top: 38px;
    margin-left: 72px;
    @media @phone {
      margin-left: auto;
      margin-right: auto;
      width: 90%;
      display: block;
    }
  }
  .sol-information-box {
    &__title {
      display: inline-block;
      margin-bottom: 18px;
      font-size: 31px;
      font-weight: 300;
      &:before {
        content: "\f129";
        display: inline-block;
        font-family: fontAwesome;
        width: 41px;
        height: 41px;
        border-radius: 50%;
        border: 2px solid rgba(14, 60, 93, 0.4);
        margin-right: 18px;
        display: inline-flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        color: #0e3c5d;
      }
    }
    i {
      width: 20px;
      font-size: 20px;
      margin-right: 10px;
      text-align: center;
      color: #0e3c5d;
    }
  }
  padding-top: 35px;
  padding-bottom: 50px;
  &__top {
    width: 52%;
    margin-left: auto;
    margin-right: auto;
    @media @tablet-strict {
      width: 80%;
    }
    @media @phone {
      width: 89%;
    }
  }
}

.sol-event__date-n-time {
  margin-bottom: 8px;
  > span {
    font-size: 16px;
    font-weight: 600;
    line-height: 0.69;
    letter-spacing: 0.3px;
    color: @gray-3;
    min-height: auto;
    margin-left: 0;
    float: none;
    
  }
}
.sol-info-row {
  margin: 6px 0;
  &__title {
    font-size: 17px;
    font-weight: bold;
    line-height: 1.88;
    color: @black;
    margin-right:8px;
  }
  &__details {
    > span {
      word-break: keep-all;
      font-size: 18px;
      line-height: 1.67;

      & i {
        font-size: 15px !important;
        margin-right: 5px !important;
        margin-left: 20px;
      }
    }
    div {
      display: inline-block;
      p {
        margin: 0;
      }
    }
    a {
      
    }
    & .sol-event__price__link {
      margin-left: 15px;
    }
  }
}
