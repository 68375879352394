.sv-standardsearch-portlet {
  .sol-did-you-mean,
  .sol-num-hits {
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 12px;
  }

  .sv-search-hit {
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
    padding: 20px 50px 20px 40px;
    border-radius: 2px;
    background-color: @search-hit-bg;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, .1);

    & .font-heading-3 {
      display: block;
      margin-top: 0;
      margin-bottom: 3px;
      color: @search-hit-text;
      font-size: 15px;
      font-weight: 800;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        color: @search-hit-text-hover;
      }
    }

    & .font-normal {
      font-size: 15px;
      line-height: 1.4em;
    }

    & .sol-hit-info-text {
      font-size: 13px;

      & i.fa {
        color: @search-hit-icon;
        margin-right: 5px;
        margin-left: 10px;

        &:first-child {
          margin-left: 0;
        }
      }

      & > strong {
        margin-left: 10px;
      }

      & > strong:first-of-type {
        margin-left: 0;
      }
    }

    & .sol-hit-path-text {
      font-size: 12px;

      & a {
        color: @search-hit-path;
        text-decoration: none;

        &:hover {
          color: @search-hit-path-hover;
          text-decoration: underline;
        }
      }
    }
  }

  .sol-pagination {
    text-align: center;

    .pagination {
      .sol-clearfix;
      display: inline-block;
      margin-top: 50px;

      & a,
      & span.current {
        width: 30px;
        height: 30px;
        margin-top: 0;
        margin-right: 2px;
        margin-left: 2px;
        padding: 4px 0 0;
        border-radius: 100%;
        background-color: @pagination-bg;
        box-shadow: none;
        color: @pagination-text;
        font-size: 13px;
        font-weight: 400;
        text-align: center;
        border: none;
        text-decoration: none;
        line-height: 22px;
        &:hover {
          background-color: @pagination-hover-bg;
        }
        @media @tablet {
          padding-left: 38px;
        }
      }

      & span.current,
      & span.current:hover {
        font-weight: 700;
        background-color: @pagination-current-bg;
        color: @pagination-current-text;

        & strong {
          font-weight: 300;
        }
      }

      & > *:first-child:not(span),
      & > *:last-child:not(span) {
        border: none;
        width: auto;
        height: auto;
        border-radius: 0;
        font-size: 14px;
        padding: 4px 0 0;
        text-decoration: underline;
        margin-left: 15px;
        margin-right: 15px;

        &:hover {
          background-color: @pagination-bg;
          color: @pagination-current-bg;
        }
      }

      & span.current.next,
      & span.current.prev {
        display: none;
      }
    }
  }
}
