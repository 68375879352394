/*test*/

.sol-submenu.sol-menu-container .sol-box-decoration-header {
    padding-top: 14px;
    padding-bottom: 14px;
}

.sol-submenu.sol-menu-container {
    margin-top: 10px;
}

.sol-uvk-contact-box {
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
    z-index: 2;
}

.sol-startsida-news {
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
    z-index: 2;
}

.sol-uvk .sol-slide-menu-container .sol-menu-item[data-menu-level="1"]>.sol-menu-item-link>.sol-menu-item-display-name {
    font-size: 17px;
    text-align: left;
}

.sol-uvk .sol-slide-menu-container .sol-menu-item[data-menu-level="0"]>.sol-menu-item-link>.sol-menu-item-display-name {
    font-size: 17px;
    font-weight: bold;
    text-align: left;
    color: #fff;
}

.sol-uvk .sol-slide-menu-container .sol-menu-item[data-menu-level="2"][data-expanded=true]>.sol-menu-item-link .sol-menu-item-display-name {
    font-size: 17px;
}


.sol-uvk .sol-slide-menu-container .sol-menu-item[data-menu-level="2"]>.sol-menu-item-link .sol-menu-item-display-name {
    font-size: 17px;
}

.sol-box-decoration-header {
    display: block;
    padding: 12px 15px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: rgba(255, 255, 255, 1);
    text-align: left;
    text-decoration: none;
    line-height: 13px;
    font-weight: 700;
    font-size: 14px;
    margin: 0;
}

.sol-landingpage-wrapper .sol-children-pages--lvl3 .sol-child-page-box__content>p {
    line-height: 1.3em;
    font-size: 19px;
    font-weight: 300;
    margin-top: 5px;
    color: #3b3b3b;
}

.sol-uvk .sol-startpage-wrapper .sol-events-suggestion__cnt .sol-suggestion-widget>div {
    padding: 25px 0 20px 97px;
}


.sol-landingpage-wrapper .sol-children-pages--big .sol-child-page-box__header>span {
    color: #fff;
}


.sv-rating-portlet form input[type="button"]:hover {
    background-color: #0e3c5d;
    color:#fff;
}

.sol-landingpage-wrapper .sol-children-pages--big .sol-child-page-box__link:hover+.sol-child-page-box__content {
    background-color: #ffffff;
}

.sol-icon-email, .sol-icon-mobile {;
    font-size: 24px;
}

.sol-icon-email+a, .sol-icon-mobile+a {
    font-size: 19px;
}


.sol-link-tablet:hover .sol-link-tablet__text {
    text-decoration: underline;
}


.sv-rating-portlet form, .sol-contact-btn {
    margin-top: 15px;
}

.sol-landingpage-wrapper .sol-children-pages--lvl3 .sol-child-page-box:nth-child(odd) {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
}



.sol-collapsible-decoration .sol-collapsible-header, .sol-collapsible-decoration-small .sol-collapsible-header {
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.1);
}

.sol-related-info-container .sol-related-item {
    margin-bottom: 14px;
}

.sol-related-info-container .sol-related-item-name {
    font-size: 15px;
}


.sol-related-info-container .sol-related-info__title {
    margin-bottom: 10px;
}

@media (max-width: 767px){

    .sol-landingpage-wrapper .sol-children-pages--lvl3 .sol-child-page-box__header span:first-child {
        font-size: 15px;
    }

    .sol-landingpage-wrapper .sol-children-pages--lvl3 .sol-child-page-box{
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
    }

    .sol-startpage-wrapper .sol-home-page-top__shortcuts .sol-shortcut:nth-child(odd) {
        border-right: 1px solid rgba(235, 244, 247, 0.1);
    }

}