.sol-social-feed {
    .sol {
        &-fb { 
            &-picture { 
                &-container {
                    width: 30%;
                    float: left;
                }  
            }
        }
        
        &-news { 
            &-content { 
                width: 66%;
                float: left;
                padding-bottom: 20px; 
                margin-left: 20px;

                @media only screen and (max-width: 1026px) and (min-width: 563px) {
                    width: 64%;
                }

                @media only screen and (max-width: 562px) {
                    width: 62%;
                }

                .sol-news-date {
                    i {
                        padding-right: 7px;
                    }
                }

                p {
                    margin-top: 0px;
                }
                
            }
        }

        &-twitter {
            > p {
                color: #3b3b3b;
                font-size: 21px;
                line-height: 1.5em;
                font-weight: 400;
            }
                       
        }
    }

    #sol-social-instafeed {
        list-style: none;
        padding-left: 0;

        a {
            text-decoration: none;
        }

        li {
            padding-bottom: 20px;
            color: #3b3b3b;
            font-size: 21px;
            line-height: 1.5em;
            font-weight: 400;
            height: 250px;
            
            img {
                width: 30%;
                float: left;
            }

            .sol-insta--content {
                width: 66%;
                float: left;
                margin-left: 20px;
                margin-top: -9px;

                @media only screen and (max-width: 1026px) and (min-width: 563px) {
                    width: 64%;
                }
                
                @media only screen and (max-width: 562px) {
                    width: 62%;
                }

                span:first-child {
                    font-size: 16px;
                }

                span {
                    font-family: 'Source Sans Pro',sans-serif !important;
                    color: #3b3b3b;
                    i {
                        padding-right: 7px;
                        font-size: 16px;
                    }
                }
            }
        }
    }

}   

