
.sol-element-action {
  @action__bg: #00823B;
  @action__border: @action__bg;
  @action__bg--hover: #006435;
  @action__border--hover: #006435;
  position: relative;
  display: flex;
  align-items: center;
  width: 543px;
  min-height: 40px;
  overflow: hidden;
  border-radius: 5px;
  background-color: @action__bg;
  border: solid 2px @action__border;
  margin-bottom: 20px;
  transition: all .2s ease;
  &:hover {
    background-color: @action__bg--hover;
    border-color: @action__border--hover;
  }
  &:after {
    content:'';
    min-height:inherit;
    font-size:0;
  }
  @media @phone {
    height: auto;
    min-height: 40px;
    width: auto;
  }
  &__link {
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    color: @white;
    font-weight: bold;
    line-height: 1.5;
    z-index: 2;
    padding-left: 55px;
    width: 100%;
    @media @phone {
      flex-basis: 85%;
    }
  }
  &__text {
    color: @white;
    font-size: 16px;
    font-weight: bold;
    line-height: 2.0;
    text-decoration: none;
  }
  &__icon {
    position: absolute;
    left: 15px;
    top: 4px;
    display: inline-flex;
    align-items: center;
    width: 28px;
    height: 28px;
    margin-right: 12px;
    background-color: @white;
    border-radius: 50%;
    border: solid 2px rgba(255, 255, 255, 0.4);
    i.fa {
      width: 100%;
      text-align: center;
      font-size: 12px;
      color: @downriver;
    }
  }
}

.sol-link-tablet {
  width: 543px;
  height: 40px;
  overflow: hidden;
  border-radius: 5px;
  background-color: @downriver;
  border: solid 2px solid @downriver;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 20px;
  @media @phone {
    max-width: 100%;
    height: auto;
    padding: 10px;
  }
  .sol-link-tablet__link {
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .sol-link-tablet__text {
    color: @white;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.1;
    @media @phone {
      line-height: 1.1;
      max-width: 80%;
      font-size: 16px;
    }
    text-decoration: none;
  }
  .sol-link-tablet__icon {
    display: inline-flex;
    align-items: center;
    width: 28px;
    height: 28px;
    margin-right: 12px;
    background-color: @white;
    border-radius: 50%;
    border: solid 2px rgba(255, 255, 255, 0.4);
    i.fa {
      width: 100%;
      text-align: center;
      font-size: 12px;
      color: @downriver;
    }
  }
}

.sol-deep-rose {
  .sol-element-action, .sol-link-tablet {
    background-color: @deep-rose;
    &__icon {
      i.fa {
        color: @deep-rose !important;
      }
    }
  }
}

.sol-light-olive-green {
  .sol-element-action, .sol-link-tablet {
    background-color: @light-olive-green;
    &__icon {
      i.fa {
        color: @light-olive-green !important;
      }
    }
  }
}

.sol-sea {
  .sol-element-action, .sol-link-tablet {
    background-color: @sea;
    &__icon {
      i.fa {
        color: @sea !important;
      }
    }
  }
}


.sol-color-modifier--blue {
  @action-bg--alt1: @downriver;
  @action-border--alt1: @downriver;
  @action-hover--alt1: lighten(@downriver, 5%);
  .sol-element-action {
    background-color: @action-bg--alt1;
    border-color: @action-border--alt1;
    &:hover {
      background-color: @action-hover--alt1;
      border-color: @action-hover--alt1;
    }
  }
}
