.btn {
  text-decoration: none;
  font-size: 17px;
  .no-appearance;
}
.sol-btn {
  .btn;
  .focus-outline;
  position: relative;
  display: inline-block;
  line-height: 22px;
  padding: 7px 34px;
  color: @button-text;
  background-color: @button-bg;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  //text-transform: uppercase;
  text-align: center;
  transition: all .2s ease;
  font-weight: 600;
  font-size: 14px;
  font-family: @main-font;
  border: none;
  &:hover {
    background-color: @button-bg-hover;
    color: @button-text-hover;
    border: 2px solid @button-border-color-hover;
  }

  p.font-normal {
    margin: 0;
  }
  & i.fa {
    margin-right: 10px;
    margin-left: -5px;
    font-weight: 400;
  }
}

.sol-btn--sea {
  .sol-btn;
  background-color: @sea;
  color: @button-text;
  border: solid 2px @sea;
    &:hover {
    background-color: var(--sea-20);
    border: solid 2px rgba(56, 142, 154, 0.2);
  }
}



.font-btn {
  padding-top: 10px;
  padding-bottom: 10px;

  & > a {
    .sol-btn;
    display: inline;
    padding-bottom: 12px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

.sol-btn-small,
.sv-text-portlet.sol-btn-small {
  .font-btn {
    padding-top: 4px;
    padding-bottom: 5px;
    margin-bottom: 0;

    & > a {
      padding: 7px 25px 8px;
      color: @button-small-text;
      background-color: @button-small-bg;
      box-shadow: none;
      font-size: 11px;
      line-height: 10px;
      font-weight: 600;
      text-transform: none;
      &:hover {
        background-color: @button-small-bg-hover;
        color: @button-small-text-hover;
      }
      &:active {
        top: 0;
        box-shadow: none;
      }
    }
  }
}


.sol-btn-decoration {
  display: inline-block;
  p.font-normal {
    margin: 0;
    a {
      &:extend(.sol-btn);
      &:hover {
        &:extend(.sol-btn:hover);
      }
      &:active {
        &:extend(.sol-btn:active);
      }
    }
  }
}


.sol-hamburger-wrapper, .sol-hamburger-wrapper--desktop, .sol-menu-toggle-button {
  line-height: 1;
  .focus-outline;
  &:extend(.flex-centered);
  flex-direction: column;
  width: 55px;
  border: none;
  text-align: center;
  padding: 0;
  height: auto;
  background-color: transparent;
  z-index: 1000 !important;
  color: @black;
  font-family: @main-font;
  font-size: 14px;
  font-weight: 600;
  @media @phone {
    font-weight: normal;
  }
  text-align: center;
  & .sol-hamburger {
    position: relative;
    cursor: pointer;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-color: @ms-color-1;
    &:extend(.flex-centered);
    flex-direction: column;
    margin-bottom: 5px;
    & > span {
      display: block;
      width: 26px;
      height: 2px;
      border-radius: 2px;
      background-color: #fff;
      &.sol-hamburger-top,
      &.sol-hamburger__top {
        top: 12px;
      }
      &.sol-hamburger-bottom,
      &.sol-hamburger__bottom {
        top: 28px;
      }
      &.sol-hamburger-top, &.sol-hamburger-bottom,
      &.sol-hamburger__top, &.sol-hamburger__bottom {
        position: absolute;
        left: 8px;
        transition-property: top, transform;
        transition-duration: .3s;
        transition-delay: .3s, 0s;
      }
    }


    &.sol-active,
    &.sol-hamburger--active {
      .sol-hamburger-top,
      .sol-hamburger__top {
        transform: rotate(135deg);
      }
      .sol-hamburger-bottom,
      .sol-hamburger__bottom {
        transform: rotate(-135deg);
      }
      .sol-hamburger-middle,
      .sol-hamburger__middle {
        opacity: 0;
      }
      .sol-hamburger-top, .sol-hamburger-bottom,
      .sol-hamburger__top, .sol-hamburger__bottom {
        top: 21px;
        transition-delay: 0s, 0.3s;
      }
    }
  }

  & .sol-hamburger-text {
    color: @black;
    display: block;
    font-family: @main-font;
    font-size: 14px;
    font-weight: 600;
    @media @phone {
      font-weight: normal;
    }
    text-align: center;
  }
}
.sol-contact-btn {
  .sol-btn();
  outline: none;
  border: none;
  min-width: 150px;
  height: 45px;
  padding-left: 24px;
  padding-right: 24px;
  background-color: @pale-sea;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin: 0 33px 0 0;
  cursor: pointer;
  border: 2px solid transparent;
  @media @phone {
    width: 100%;
    justify-content: center;
  }
  &__icon {
    display: inline-flex;
    background-image: url('/webdav/files/System/resources/graphics/icons/Message.svg');
    width: 23px;
    height: 20px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-right: 12px;
  }
  &__text {
    color: @black;
    font-size: 16px;
    font-weight: bold;
  }
}
