.icon-size(@height;
@width: @height) {
  max-height: @height;
  &::after {
    height: @height;
    width: @width;
  }
  &.sol-icon-edge {
    height: @height;
    width: @width;
    &::after {
      transform: scale(0.5);
      height: @height * 2;
      width: @width * 2;
      margin-top: -(@height / 2);
      margin-left: -(@width / 2);
    }
  }
}
.sol-icon {
  display: inline-block;
  &::after {
    content: '';
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    display: inline-block;
    height: 15px;
    width: 15px;
  }
}
.sol-icon--chevron--upp {
  &::after {
    background-image: url('@{sol-graphics-path}/icons/arrow--up--white.svg');
  }
}

.sol-icon--finnish {
  &::after {
    background-image: url('@{sol-graphics-path}/icons/Finland-flagga.svg');
    @media @tablet {
      background-image: url('@{sol-graphics-path}/icons/Finland-flagga.svg');
    }
  }
}

.sol-icon--listen {
  &::after {
    background-image: url('@{sol-graphics-path}/icons/Lyssna.svg');
    @media @tablet {
      background-image: url('@{sol-graphics-path}/icons/Lyssna--white.svg');
    }
  }
}

.sol-icon--lang {
  &::after {
    background-image: url('@{sol-graphics-path}/icons/Sprak.svg');
    @media @tablet {
      background-image: url('@{sol-graphics-path}/icons/Sprak--white.svg');
    }
  }
}

.sol-icon--map {
  &::after {
    background-image: url('@{sol-graphics-path}/icons/Karta.svg');
    @media @tablet {
      background-image: url('@{sol-graphics-path}/icons/Karta--white.svg');
    }
  }
}

.sol-icon--contact {
  &::after {
    background-image: url('@{sol-graphics-path}/icons/Kontakt.svg');
    @media @tablet {
      background-image: url('@{sol-graphics-path}/icons/Kontakt--white.svg');
    }
  }
}

.sol-icon--warning {
  &::after {
    background-image: url('@{sol-graphics-path}/icons/warning--white.svg');
    @media @tablet {
      background-image: url('@{sol-graphics-path}/icons/warning--white.svg');
    }
  }
  &--black {
    &::after {
      background-image: url('@{sol-graphics-path}/icons/warning--black.svg');
      @media @tablet {
        background-image: url('@{sol-graphics-path}/icons/warning--black.svg');
      }
    }
  }
}