.sol-mobile-header {
  display: none;
  text-align: center;
  background-color: @header-top-bg;
  z-index: 999;
  border-bottom: 1px solid @pale-sea;;
  & > div {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 75px;
    > a {
        text-decoration: none;
    }
    &:first-child {
      align-items: center;
      height: 85px;
      padding-right: 14px;
      @media @phone {
        height: 75px;
      }
      > a {
        text-decoration: none;
      }
    }
  }
  &__left {
    @media @tablet-strict {
      position: relative;
      flex-grow: 1;
      height: 100%;
    }
    .sol-overlay {
    }
    .sol-top-logo-text {
        text-decoration: none;
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
      }
      .sol-mobile-logo {
        max-width: 180px;
        height: auto;
        max-height: 31px;
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
      }
  }
  &__right {
    display: flex;
    @media @tablet-strict {
      width: 431px;
      justify-content: flex-end;
    }
  @media @phone {
    justify-content: flex-end;
    /*padding-right: 15px;*/
  }
  .sol-mobile-search-btn {
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-right: 23px;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    &__icon {
      background-color: @ms-color-1;
      border-radius: 50%;
      height: 42px;
      width: 42px;
      background-image: url(/webdav/files/System/resources/graphics/icons/Sok-vit.svg);
      background-size: 20px 19px;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      display: block;
    }
    &__text {
      margin-top: 5px;
      color: @black;
      display: block;
      font-size: 13px;
      text-align: center;
      font-weight: normal;
    }
    &.sol-hidden {
      display: none !important;
    }
  }
  }
  @media @tablet {
    display: block;
  }
  @media @tablet-strict {
    background-color: @header-menu-bg;
  }
  .sol-mobile-search-form {
    &:extend(.sol-top-search-field);
    display: none;
    margin-right: 35px;
    input[type="search"] {
      &:extend(.sol-top-search-field input[type=search]);
      height: 42px;
      width: 95% !important;
    }
    button[type="submit"] {
      &:extend(.sol-top-search-field button[type=submit]);
      position: absolute;
      top: 0;
      height: 42px;
      width: 42px;
    }
    label {
      font-size: 14px;
      text-align: center;
      color: @text-color;
      font-weight: normal;
      text-align: right;
      display: block;
      position: absolute;
      bottom: 0;
      bottom: -5px;
      right: -5px;
    }
    @media @tablet-strict {
      width: 400px;
    }
    @media @phone {
      width: 80vw;
      max-width: 60%;
    }
  }
  @media @phone {
    width: 100%;
  }
}
