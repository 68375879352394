.sol-mini-site {
  .sol-tool-nav {
    @media (min-width: 992px) {
      padding-right: 18.6%;
    }
    .sol-tool-nav-item {
      .sol-top-search {
        .search-icon {
          background-color: @downriver;
        }
      }
    }
  }
}
