  .sol-footer {
    background-color: @downriver;
    .sol-bottom {
      padding-top: 55px;
      padding-bottom: 65px;
      .sv-grid-main {
        padding-left: 0;
        padding-right: 0;
      }
    }
    &-wrapper {
      display: flex;
      justify-content: space-between;
      padding-left: 6.3%;
      padding-right: 10.1%;
      @media @tablet {
        padding-left: 6.5%;
        padding-right: 6.5%;
      }
      .sol-footer-column {
        flex-basis: 33.33%;
        &:last-child {
          @media @tablet {
            display: none !important;
          }
        }
        h4 {
              font-family: 'Source Sans Pro', sans-serif;
              font-size: 18px;
              font-weight: bold;
        }
        span {
          display: block;
        }
        .fa + span {
          display: inline-block;
        }
        a, span, p {
          font-size: 18px;
          line-height: 1.56;
          text-align: left;
          color: #ffffff;
        }
        flex-grow: 1;
        .sol-footer-contact__item {
          &__title {
            font-weight: bold;
          }
          &__link {
            > span {
              text-decoration: underline;
            }
          }
          &:not(:first-child) {
            padding-top: 30px;
          }
          .sol-organisation-num {
            font-weight: 700;
            font-size: 14px;
          }
        }
        h4 {
          margin-top: 0;
        }
        a {
          text-decoration: none;
        }
        .sol-external-link-icon {
          margin-left: 0;
        }
        .sol-footer-adress__item:not(:first-child) {
          padding-top: 25px;
        }
        .sol-social-media {
          display: flex;
        }
        .sol-social-media-item {
          &:not(:last-child) {
            margin-right: 20px;
          }
          > a {
            background: @social-icon-bg;
            .fa {
              color: @social-icon-color;
            }
          }
        }
        &:last-child {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;
          .sol-social-media {
            display: flex;
            justify-content: space-between;
            &:before {
              display: none;
            }
          }
        }
        &__center {
          &__links {
            padding-top: 25px;
            > a {
              display: block;

              > span {
                font-family: 'Source Sans Pro', sans-serif;
                font-size: 18px;
                line-height: 1.56;
                text-align: left;
                color: #ffffff;
                text-decoration: underline;
              }
            }
          }
          &__logo-social {
            display: none;
            @media @tablet {
              display: block;
              svg {
                margin-top: 45px;
              }
            }
          }
        }
        &__right {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          .sol-municipality-logo {
            width: 300px;
            height: 100px;
            display: block;
            background-image: url('http://utv.upplandsvasby.se/images/18.705825cd15bd22fbeb6382/1494511531962/Logotyper/UpplandsVasby_logo_ligg_neg.svg');
            background-repeat: no-repeat;
          }
          @media @tablet {
            display: none;
          }
        }
      }
      @media @phone {
        flex-wrap: wrap;
        .sol-footer-column {
          flex-basis: 100%;
        }
      }
    }
    .sol-bottom {
      background: @ms-color-1;
      * {
        color: #fff;
      }
      h4 {
        display: block;
      }
      .sol-footer-links {
        &
        > * {
          display: block;
        }
      }
    }

  }
  #FooterSkript {
    display: none;
  }
  .sol-footer-center__item__title {
    font-weight:bold;
  }
