//.sol-uvk {
  .sol-article-list-page {
  margin-left: 22%;
  margin-right: 26%;
  padding-top: 35px;
  @media @tablet {
    padding-top: 20px;
    margin-left: 10%;
    margin-right: 10%;
  }
    @media @phone {
      padding-top: 15px;
      margin-left: 5.3%;
      margin-right: 5.3%;
    }
  .sv-channel {
    border-top: 5px solid @pale-sea !important;
    border-bottom: 5px solid @pale-sea !important;;
    padding-left: 30px;
    @media @phone {
      padding-left: 0;
    }
  }
  .sv-archivemenu-portlet {
    @media @tablet{
      width: 100%;
    }
    .sol-news-filter {
      @media @tablet{
        width: 100%;
      }
      .sol-news-filter-button {
        cursor: pointer;
        @media @tablet{
          width: 100%;
        }
      }
    }
  }
  .sol-archive-filter-cnt {
    display: flex;
    border-bottom: 5px solid #ebf4f7;
    margin-bottom: 20px;
    @media @phone {
      flex-direction: column;
    }
    .sol-news-filter-list {
      z-index: 8;
    }
    > div:first-child {
      .sol-news-filter-list {
        z-index: 9;
      }
    }
  }
  .sol-article-item {
    margin-top: 20px;
    margin-bottom: 20px;
      .sol-article-item__content {
        @media @phone {
          width: calc(~'100% - 116px');
        }
      }
    &.sol-no-img {
      .sol-article-item__content {
        @media @phone {
          width: 100%;
        }
      }
    }
  }
  .sol-article-item-date {
    > span {
       font-size: 10px;
       font-weight: 600;
       line-height: 1;
       text-transform: uppercase;
    }
  }
}


.sol-article-wrapper {
  .sol-simple-box-decoration {
    .sol-js-print {
      .fa-print {
        color: @downriver;
      }
      color: @downriver;
    }
  }
}

.sol-subpage-wrapper {
  .sv-archive-portlet {
    .sv-channel-item  {
        border-color: #ececec;
    }
  }
  }
//}

.sol-article-list-page {
  .sv-archivemenu-portlet {
    @media @tablet{
      width: 100%;
    }
    .sol-news-filter {
      @media @tablet{
        width: 100%;
      }
      .sol-news-filter-button {
        cursor: pointer;
        @media @tablet{
          width: 100%;
        }
      }
    }
  }
  .sol-article-item-date {
    > span {
      font-size: 10px;
      font-weight: 600;
      line-height: 1;
      text-transform: uppercase;
    }
  }
}