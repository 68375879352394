.sol-error-page {
  background-color: @error-bg;
  text-align: center;
  &-text-big {
    color: @downriver;
    font-size: 180px;
    line-height: 180px;
    font-weight: 700;
  }

  &-text-small {
    margin-top: 20px;
    margin-bottom: 25px;
    font-size: 34px;
    line-height: 40px;
    font-weight: 300;
  }
  &-btns {
    margin-bottom: 50px;
  }
  & .sol-btn {
    background-color: transparent;
    border-color: transparent;
    color: @black;
    font-size: 19px;
    text-transform: none;
    font-weight: normal;

    &:first-child {
      margin-right: 10px;
    }
  }
}
