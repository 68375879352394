.sol-clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

.sol-relative {
  position: relative;
}

.sol-no-margin {
  margin: 0;
}

.sol-no-margin-top {
  margin-top: 0 !important;
}

.placeholder(@rules) {
  &::-webkit-input-placeholder {
    @rules();
  }
  &:-moz-placeholder {
    @rules();
  }
  &::-moz-placeholder {
    @rules();
  }
  &:-ms-input-placeholder {
    @rules();
  }
}

.no-outline-focus {
  &:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-appearance: none;
    outline: none;
  }
}

.no-webkit-appearance {
  -webkit-appearance: none;
}

.border-top-radius (@radius: 5px) {
  border-top-left-radius: @radius;
  border-top-right-radius: @radius;
}

.border-bottom-radius (@radius: 5px) {
  border-bottom-left-radius: @radius;
  border-bottom-right-radius: @radius;
}

.rotate(@deg) {
  -webkit-transform: rotate(@deg);
  -ms-transform: rotate(@deg);
  transform: rotate(@deg);
}

.transform(@v) {
  -webkit-transform: @v;
  -ms-transform: @v;
  transform: @v;
}

.icon(@icon; @size; @display: inline-block; @position: none) {
  .fa;
  display: @display;
  font-size: @size;
  .position(@position);
  .position(@position) when (@position =before) {
    &::before {
      content: @icon;
    }
  }
  .position(@position) when (@position =after) {
    &::after {
      content: @icon;
    }
  }
  .position(@position) when (@position =none) {
    content: @icon;
  }
}

.transition( @property; @duration: 200ms; @style: ease) {
  -webkit-transition-property: @property;
  transition-property: @property;
  -webkit-transition-duration: @duration;
  transition-duration: @duration;
  -webkit-transition-timing-function: @style;
  transition-timing-function: @style;
}

.no-appearance {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.margin-auto {
  margin-left: auto;
  margin-right: auto;
}
.focus-outline{
  &:focus,
  [data-whatinput="keyboard"] &:focus {
     outline: 2px solid @focus-border;
     z-index: 10;
  }
  [data-whatinput="initial"] &:focus,
  [data-whatinput="mouse"] &:focus,
  [data-whatinput="touch"] &:focus{
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-appearance: none;
    outline: none;
  }
}
.flex-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}
.inline-flex-centered {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

////////////////////////////////// INPUT ////////////////////////////////////

/* elements/input.less */
@form-green-1: 									#66bd2d;
@form-green-2: 									#c7e6b3;
@form-green-3: 									#eef7e8;

@form-red-1: 										#d53636;
@form-red-2: 										#de404e;;
@form-red-3: 										#8acf95;

@downriver: #204560;

@form-placeholder:							@gray-3;
@form-input-text:								@black;
@form-input-border:							rgba(110, 205, 233, 0.4);
@form-input-bg:									@white;
@form-input-focus-bg:						@white;
@form-input-hover-border:				rgba(110, 205, 233, 0.4);;
@form-input-focus-border:				@focus-border;
@form-input-chk-empty-border:	  #6ecde9;
@form-input-chk-empty-border--hover: rgba(110, 205, 233, 0.4);
@form-input-chk-bg:							@color-1;
@form-input-chk-border:					@downriver;
@form-input-chk-text:						@white;
@form-divider:                  lightgray;

.input-label {
  display: block;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
}
.input-required-asterisk {
  &:after {
    content: '*';
    color: @form-red-1;
    font-size: 14px;
    margin-left: 2px;
  }
}
.input-text {
  .placeholder({
    color: @form-placeholder;
  });
  .no-outline-focus;
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  padding: 10px 15px;
  font-size: 16px;
  font-family: @main-font;
  color: @form-input-text;
  vertical-align: middle;
  height: 40px;
  border: 1px solid @form-input-border;
  border-radius: 2px;
  background-color: @form-input-bg;
  .transition(~'border, background-color, box-shadow');
  box-shadow: none;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: right 10px top 8px;
  &:hover {
    border-color: @form-input-hover-border;
    background-color: @form-input-focus-bg;
  }
  &:focus {
    background-color: @form-input-focus-bg;
    border-color: @form-input-focus-border;
  }
  &::-ms-clear,
  &::-webkit-search-cancel-button,
  &::-webkit-search-decoration,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}
.input-text-small {
  .input-text;
  height: 35px;
  padding: 9px 16px 8px;
  font-size: 12px;
}
.input-select {
  .input-text;
  cursor: pointer;
  background-image: url('@{sol-graphics-path}/forms/chevron-down.svg');
  background-size: 13px;
  background-position: right 15px top 12px;
}
.input-select-small {
  .input-select;
  padding: 9px 16px 8px;
  font-size: 12px;
}
.input-text-invalid {
  border-color: @form-red-2;
  background-image: url('@{sol-graphics-path}/forms/times-invalid.svg');
}
.input-text-valid {
  border-color: #8acf96;
  background-image: url('@{sol-graphics-path}/forms/check-valid.svg');
}
.input-textarea {
  .input-text;
  min-height: 120px;
  height: auto;
  max-width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.input-divider {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px dashed @form-divider;
}
.input-checkbox,
.input-radio {
  display: block;
  height: 0;
  opacity: 0;
  & + label::before {
    content: '';
    box-sizing: border-box;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid @form-input-chk-empty-border;
    border-radius: 2px;
    position: relative;
    top: -1px;
    margin-right: 10px;
    float: left;
    text-align: center;
    .transition(~'color, background-color, border-color, border-width');
  }
  & + label {
    display: block;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    margin-bottom: 10px;
    cursor: pointer;
    &:hover {
      &::before {
        border-color: @form-input-chk-empty-border--hover;
      }
    }
  }
  &:focus + label::before,
  &:focus:checked + label::before {
    border: 2px solid @form-input-focus-border;
  }
}
.input-checkbox {
  &:checked + label:before {
    .icon(@fa-var-check, 12px);
    padding-top: 2px;
    background-color: @form-input-chk-bg;
    border-color: @form-input-chk-border;
    color: @form-input-chk-text;
  }
}
.input-radio {
  & + label:before {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    top: 1px;
  }
  &:checked + label:before {
    border-width: 3px;
    border-color: @form-input-chk-border;
    background-color: @form-input-chk-bg;
  }
}
.input-file() {
  display: block;
  height: 0;
  opacity: 0;
  & + label {
    .clearfix;
    margin-bottom: 20px;
  }
  &__label {
    .input-label;
    margin-bottom: 8px;
  }
  &__btn {
    .button;
    padding: 5px 20px;
    margin-left: 10px;
    margin-top: 2px;
    font-size: 13px;
  }
  &__text {
    float: left;
    margin-left: 15px;
    font-size: 15px;
    font-weight: 300;
    line-height: 34px;
  }
  &__remove {
    .no-appearance;
    min-height: 32px;
    margin-left: 10px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    top: 1px;
    cursor: pointer;
    & i {
      font-size: 18px;
      color: @color-1;
    }
  }
}
.input-btn {
  .sol-btn;
}
.input-btn-small {
  .input-btn;
  font-size: 14px;
  padding: 5px 15px 6px;
}
